import { Status } from "src/api/Status";
import { ActiveInhabitant } from "src/api/Active";

// Utility functions for user status.
export const StatusUtil = {

    isStatusActive(role: Status): boolean {
        return role === ActiveInhabitant || role === 'active';
    },
}
