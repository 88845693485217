import {
  Flex,
  Box,
  Stack,
  Button,
  Heading,
  Text,
  Image
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useAuthContext } from 'src/auth/AuthContext';
import { useColorModeValue } from "@/components/ui/color-mode"
import { Avatar } from "@/components/ui/avatar"

const Logout = () => {
  const { user, logout } = useAuthContext();

  useEffect(() => {
    logout();
    console.log('logged out...');
  });

  return (
    <Stack as={Box} direction={{ base: 'column', md: 'row' }}
      align={'center'}
      justify={{ base: 'left', lg: 'center' }}
    >

      <Flex
        minH={{ base: '50vh', lg: '70vh' }}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.25', 'gray.800')}>
        <Stack gap={8} mx={'auto'} maxW={'lg'} py={6} px={6}>
          <Stack align={'center'}>
            <Avatar bg="orange.500" />
            <Heading fontSize={'4xl'} textAlign={'center'}>Abmeldung</Heading>
            {user && user.data.firstName && <Text fontSize={'lg'} color={'gray.600'}>Bye {user.data.firstName} 👋</Text>}
          </Stack>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('gray.25', 'gray.700')}
            boxShadow={'lg'}
            pos='relative'
            p={8}>
            <Stack gap={4}>
              <Text lineClamp={2}>
                Die Sitzung abgelaufen ist,
                bitte melde dich erneut an.
              </Text>
              <Stack gap={10} pt={2}>
                <Button size="lg" bg={'blue.400'} color={'white'} _hover={{ bg: 'blue.500' }}
                  onClick={event => window.location.href = '/login'}>
                  Anmelden
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>

      <Box display="flex" hideBelow="md">
        <Flex minH={{ base: '50vh', lg: '70vh' }}>
          <Image
            maxW={'4xl'}
            alt={'Logout image'}
            objectFit={'scale-down'}
            src={'/images/trees.webp'}
            px={5}
          />
        </Flex>
      </Box>

    </Stack>
  );
}

export default Logout