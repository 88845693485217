import { Box, VisuallyHidden, Text, Flex, Separator, IconButton } from "@chakra-ui/react";
import { ReactNode } from "react";
import { FcInfo, FcInspection } from "react-icons/fc";
import CountryFlag from "./CountryFlag";
import { useColorModeValue } from "@/components/ui/color-mode";
import { Tooltip } from "@/components/ui/tooltip"
import { useNavigate } from "react-router-dom";

const SocialButton = ({
    children,
    label,
    href,
}: {
    children: ReactNode;
    label: string;
    href: string;
}) => {
    const navigate = useNavigate();
    return (
        <Tooltip content={label}>
            <IconButton
                bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
                rounded={'full'}
                cursor={'pointer'}
                onClick={() => navigate(href)}
                alignItems={'center'}
                justifyContent={'center'}
                transition={'background 0.3s ease'}
                _hover={{
                    bg: useColorModeValue('blackAlpha.300', 'whiteAlpha.300'),
                }}>
                <VisuallyHidden>{label}</VisuallyHidden>
                {children}
            </IconButton>
        </Tooltip>
    );
};

// bezier path and copyright with social buttons
const Footer = ({loggedIn}: {loggedIn: boolean}) => {
    const year = new Date().getFullYear();
    return (
        <Box>
            <svg viewBox="0 125 1440 105">
                <path fill='lightgray' fillOpacity=".5" d="M 2 166 L 67 186 C 121 206 192 224 288 224 C 384 224 484 202 634 177 C 761 159 781 152 935 188 C 1017 213 1055 221 1189 223 C 1292 212 1341 192 1434 166 L 1435 235 L 3 237 Z"></path>
            </svg>

            <Separator orientation='horizontal' background='lightgray' colorScheme='gray' />

            <Box bg={useColorModeValue('gray.100', 'gray.600')} px='1rem' py='.5rem' boxShadow='lg'>
                <Flex
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                    width='100%'
                >
                    <Flex gap='.5rem'>
                        <Text>© 2023-{year}</Text>
                        <Tooltip content='Schweiz'>
                            <CountryFlag code='CH' />
                        </Tooltip>
                    </Flex>

                    <Flex gap='1rem'>
                        <SocialButton label={'Info'} href={'/info'}>
                            <FcInfo />
                        </SocialButton>
                        {loggedIn && (
                            <SocialButton label={'Entwicklung'} href={'/dev'}>
                                <FcInspection />
                            </SocialButton>
                        )}

                    </Flex>
                </Flex>
            </Box>
        </Box>
    );
}

export default Footer