import { forwardRef } from 'react';
import { Input } from '@chakra-ui/react';
import { NumericFormat } from 'react-number-format';
import { AmountUtil } from '@/data/AmountUtil';

interface CustomAmountInputProps {
  value?: string | number;
  onChange?: (value: string) => void;
  [key: string]: any;
}

export const CustomAmountInput = forwardRef<HTMLInputElement, CustomAmountInputProps>(({ value, onChange, ...props }, ref) => {
  return (
    <NumericFormat
      customInput={Input} // Use Chakra Input as the base component
      textAlign="right"
      thousandSeparator="'"
      decimalSeparator="."
      decimalScale={2}
      fixedDecimalScale={true}
      getInputRef={ref}
      value={value}
      onValueChange={(values) => {
        if (onChange) {
          const num = AmountUtil.parse(values.value);
          onChange(num);
        }
      }}
      {...props}
    />
  );
});

// value={value} onValueChange={v => onChange(AmountUtil.parse(v.target.value))} onBlur={onBlur} readOnly={readOnly}

/**
 * When using it from a controller, automatic conversion fails because of the grouping character.
 * Extend the onChange like:
 * onChange={v => onChange(AmountUtil.parse(v.target.value))}
 */
