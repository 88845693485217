import { Skeleton, Stack, Text } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import DataTable, { amountHeader, moneyCell, moneyText } from 'src/components/DataTable';
import { ColumnDef, CellContext, Row } from '@tanstack/react-table';
import Content from 'src/components/Content';
import ReachLink from 'src/components/ReachLink';
import { FundMoveYear } from 'src/api/FundMoveYear';
import { reportService } from 'src/service/ReportService';
import { IdAndName } from 'src/api/IdAndName';
import { EntryUtil } from 'src/data/EntryUtil';
import { FundMoveDebt } from 'src/api/FundMoveDebt';
import { Money } from 'src/api/Money';
import { MoneyUtil } from 'src/data/MoneyUtil';
import WaitingSpinner from '@/components/WaitingSpinner';

export interface DebtEntry {
    project: IdAndName;
    debt: Map<number, Money>;
}

// extract converter function for easier testing
// converts the API response into a strcture that can be easier visualized with the data table.
export const convert = (results: readonly FundMoveDebt[], id2Name: Map<number, string>): DebtEntry[] => {
    // convert to map of project id to debt list
    const id2debts = results.reduce(
        (m, d) => m.set(d.projectId, m.get(d.projectId) ? m.get(d.projectId).concat(d) : [d]), new Map<number, FundMoveDebt[]>()
    )
    //console.log(`projectId to debts = ${JSON.stringify(id2debts)}`);
    console.log(`pid2debts size=${id2debts.size}`);
    const entries = [...id2debts.entries()].map(a => {
        const projectId = a[0];
        const debts = a[1];
        //console.log(`debts for project ${projectId} = ${JSON.stringify(debts)}`);
        //const c = debts.map(d => [d.internalProjectId, d.debt]);
        //const d: Map<number, Money> = new Map(c);
        const d = debts.reduce((map, debt) => {
            map.set(debt.internalProjectId, debt.debt);
            return map;
          }, new Map<number, Money>());
        //console.log(`debts MAP for project ${projectId} = ${JSON.stringify(d)} where size is ${d.size}`);
        let e: DebtEntry = {
            project: { id: projectId, name: id2Name.get(projectId) ?? '' },
            debt: d
        }
        //console.log(`debt entry = ${JSON.stringify(e)}`);
        return e;
    });
    return entries;
}

const DashboardFundsMoves = () => {
    const [data, setData] = useState<FundMoveYear[]>([]);
    const [debt, setDebt] = useState<DebtEntry[]>([]);
    const [internalProjects, setInternalProjects] = useState<IdAndName[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const loadAllData = (): Promise<void> => {
        setIsLoading(true);
        return reportService.fundMoves()
            .then(r => setData(r.results.concat()))
            .then(() => reportService.fundMovesDebts())
            .then(r => {
                //console.log(`debts = ${JSON.stringify(r)}`)
                const id2Name: Map<number, string> = new Map(r.projects.map(p => [p.id, p.name]))
                const entries = convert(r.results, id2Name);
                //console.log(`debt entries = ${JSON.stringify(entries)}`)
                const internals = Array.from(new Set(r.results.map(a => a.internalProjectId))).map(p => {
                    return { id: p, name: id2Name.get(p) ?? '' }
                });
                setInternalProjects(internals);
                const totalDebts: Map<number, Money> = new Map(internals.map(i => {
                    const m: Money = MoneyUtil.merge(r.results.filter(e => e.internalProjectId === i.id).map(e => e.debt)).at(0);
                    return [i.id, m]
                }));
                const total: DebtEntry = {
                    project: { id: -1, name: 'Total' },
                    debt: totalDebts
                };
                setDebt(entries.concat(total));
            })
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        loadAllData();
    }, []);

    const redIfDept = (money: Money) => {
        return money && money.value ? 
            moneyText(money, '15rem', money.value < 0 ? 'bold' : 'normal', money.value < 0 ? 'red' : undefined) 
            : 
            '';
    };

    const projectHeader: ColumnDef<DebtEntry>[] = useMemo(() => [
        {
            id: 'project',
            header: 'Projekt',
            accessorKey: 'project',
            cell: (info: CellContext<DebtEntry, IdAndName>) => {
                let pr = info.getValue();
                if (pr.id < 0) {
                    return (<Text ml='.5rem' width='15rem' fontWeight='bold'>{pr.name}</Text>);
                }
                return (<ReachLink to={`/projects/view/${pr.id}`} name={pr.name} tooltip='zu den Projektdetails' />);
            },
            sortingFn: (rowA: Row<DebtEntry>, rowB: Row<DebtEntry>, _): number => {
                const a = rowA.original;
                if (a.project.id < 0) {
                    return 1;
                }
                const b = rowB.original;
                if (b.project.id < 0) {
                    return -1;
                }
                return a.project.name.trim().localeCompare(b.project.name.trim(), undefined, { sensitivity: 'base' });
            },
        }], []);

    const internalHeaders: ColumnDef<DebtEntry>[] = internalProjects.map(ip => {
        let c: ColumnDef<DebtEntry> = {
            id: `debt${ip.id}`,
            header: amountHeader({ name: ip.name, width: '15rem' }),
            accessorFn: (row: DebtEntry) => row.debt.get(ip.id),
            cell: (info: CellContext<DebtEntry, Money>) => {
                //return moneyCell(); // is sensitive to undefined values!
                const money = info.getValue();
                return redIfDept(money);
            }
        };
        return c;
    });

    const totalHeader: ColumnDef<DebtEntry>[] = useMemo(() => [
        {
            id: 'total',
            header: amountHeader({ name: 'Total', width: '15rem' }),
            accessorFn: (row: DebtEntry) => {
                const sum = Array.from(row.debt.values());
                const aaa = sum.length > 0 ? MoneyUtil.merge(sum).at(0) : undefined;
                return aaa;
            },
            cell: (info: CellContext<DebtEntry, Money>) => {
                const debt = info.getValue();
                return redIfDept(debt);
            },
        }], []);

    const columnsDebt: ColumnDef<DebtEntry>[] = useMemo(
        () => projectHeader.concat(internalHeaders, totalHeader), [internalHeaders, projectHeader, totalHeader]
    );

    const columns: ColumnDef<FundMoveYear>[] = useMemo(
        () => [
            {
                id: 'project',
                header: 'Projekt',
                accessorKey: 'project',
                cell: (info: CellContext<FundMoveYear, IdAndName>) => {
                    let pr = info.getValue();
                    return (<ReachLink to={`/projects/view/${pr.id}`} name={pr.name} tooltip='zu den Projektdetails' />);
                },
                sortingFn: (rowA: Row<FundMoveYear>, rowB: Row<FundMoveYear>, _): number => {
                    const a = rowA.original;
                    const b = rowB.original;
                    return a.project.name.trim().localeCompare(b.project.name.trim(), undefined, { sensitivity: 'base' });
                },
            },
            {
                id: 'year',
                header: 'Jahr',
                accessorKey: 'year'
            },
            {
                id: 'amount',
                header: amountHeader({ name: 'Summe', width: '6rem' }),
                accessorKey: 'amount',
                cell: moneyCell('10rem'),
            },
        ], []
    );

    return (
        <Content caption='Zuweisung Fonds'>
            {isLoading ? (
                <Stack py={5}>
                    <WaitingSpinner size='xl' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                </Stack>
            ) : (
                <>
                    <Text fontSize='small'>{EntryUtil.text(debt.length)}</Text>
                    <DataTable columns={columnsDebt} data={debt} initialSortByColumn='project' />
                    <Text mt='2rem' fontSize='small'>{EntryUtil.text(data.length)}</Text>
                    <DataTable columns={columns} data={data} initialSortByColumn={['project', 'year']}/>
                </>
            )}
        </Content>
    );
}

export default DashboardFundsMoves
