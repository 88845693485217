import { Tag } from "@/components/ui/tag";
import { HStack } from "@chakra-ui/react";
import { BoardTag } from "src/api/BoardTag";

const Tags = ({ tags }: { tags: BoardTag[] }) => (
    <HStack gap={4}>
        {tags.map(tag => (
            <Tag key={tag.title} variant='solid' background={tag.bgcolor} color={tag.color} fontFamily='Verdana' fontSize='xs' dropShadow='3px'>
               {tag.title}
            </Tag>
        ))}
    </HStack>)

export default Tags;
