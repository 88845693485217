import { Separator, Flex, HStack, Text, Box, Stack, Fieldset } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { YearlyBudget } from "src/api/YearlyBudget";
import { CustomDateInput, formatDate } from "@/components/fields/DateSelect";
import { AmountUtil } from "src/data/AmountUtil";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { de } from 'date-fns/locale/de';
import ErrorMessage from "src/components/ErrorMessage";
import { CustomAmountInput } from "@/components/fields/AmountInput";
import { FcCalculator } from "react-icons/fc";
import CancelButton from "src/components/buttons/CancelButton";
import { dateOfYear } from "src/data/DateTimeUtil";
import { Button } from "@/components/ui/button";
import { Field } from "@/components/ui/field";
import { Switch } from "@/components/ui/switch";
import {
    NumberInputField,
    NumberInputRoot,
} from "@/components/ui/number-input";
import { DialogRoot, DialogContent, DialogHeader, DialogBody, DialogCloseTrigger, DialogFooter } from "@/components/ui/dialog";

export interface BudgetDialogProps {
    data: YearlyBudget;
    currency: string,
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (yb: YearlyBudget) => Promise<void>;
}

const BudgetDialog = ({ data, currency, isOpen, onClose, onConfirm }: BudgetDialogProps) => {

    //console.info(`received ${JSON.stringify(data)}`);
    const [budget, setBudget] = useState<YearlyBudget>(data);
    const [error, setError] = useState('');

    // update the state got from the props at every open
    useEffect(() => setBudget(data), [data]);

    const onSubmitInternal = (event) => {
        event.preventDefault();
        let yb = budget;
        console.info(`budget: ${JSON.stringify(yb)}`);
        try {
            if (!yb.year) {
                throw new Error('Jahr leer');
            }
            if (!yb.budget) {
                throw new Error('Budget leer');
            }
            if (!yb.rateDate) {
                throw new Error('Kursdatum leer');
            }
            if (!yb.rate) {
                throw new Error('Kurs leer');
            }
            if (!yb.projectBudget) {
                throw new Error('Project budget leer');
            }
            if (yb.overhead < 0) {
                throw new Error('Overhead leer');
            }
            return onConfirm(yb).then(() => onClose());
        } catch (err) {
            console.error(err);
            setError(err.message);
        }
    };

    // decoupled for the moment - as requested
    //const fundraising = (projectBudget: number, overhead: number): number => projectBudget * 100 / (100 - overhead);
    return (
        <DialogRoot open={isOpen} onOpenChange={() => onClose()}
            placement='center'
            size='md'
            motionPreset='scale'
            scrollBehavior='outside'
        >
            <DialogContent>
                <DialogHeader>
                    <HStack><FcCalculator size='1.5rem' /><Text>Jahresbudget</Text></HStack>
                </DialogHeader>
                <DialogCloseTrigger />

                <DialogBody>
                    <Fieldset.Root invalid={error && error.length > 0}>
                        <Stack gap='5'>
                            {error && <ErrorMessage message={error} />}
                            <Stack direction={{ base: 'column', sm: 'row' }} justify={'space-between'}>
                                <Field id="year" label='Budget Yahr' required>
                                    <DatePicker
                                        showYearPicker
                                        dateFormat='yyyy'
                                        showPopperArrow={true}
                                        selected={dateOfYear(budget.year)}
                                        onChange={(date: Date | null) => {
                                            setError('');
                                            setBudget(prev => {
                                                let merged = { ...prev, ...{ year: date?.getFullYear() } };
                                                return merged;
                                            })
                                        }}
                                        customInput={<CustomDateInput />}
                                    />
                                </Field>
                                <Field id="budget" label={`Budget (${currency})`} required>
                                    <CustomAmountInput
                                        value={budget.budget}
                                        placeholder='in fremder Währung'
                                        onChange={(ev) => {
                                            setError('');
                                            setBudget(prev => {
                                                let v = ev;
                                                let pb = v / prev.rate;
                                                //let fb = fundraising(pb, prev.overhead);
                                                let merged = {
                                                    ...prev, ...{
                                                        budget: v,
                                                        projectBudget: pb
                                                    }
                                                };
                                                return merged;
                                            })
                                        }}
                                    />
                                </Field>
                            </Stack>
                            <Box borderWidth='1px' borderRadius='lg' p='.5rem' shadow='lg'>
                                <Text fontWeight='bold' pb='.5rem'>Projekt Budget</Text>
                                <Stack direction={{ base: 'column', sm: 'row' }} justify={'space-between'}>
                                    <Field id="rateDate" label='Kursdatum' required>
                                        <DatePicker
                                            name='rateDate'
                                            dateFormat='dd.MM.yyyy'
                                            locale={de}
                                            showPopperArrow={true}
                                            selected={formatDate(budget.rateDate)}
                                            onChange={(date: Date | null) => {
                                                setError('');
                                                setBudget(prev => {
                                                    let merged = { ...prev, ...{ rateDate: date } };
                                                    return merged;
                                                })
                                            }}
                                            customInput={<CustomDateInput />}
                                        />
                                    </Field>
                                    <Field id="rate" label={`Kurs (CHF/${currency})`} required>
                                        <CustomAmountInput
                                            value={budget.rate}
                                            placeholder='Valutakurs'
                                            onChange={ev => {
                                                setError('');
                                                setBudget(prev => {
                                                    let v = ev;
                                                    let pb = prev.budget / v;
                                                    //let fb = fundraising(pb, prev.overhead);
                                                    let merged = {
                                                        ...prev, ...{
                                                            rate: v,
                                                            projectBudget: pb,
                                                        }
                                                    };
                                                    return merged;
                                                })
                                            }}
                                        />
                                    </Field>
                                </Stack>

                                <Stack direction={{ base: 'column', sm: 'row' }} justify={'space-between'} pt='.5rem'>
                                    <Field label='Indikativ Kurs'>
                                        <Switch id='indicativeRate' checked={budget.indicativeRate} onCheckedChange={ev => {
                                            setError('');
                                            setBudget(prev => {
                                                let ir = ev.checked;
                                                let pb = ir ? prev.projectBudget : prev.budget / prev.rate;
                                                let merged = {
                                                    ...prev, ...{
                                                        indicativeRate: ir,
                                                        projectBudget: pb,
                                                    }
                                                };
                                                return merged;
                                            })
                                        }}>Manuell Eingeben</Switch>
                                    </Field>
                                    <Field id="projectBudget" label='Budget (CHF)' required readOnly={!budget.indicativeRate}>
                                        <CustomAmountInput
                                            value={budget.projectBudget}
                                            isDisabled={!budget.indicativeRate}
                                            placeholder='Budget / Kurs'
                                            onChange={ev => {
                                                setError('');
                                                setBudget(prev => {
                                                    let pb = ev;
                                                    let merged = {
                                                        ...prev, ...{
                                                            projectBudget: pb,
                                                        }
                                                    };
                                                    return merged;
                                                })
                                            }}
                                        />
                                    </Field>
                                </Stack>
                            </Box>

                            <Box borderWidth='1px' borderRadius='lg' p='.5rem' shadow='lg'>
                                <Text fontWeight='bold' pb='.5rem'>Fundraising Budget</Text>
                                <Stack direction={{ base: 'column', sm: 'row' }} justify={'space-between'}>
                                    <Field id="overhead" label='Overhead %' required>
                                        <NumberInputRoot
                                            step={0.01}
                                            // defaultValue='10'
                                            formatOptions={{
                                                style: "percent",
                                                //minimumFractionDigits: 1
                                            }}
                                            min={0} 
                                            max={99} // 100 not possible
                                            value={(budget?.overhead ?? 10).toString()}
                                            onValueChange={(ev) => {
                                                setError('');
                                                //console.log(`val=${JSON.stringify(ev)}`);
                                                const v: number = ev.valueAsNumber * 100;
                                                setBudget(prev => {
                                                    //let fb = fundraising(prev.projectBudget, v);
                                                    let merged = {
                                                        ...prev, ...{
                                                            overhead: v,
                                                        }
                                                    };
                                                    return merged;
                                                });
                                            }}
                                        >
                                            <NumberInputField />
                                        </NumberInputRoot>
                                    </Field>

                                    <Field id="fundraisingBudget" label='Fundraising (CHF)' required>
                                        <CustomAmountInput
                                            value={budget.fundraisingBudget}
                                            placeholder='Budget + OH'
                                            onChange={(ev) => {
                                                setError('');
                                                setBudget(prev => {
                                                    let v = ev;
                                                    let merged = {
                                                        ...prev, ...{
                                                            fundraisingBudget: v,
                                                        }
                                                    };
                                                    return merged;
                                                })
                                            }}
                                        />
                                    </Field>
                                </Stack>
                            </Box>

                            <Separator my='0' />
                            <Stack justify={'space-between'} alignItems={{ base: 'flex-end' }}>
                                <Flex>
                                    <Text>Projekt Budget:</Text><Text mx='3' textAlign='right' fontWeight='black'>{AmountUtil.format(budget?.projectBudget ?? 0)}</Text><Text>CHF</Text>
                                </Flex>
                                <Flex>
                                    <Text>Fundraising Budget:</Text><Text mx='3' textAlign='right' fontWeight='black'>{AmountUtil.format(budget?.fundraisingBudget ?? 0)}</Text><Text>CHF</Text>
                                </Flex>
                            </Stack>
                        </Stack>
                    </Fieldset.Root>
                </DialogBody>

                <DialogFooter>
                    <HStack gap={2} >
                        <CancelButton onClick={onClose} />
                        <Button bg={'blue.400'} color={'white'} _hover={{ bg: 'blue.500' }} onClick={event => onSubmitInternal(event)}>
                            Speichern
                        </Button>
                    </HStack>
                </DialogFooter>
            </DialogContent>
        </DialogRoot>
    );
}

export default BudgetDialog