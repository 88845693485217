import { Badge, Text, Box, Stack, Link, HStack, Separator, Table, useDisclosure } from "@chakra-ui/react"
import { useState, useEffect } from "react";
import { InfoResponse } from "../api/InfoResponse";
import { infoService } from '../service/InfoService';
import { parseIso } from "src/data/DateTimeUtil";
import { Duration, differenceInSeconds, formatDuration, intervalToDuration, setDefaultOptions } from "date-fns";
import { de } from 'date-fns/locale';
import CountryFlag from "src/components/CountryFlag";
import packageJson from '../../package.json';
import apiClient from "src/service/core/ApiClient";
import ColorPicker from "src/components/ColorPicker";
import { OrgUtil } from "src/data/OrgUtil";
import { ProgressBarReact } from "src/components/ProgressBar";
import { useAuthContext } from "src/auth/AuthContext";
import RoleBadge from "src/components/RoleBadge";
import ExchangeRates from "src/components/ExchangeRates";
import DatePicker from "react-datepicker";
import { CustomDateInput } from "@/components/fields/DateSelect";
import { Tooltip } from "@/components/ui/tooltip";
import { Button } from "@/components/ui/button";
import ConfirmDialog from "@/components/ConfirmDialog";

const Info = () => {
    const { user } = useAuthContext();
    const [buildTime, setBuildTime] = useState<Date | null>(null);
    const [serverTime, setServerTime] = useState<Date | null>(null);
    const [beUpTime, setBeUpTime] = useState<Duration>({ seconds: 0 });
    const [dbAlive, setDbAlive] = useState<boolean>(false);
    const [s3Objects, setS3Objects] = useState<number>(-1);
    const [memoryPercentile, setMemoryPercentile] = useState<number>(0);
    const { open, onOpen, onClose } = useDisclosure();
    //const [feUpTime] = useState<Duration>({ seconds: 0 });

    setDefaultOptions({ locale: de });

    useEffect(() => {
        infoService.getInfo().then((res: InfoResponse) => {
            setBuildTime(parseIso(res.buildTime));
            let serverNow = parseIso(res.currentTime);
            setServerTime(serverNow);
            let seconds = differenceInSeconds(serverNow, parseIso(res.startTime))
            const duration = intervalToDuration({ start: 0, end: seconds * 1000 })
            setBeUpTime(duration);
            setDbAlive(res.dbAlive);
            setS3Objects(res.s3Objects);
            setMemoryPercentile(res.memoryUsedPercentile);

            // !!! on live runs with Caddy, on local runs from Node
            // let clientNow = new Date()
            // let feUptime = differenceInSeconds(clientNow, feStartTime)
            // console.info(`FE uptime ${feUptime} - now:${clientNow} start:${feStartTime}`);
            // let feDuration = intervalToDuration({ start: 0, end: feUptime * 1000 })
            // setFeUpTime(feDuration);
        });

    }, []);

    return (
        <Stack as={Box} p='.5rem' direction={{ base: 'column' }}>
            <Table.Root striped colorPalette='teal' size='sm' align={'left'} w={{ base: '100%', lg: '50%' }}>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell width='25%'><Text as='b'>User:</Text></Table.Cell>
                        <Table.Cell>
                            {user && (
                                <HStack ml='5'>
                                    <Text fontWeight='bold'>{user?.data?.firstName}</Text>
                                    <RoleBadge role={user?.data?.role} />
                                </HStack>
                            )}
                            {!user && (
                                <HStack ml='5'>
                                    <Badge variant='outline' colorScheme='red'>None</Badge>
                                </HStack>
                            )}
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><Text as='b'>Organization:</Text></Table.Cell>
                        <Table.Cell>
                            <Badge ml='5' mr='10' variant='surface' colorPalette='yellow'>{OrgUtil.orgName(user?.data)}</Badge>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><Text as='b'>Status:</Text></Table.Cell>
                        <Table.Cell>
                            <Badge ml='5' mr={10} variant='surface' colorPalette={serverTime ? 'green' : 'red'}>{serverTime ? 'Pong' : 'Ping'}</Badge>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><Text as='b'>Database:</Text></Table.Cell>
                        <Table.Cell>
                            <Badge ml='5' mr={10} variant='surface' colorPalette={dbAlive ? 'green' : 'red'}>{dbAlive ? 'Alive' : 'Checking'}</Badge>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><Text as='b'>S3 Objects:</Text></Table.Cell>
                        <Table.Cell><Text ml='5' as='i'>{s3Objects}</Text></Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><Text as='b'>Build Zeit:</Text></Table.Cell>
                        <Table.Cell><Text ml='5' as='i'>{buildTime?.toLocaleString() ?? '?'}</Text></Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><Text as='b'>Server Zeit:</Text></Table.Cell>
                        <Table.Cell>
                            <Text ml='5' as='i'>{serverTime?.toLocaleString() ?? '?'}</Text>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><Text as='b'>Frontend Version:</Text></Table.Cell>
                        <Table.Cell>
                            <Text ml='5' as='i'>{packageJson.version}</Text>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><Text as='b'>API Host:</Text></Table.Cell>
                        <Table.Cell>
                            <Text ml='5' as='i'>{apiClient.baseUrl}</Text>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><Text as='b'>Hostname:</Text></Table.Cell>
                        <Table.Cell>
                            <Text ml='5' as='i'>{window.location.hostname}</Text>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><Text as='b'>Lauf Zeit Backend:</Text></Table.Cell>
                        <Table.Cell>
                            <Text ml='5' as='i'>{formatDuration(beUpTime, { format: ['days', 'hours', 'minutes', 'seconds'] })}</Text>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><Text as='b'>Lauf Zeit Frontend:</Text></Table.Cell>
                        <Table.Cell>
                            <Text ml='5' as='i'>{formatDuration(beUpTime, { format: ['days', 'hours', 'minutes', 'seconds'] })}</Text>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><Text as='b'>Memory Usage:</Text></Table.Cell>
                        <Table.Cell>
                            <Box ml='5' w='12rem'>{ProgressBarReact(memoryPercentile / 100)}</Box>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><Text as='b'>OpenAPI:</Text></Table.Cell>
                        <Table.Cell>
                            <Box ml={5}>
                                <Text color='blue.600'>
                                    <Link onClick={_ => window.location.href = '/docs'}>/docs</Link>
                                </Text>
                            </Box>
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><Text as='b'>Entwicklung:</Text></Table.Cell>
                        <Table.Cell>
                            <Box ml={5}>
                                <Text color='blue.600'>
                                    <Link onClick={_ => window.location.href = '/dev'}>/dev</Link>
                                </Text>
                            </Box>
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table.Root>

            <ExchangeRates base={"CHF"} all={["USD", "BDT", "KES"]} />

            <HStack>
                <Separator flex="1" />
                <Text flexShrink="0">UI Components</Text>
                <Separator flex="1" />
            </HStack>

            <HStack mt='1rem'>
                <Text>Test Color Picker</Text>
                <ColorPicker value='#00AA00' onChange={(_) => { }} isReadOnly={false} />
            </HStack>
            <HStack mt='1rem'>
                <Text>Test Date Picker</Text>
                <DatePicker
                    isClearable={true}
                    selected={new Date()}
                    onChange={(date: Date | null) => console.log(`date changed ${date}`)}
                    dateFormat='dd.MM.yyyy'
                    locale={de}
                    showPopperArrow={true}
                    customInput={<CustomDateInput />}
                />
            </HStack>
            <HStack mt='1rem'>
                <Text>Test Confirm Dialog</Text>
                <Button colorPalette='teal' onClick={() => onOpen()}>Confirm</Button>
                <ConfirmDialog heading='Is it ok?' isOpen={open} onClose={onClose} onConfirm={() => Promise.resolve()} />
            </HStack>

            <Stack alignItems={{ base: 'flex-end' }}>
                <Tooltip content='Schweiz'>
                    <CountryFlag code='CH' />
                </Tooltip>
            </Stack>
        </Stack>
    )
}

export default Info