import { Skeleton, Stack, HStack, Text, Flex } from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { AccountingEntry } from "src/api/AccountingEntry";
import { reportService } from "src/service/ReportService";
import DataTable, { amountHeader, amountCell, dateCell } from 'src/components/DataTable';
import { FileDownload } from "src/components/FileSelect";
import CancelButton from "src/components/buttons/CancelButton";
import WaitingSpinner from "@/components/WaitingSpinner";
import { DialogRoot, DialogContent, DialogHeader, DialogBody, DialogCloseTrigger, DialogFooter } from "@/components/ui/dialog";


export interface DashboardFundraisingDialogProps {
    projectId: number;
    projectName: string;
    projectYear?: number;
    instituteOnly: boolean;
    isOpen: boolean;
    onClose: () => void;
}

const DashboardFundraisingDialog = ({ projectId, projectName, projectYear, instituteOnly, isOpen, onClose }: DashboardFundraisingDialogProps) => {

    const [data, setData] = useState<AccountingEntry[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        console.log(`year for details is ${projectYear}, instituteOnly ${instituteOnly}`);
        if (projectYear) {
            setIsLoading(true);
            reportService.fundraisingProjectYear(projectId, projectYear)
                .then(r => {
                    // filter on client side
                    const res = instituteOnly ? r.results.filter(a => a.entryType === 'Projektbeiträge') : r.results;
                    setData(res.concat([]));
                }).finally(() => setIsLoading(false));
        } else {
            setData([]);
        }
    }, [projectId, projectYear, instituteOnly, isOpen]);

    const columns: ColumnDef<AccountingEntry>[] = useMemo(
        () => [
            {
                id: 'entryDate',
                header: 'Datum',
                accessorKey: 'entryDate',
                cell: dateCell(),
                enableGrouping: false,
            },
            {
                id: 'amount',
                header: amountHeader({ name: 'Summe' }),
                accessorKey: 'amount',
                cell: amountCell({}),
                enableGrouping: false,
                aggregateFn: 'sum',
                aggregatedCell: amountCell({}),
            },
            {
                id: 'entryType',
                header: 'Typ',
                accessorKey: 'entryType',
            },
            {
                id: 'comments',
                header: 'Bemerkungen',
                accessorKey: 'comments',
                enableGrouping: false,
            },
        ], []
    );

    return (
        <DialogRoot open={isOpen} 
            onOpenChange={() => onClose()}
            placement='center'
            size='xl'
            motionPreset='scale'
            scrollBehavior='outside'>
            <DialogContent>
                <DialogHeader>{projectYear ?? 'Total'} - {projectName}</DialogHeader>
                <DialogCloseTrigger />
                <Stack justify={'space-between'} alignItems={{ base: 'baseline' }} direction={{ base: 'column', sm: 'row' }} mx='2rem'>
                    <Text verticalAlign={'top'}>{instituteOnly ? 'Nur Institutionelle Beiträge' : ''}</Text>
                    <HStack>
                        <FileDownload path={`/api/reports/fundraising/${projectId}/${projectYear}/download`} name={`fundraising-${projectYear}-${projectId}.xlsx`} />
                    </HStack>
                </Stack>
                <DialogBody>
                    {isLoading ? (
                        <Stack py={5}>
                            <WaitingSpinner size='xl' />
                            <Skeleton height='20px' />
                            <Skeleton height='20px' />
                            <Skeleton height='20px' />
                        </Stack>
                    ) : (
                        data.length > 0 && <DataTable columns={columns} data={data}
                            initialSortByColumn='entryDate' initialSortByDesc={true}
                            initialGroupByColumns={instituteOnly ? [] : ['entryType']}
                        />
                    )}
                </DialogBody>

                <DialogFooter>
                    <Flex justifyContent='flex-end'>
                        <CancelButton onClick={onClose} />
                    </Flex>
                </DialogFooter>
            </DialogContent>
        </DialogRoot>
    );
}

export default DashboardFundraisingDialog;