import { Flex, Text } from "@chakra-ui/react";
import {
    chakraComponents,
    OptionBase,
    Select as ReactSelect
} from "chakra-react-select";
import { Control, Controller } from "react-hook-form";
import { supportedCurrencyMappings } from "src/data/Countries";
import { Field } from "../ui/field";


interface CodeCurrency extends OptionBase {
    label: string;
    value: string;
}

// convert into code currency pairs and remove unwanted currencies
const codeCurrency = Object.keys(supportedCurrencyMappings).map(c => {
    let cc: CodeCurrency = {
        value: c,
        label: supportedCurrencyMappings[c]
    }
    return cc;
});

const customItem = (item: CodeCurrency) => (
    <Flex align="left">
        <Text fontWeight={'bold'}>{item.value}</Text>
        <Text ml="2px" fontSize='.8rem'>{item.label}</Text>
    </Flex>
);

const customOption = (props) => (
    <chakraComponents.Option label={props.data.name} {...props}>
        {customItem(props.data)}
    </chakraComponents.Option>
);

// with control support for forms
const CurrencySelect = ({ control, name, readOnly, ...props }: { control: Control<any, any>, name: string, readOnly: boolean, [key: string]: any }) => {
    return (
        <Controller control={control} name={name}
            rules={{ required: "Währung auswählen", validate: (value, formValues) => value.length > 0 }}
            render={({ field: { value, name, onChange, onBlur, ref }, fieldState: { error } }) => (
                <Field id={name} label='Währung' required invalid={!!error} errorText={error?.message} disabled={readOnly}>
                    <CurrencySelectPlain value={value} onChange={onChange} {...props} />
                </Field>
            )} />
    );
}

const CurrencySelectPlain = ({ value, onChange, ...props }) => {
    return (
        <ReactSelect
            placeholder='Währung auswählen'
            options={codeCurrency}
            onChange={val => onChange(val.value)}
            value={codeCurrency.find(c => c.value === value)}
            components={{ Option: customOption }}
            formatOptionLabel={(option: CodeCurrency) => customItem(option)}
            getOptionLabel={(option: CodeCurrency) => option.label}
            getOptionValue={(option: CodeCurrency) => option.value}
            {...props}
        />
    );
}

export default CurrencySelect