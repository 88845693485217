import { Separator, HStack, Text } from "@chakra-ui/react"

const LaneHeaderComponent = ({
    updateTitle, canAddLanes, onDelete, onDoubleClick, editLaneTitle, label, title, titleStyle, labelStyle, t, laneDraggable
}) => {
    return (
        <>
            <HStack justify={'space-between'} alignItems={{ base: 'flex-end' }}
                fontFamily={'Caveat'}
                fontWeight={'bold'}
            >
                <Text fontSize={'1.5rem'}>{title}</Text>
                <Text fontSize={'1rem'}>{label}</Text>
            </HStack>
            <Separator orientation='horizontal' background='gray' colorScheme='lightgray' py='1px' />
        </>
    )
}

export default LaneHeaderComponent;