import { HStack, Skeleton, Stack, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import DataTable, { amountCell, amountHeader, dateCell } from 'src/components/DataTable';
import { ColumnDef, CellContext, Row } from '@tanstack/react-table';
import { FileDownload, FileSelect } from 'src/components/FileSelect';
import { Fund } from 'src/api/Fund';
import { fundService } from 'src/service/FundService';
import Content from 'src/components/Content';
import ReachLink from 'src/components/ReachLink';
import { formatPicker } from 'src/data/DateTimeUtil';
import { EntryUtil } from 'src/data/EntryUtil';
import WaitingSpinner from '@/components/WaitingSpinner';


const OverviewFunds = () => {
    const [data, setData] = useState<Fund[]>([]);
    const [projects, setProjects] = useState<Map<number, string>>(new Map()); // id to project name
    const [isLoading, setIsLoading] = useState(false);

    const loadAllData = (): Promise<void> => {
        setIsLoading(true);
        return fundService.listAll()
            .then(r => {
                setData(r.results.concat());
                setProjects(new Map(r.projects.map(p => [p.id, p.name])));
            })
            .finally(() => setIsLoading(false));
    }

    const onUpload = useCallback((): Promise<void> => {
        console.info(`upload funds suceeded...`);
        return loadAllData();
    }, []);

    useEffect(() => {
        loadAllData();
    }, [onUpload]);

    const columns: ColumnDef<Fund>[] = useMemo(
        () => [
            {
                id: 'fundDate',
                header: 'Datum',
                accessorKey: 'fundDate',
                cell: dateCell()
            },
            {
                id: 'budgetYear',
                header: () => (<div style={{ textAlign: "right" }}>Mittel Jahr</div>),
                accessorKey: 'budgetYear',
                cell: (info: CellContext<Fund, number>) => (<Text textAlign='right'>{info.getValue()}</Text>)
            },
            {
                id: 'project',
                header: 'Projekt',
                accessorKey: 'projectId',
                cell: (info) => {
                    let row = info.row.original;
                    let pid = row.projectId;
                    let projectName = projects.get(pid);
                    return (<ReachLink to={`/projects/view/${pid}`} name={projectName} tooltip='zu den Projektdetails' />);
                },
                sortingFn: (rowA: Row<Fund>, rowB: Row<Fund>, _): number => {
                    const a = rowA.original;
                    const b = rowB.original;
                    let ap = projects.get(a.projectId)?.trim() ?? '';
                    let bp = projects.get(b.projectId)?.trim() ?? '';
                    return ap.localeCompare(bp);
                },
            },
            {
                id: 'donationCode',
                header: 'Kostenstelle',
                accessorKey: 'donationCode'
            },
            {
                id: 'fundSourceName',
                header: 'Fonds Quelle',
                accessorKey: 'fundSourceName',
                cell: (info: CellContext<Fund, string>) => (<Text fontWeight={'bold'}>{info.getValue()}</Text>)
            },
            {
                id: 'amount',
                header: amountHeader({ name: 'Summe' }),
                accessorKey: 'amount',
                cell: amountCell({}),
            },
            {
                id: 'comments',
                header: 'Kommentar',
                accessorKey: 'comments'
            },
        ], [projects]
    );

    return (
        <Content caption='Fonds'>
            <Stack align={'baseline'} direction={{ base: 'column', sm: 'row' }} justify={'end'}>
                <HStack>
                    <FileSelect path='/api/funds/upload' onUpload={onUpload} />
                    <FileDownload path='/api/funds/download' name={`fonds-${formatPicker(new Date())}.xlsx`} />
                </HStack>
            </Stack>
            {isLoading ? (
                <Stack py={5}>
                    <WaitingSpinner size='xl' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                </Stack>
            ) : (
                <>
                    <Text fontSize='small'>{EntryUtil.text(data.length)}</Text>
                    <DataTable columns={columns} data={data} initialSortByColumn='fundDate' initialSortByDesc={true} hideColumnsOnXs={['project', 'comments']} />
                </>
            )}
        </Content>
    );
}


export default OverviewFunds
