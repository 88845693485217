import { Holiday } from 'src/api/Holiday';
import apiClient from './core/ApiClient'
import { Timesheet } from 'src/api/Timesheet';
import { TimesheetsStoreRequest } from 'src/api/TimesheetsStoreRequest';
import { TimesheetsStoreResponse } from 'src/api/TimesheetsStoreResponse';
import { TimesheetsListResponse } from 'src/api/TimesheetsListResponse';

const add = (ps: Timesheet): Promise<number> => {
    let payload: TimesheetsStoreRequest = {
        entry: ps
    }
    return apiClient.post<TimesheetsStoreRequest, TimesheetsStoreResponse>(`/api/users/${ps.userId}/timesheets`, payload).then(r => r.id);
}

const update = (ps: Timesheet): Promise<number> => {
    let payload: TimesheetsStoreRequest = {
        entry: ps
    }
    return apiClient.put<TimesheetsStoreRequest, TimesheetsStoreResponse>(`/api/users/${ps.userId}/timesheets`, payload).then(r => r.id);
}

const remove = (userId: number, id: number): Promise<void> => apiClient.delete<void>(`/api/users/${userId}/timesheets/${id}`);

// from, to in ISO 8601 date format
const findAll = (userId: number, from: string, to: string): Promise<Timesheet[]> => apiClient.get<TimesheetsListResponse>(`/api/users/${userId}/timesheets?from=${from}&to=${to}`).then(r => r.results.concat([]));

const publicHolidays = (year: number): Promise<Holiday[]> => apiClient.get<Holiday[]>(`/api/holidays/public/${year}`);

export const timesheetService = {
    add,
    update,
    remove,
    findAll,
    publicHolidays
}