import * as icons from 'country-flag-icons/react/3x2'
import { Icon, IconProps } from "@chakra-ui/react";

type Flags = typeof icons;
type FlagKey = keyof Flags;

const getFlag = (key: FlagKey): Flags[FlagKey] => icons[key];

// ISO2 with capital letter
interface CountryFlagProps extends Omit<IconProps, 'as'> {
  code: string;
}

const CountryFlag = ({ code, ...props }: CountryFlagProps): React.ReactElement => {
  const selectedFlagName = code.toUpperCase();
  const Flag = getFlag(selectedFlagName as FlagKey);
  return <Icon as={Flag} width='20px' {...props}></Icon>;
}

export default CountryFlag