import { Box, Heading } from "@chakra-ui/react"
import { ReactNode } from "react"

const Content = ({ caption, children }: {caption?: string, children: ReactNode}) => (
  <Box p={{ base: '1', lg: '5' }}>
    {caption && <Heading textAlign='center' fontWeight='bold' size='3xl'>{caption}</Heading>}
    {children}
  </Box>
)

export default Content