import { Box } from '@chakra-ui/react';
import { Alert } from "@/components/ui/alert"

export default function SuccessMessage({ message }) {
  return (
    <Box my={4} zIndex={51}>
      <Alert status="success" borderRadius={4}>
        {message}
      </Alert>
    </Box>
  );
}