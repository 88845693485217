import { format, parseISO, parse } from 'date-fns';
import { de } from 'date-fns/locale'

export const formatOverview = (date: Date): string => format(new Date(date), 'PPP', { locale: de });

export const formatPicker = (date: Date): string => format(new Date(date), 'dd.MM.yyyy');
export const formatIso8601 = (date: Date): string => format(new Date(date), 'yyyy-MM-dd');

export const dueFormat = 'MMMM d, yyyy h:mm aa'
export const formatDue = (date: Date): string => format(new Date(date), dueFormat);
export const parseDue = (s: string): Date => parse(s, dueFormat, new Date());

export const parseIso = (date: Date): Date => parseISO(new Date(date).toISOString());

export const parse2Date = (s: string): Date => parse(s, 'dd.MM.yyyy', new Date());

export const toTime = (date: Date): number => new Date(date).getTime();

export const dayOfYear = (date: Date): number => Math.floor((date.getTime() - parseISO(date.getFullYear().toString()).getTime()) / 86400000) + 1;
export const isWinterHoliday = (date: Date): boolean => {
    const day = dayOfYear(date);
    return day < 7 || day > 350;  // dec 16 -> jan 6
}
export const dateOfYear = (year: number | null): Date => {
    if (!year) {
        return null;
    }
    const now = new Date();
    now.setFullYear(year);
    return now;
}

export const formatMinutes = (minutes: number): string => {
    if (minutes < 0) {
        return '-:--';
    }
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}:${mins.toString().padStart(2, '0')}`;
}

// in HH:MM or H:MM format
export const elapsedMinutes = (startTime?: string, endTime?: string): number => {
    if (!startTime || !endTime) {
        return -1;
    }
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);

    const startMinutes = startHour * 60 + startMinute;
    const endMinutes = endHour * 60 + endMinute;

    return endMinutes - startMinutes;
}