import {
  Flex,
  Input,
  Stack,
  Heading,
  Text,
  Fieldset,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { authService } from 'src/service/AuthService';
import CancelButton from 'src/components/buttons/CancelButton';
import { InputGroup } from "@/components/ui/input-group"
import { Field } from "@/components/ui/field";
import { Avatar } from "@/components/ui/avatar";
import { Alert } from "@/components/ui/alert"
import { EmailIcon } from '@/components/icons/Icon';
import Footer from '@/components/Footer';
import { useForm, SubmitHandler } from "react-hook-form";
import SubmitButton from '@/components/buttons/SubmitButton';

interface ForgotEntry {
  email: string;
}

const Forgot = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotEntry>({
    defaultValues: {
      email: '',
    }
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmitForgot: SubmitHandler<ForgotEntry> = (entry) => {
    console.info('email forgot...');
    setIsLoading(true);
    authService.passwordForgot(entry.email).then(() => {
      console.info(`${entry.email} forgot requested...`);
      setSuccess(true);
    }).catch(err => {
      console.error(err);
      if (err.status === 401) {
        setSuccess(true);
      } else {
        setSuccess(false);
        setError('Ungültiger E-Mail');
      }
    }).finally(() => setIsLoading(false));
  };

  return (
    <>
      <Stack gap={8} mx={'auto'} maxW={'lg'} minH={{ base: '40vh', lg: '70vh' }} py={6} px={6}
        justify={'center'}
      >
        <Stack align={'center'}>
          <Avatar bg="teal.500" />
          <Heading fontSize={'4xl'} textAlign={'center'}>Passwort vergessen?</Heading>
          <Text fontSize={'lg'} color={'gray.600'}>Gib hier deine E-Mail Adresse an, und du bekommst einen Link zugeschickt, mit dem du dein Passwort zurücksetzen kannst. 🔓</Text>
        </Stack>
        <form noValidate onSubmit={handleSubmit(onSubmitForgot)}>
          <Fieldset.Root size='md' maxW='md' invalid={error && error.length > 0} rounded={'lg'}
            boxShadow={'lg'}
            py='3rem'
            px='2rem'
          >
            <Stack gap={4}>
              {success && <Alert status='success'>Geschickt!</Alert>}
              <Field id="email" label='E-Mail' readOnly={success} required invalid={!!errors.email} errorText={errors.email?.message}>
                <InputGroup startElement={<EmailIcon />} w='full'>
                  <Input autoFocus={true} type="email" {...register("email", { required: 'E-Mail ist erforderlich' })} />
                </InputGroup>
              </Field>
              <Flex justifyContent='flex-end' gap='2'>
                <CancelButton onClick={() => navigate('/')} />
                {!success && <SubmitButton caption='Link zusenden' isSubmitting={isLoading} />}
              </Flex>
            </Stack>
          </Fieldset.Root>
        </form>
      </Stack >
      {/* </Flex > */}
      <Footer loggedIn={false} />
    </>
  );
}

export default Forgot