import { Fieldset, Flex, Group, Heading, Stack, Textarea } from "@chakra-ui/react"
import ErrorMessage from "src/components/ErrorMessage";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Expense } from 'src/api/Expense';
import DateSelectController from '@/components/fields/DateSelect';
import { BiWallet } from "react-icons/bi";
import {
    Select as ReactSelect
} from "chakra-react-select";
import { CustomAmountInput } from '@/components/fields/AmountInput';
import { projectService } from 'src/service/ProjectService';
import { expenseService } from "src/service/ExpenseService";
import { LabelAndValueOption } from "src/data/LabelAndValueOption";
import { partnerService } from "src/service/PartnerService";
import CurrencySelect from "@/components/fields/CurrencySelect";
import CancelButton from "src/components/buttons/CancelButton";
import SubmitButton from "@/components/buttons/SubmitButton";
import { Field } from "@/components/ui/field"
import { InputGroup } from "@/components/ui/input-group"

export interface ExpensesFormProps {
    expenseId?: number;
    organizationId: number;
    onSubmit: (ps: Expense) => Promise<void>;
    viewOnly?: boolean;
    header: string;
}

const ExpensesForm = ({ expenseId, organizationId, onSubmit, viewOnly, header }: ExpensesFormProps): JSX.Element => {
    const { control, register, handleSubmit, reset, formState: { isSubmitting } } = useForm<Expense>({
        defaultValues: {
            id: expenseId,
            projectId: -1,
            partnerId: -1,
            amount: 0,
            currency: 'CHF',
            entryDate: new Date(),
            comments: '',
            organizationId: organizationId
        }
    });
    const [serverError, setServerError] = useState('');
    const [projectNames, setProjectNames] = useState<LabelAndValueOption[]>([]);
    const [partnerNames, setPartnerNames] = useState<LabelAndValueOption[]>([]); // unique names for institutes
    const navigate = useNavigate();

    useEffect(() => {
        if (expenseId > -1) {
            expenseService.find(expenseId).then(p => reset(p));
        }
    }, [expenseId, reset]);

    useEffect(() => {
        let a = projectService.listNames().then(ps => {
            let ops = ps.map(p => {
                let n: LabelAndValueOption = {
                    label: p.name,
                    value: p.id
                }
                return n;
            });
            setProjectNames(ops);
        });
        let b = partnerService.listAll().then(ns => {
            let ops = ns.map(n => {
                let o: LabelAndValueOption = {
                    label: n.name,
                    value: n.id
                }
                return o;
            });
            setPartnerNames(ops);
        });
        Promise.all([a, b]);
    }, []);

    const readOnly = viewOnly ?? false;
    // console.info(`form is readonly: ${readOnly}`);

    const onSubmitInternal = (c: Expense): Promise<void> => {
        console.info(`submitting ${JSON.stringify(c)}`);
        return onSubmit(c)
            .then(() => navigate('/expenses'))
            .catch(_err => setServerError('Fehler'));
    };

    return (
        <Flex
            minH={{ base: '50vh', lg: '70vh' }}
            align={'center'}
            justify={'center'}
        >
            <form noValidate onSubmit={handleSubmit(onSubmitInternal)}>
                <Fieldset.Root size='md' maxW='md' rounded={'lg'}
                    boxShadow={'lg'}
                    py='3rem'
                    px='2rem'
                    invalid={serverError && serverError.length > 0}
                >
                    <Stack align={'center'}>
                        <Heading fontSize={'4xl'} textAlign={'center'}>{header}</Heading>
                    </Stack>

                    <Stack gap={4}>
                        {serverError && <ErrorMessage message={serverError} />}

                        <Controller control={control} name='partnerId'
                            rules={{ required: "Partner auswählen", validate: (value) => value > -1 || 'Partner auswählen' }}
                            render={({ field: { value, name, onChange, onBlur, ref }, fieldState: { error, invalid } }) => (
                                <Field id="partnerId" label='Partner' required disabled={readOnly} invalid={invalid} errorText={error?.message}>
                                    <ReactSelect
                                        isSearchable
                                        required
                                        isDisabled={readOnly}
                                        autoFocus={true}
                                        ref={ref}
                                        name={name}
                                        onBlur={onBlur}
                                        placeholder='Partner'
                                        options={partnerNames}
                                        onChange={val => onChange(val.value)}
                                        value={partnerNames.find(c => c.value === value)}
                                    />
                                </Field>
                            )} />

                        <Controller control={control} name='projectId'
                            rules={{ required: "Projekt auswählen", validate: (value) => value > -1 || "Projekt auswählen" }}
                            render={({ field: { value, name, onChange, onBlur, ref }, fieldState: { error, invalid } }) => (
                                <Field id="projectId" label='Projekt' required disabled={readOnly} invalid={invalid} errorText={error?.message}>
                                    <ReactSelect
                                        isSearchable
                                        required
                                        isDisabled={readOnly}
                                        autoFocus={false}
                                        ref={ref}
                                        name={name}
                                        onBlur={onBlur}
                                        placeholder='Projekt'
                                        options={projectNames}
                                        onChange={val => onChange(val.value)}
                                        value={projectNames.find(c => c.value === value)}
                                    />
                                </Field>
                            )} />

                        <DateSelectController label='Datum' required disabled={readOnly} control={control} name='entryDate' clearable={false} />
                        <Stack direction={{ base: 'column', sm: 'row' }}>
                            <Controller control={control} name='amount'
                                rules={{ required: "Summe eingeben", validate: (value) => value > 0 || "Summe eingeben" }}
                                render={({ field: { value, name, onChange, onBlur, ref }, fieldState: { error, invalid } }) => {
                                    console.log(error);
                                    return (
                                        <Field id="amount" label='Summe' required invalid={invalid} errorText={error?.message} disabled={readOnly}>
                                            <InputGroup startElement={<BiWallet color='blue' />}>
                                                <CustomAmountInput value={value} onChange={onChange} onBlur={onBlur} ref={ref} />
                                            </InputGroup>
                                        </Field>
                                    )
                                }} />

                            <CurrencySelect control={control} name='currency' readOnly={readOnly} />
                        </Stack>

                        <Field id="comments" label='Bemerkungen' disabled={readOnly}>
                            <Textarea {...register('comments')} />
                        </Field>

                        {/* submittion */}
                        <Flex justifyContent='flex-end'>
                            <Group gap={2}>
                                <CancelButton onClick={() => navigate('/expenses/')} />
                                <SubmitButton isSubmitting={isSubmitting} />
                            </Group>
                        </Flex>
                    </Stack>
                </Fieldset.Root>
            </form>
        </Flex>
    );
}

export default ExpensesForm