import {
  Input,
  Text,
  Textarea,
  VStack,
  Flex,
  Box,
  Group
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Absence as AbsenceEntry } from "src/pages/timesheet/AbsenceData";
import { elapsedMinutes, formatMinutes } from "src/data/DateTimeUtil";
import { de } from 'date-fns/locale';
import { format } from "date-fns";
import {
  chakraComponents,
  OptionBase,
  Select as ReactSelect
} from "chakra-react-select";
import { absenceData } from "./AbsenceData";
import CancelButton from "src/components/buttons/CancelButton";
import SubmitButton from "@/components/buttons/SubmitButton";
import DateSelectController from "@/components/fields/DateSelect";
import { Field } from "@/components/ui/field";
import { DialogRoot, DialogContent, DialogHeader, DialogBody, DialogCloseTrigger, DialogFooter } from "@/components/ui/dialog";

interface AbsenceDialogProps {
  isOpen: boolean;
  onClose: () => void;
  entry: AbsenceEntry;
  onSave: (data: AbsenceEntry) => void;
}

interface EntryOption extends OptionBase {
  label: string;
  value: number;
  detail?: string;
  color: string;
}

const options: EntryOption[] = absenceData.map((e) => ({
  label: e.name,
  value: e.id,
  color: e.color,
  detail: e.detail,
}));

const customItem = (item: EntryOption) => (
  <Flex align="center" justifyContent="flex-start">
    <Box w="1rem" h="1rem" borderRadius="50%" bg={item?.color} mr="1rem" />
    <Text ml="1px">{item?.label}</Text>
    <Text ml="1px" fontSize='.8rem' color="gray.500">{item?.detail}</Text>
  </Flex>
);

const customOption = (props) => (
  <chakraComponents.Option label={props.data.name} {...props}>
    {customItem(props.data)}
  </chakraComponents.Option>
);

const AbsenceDialog = ({ isOpen, onClose, entry, onSave }: AbsenceDialogProps) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    watch,
  } = useForm<AbsenceEntry>({
    defaultValues: entry
  });

  const localeOption = { locale: de };
  const startTime = watch("startDate");
  const endTime = watch("endDate");
  // TODO
  const duration: string = '10'; //formatMinutes(elapsedMinutes(startTime, endTime));

  const validateTimes = (endTime: Date) => {
    // if (!startTime || !endTime) return true;

    // const [startHour, startMinute] = startTime.split(":").map(Number);
    // const [endHour, endMinute] = endTime.split(":").map(Number);

    // const startMinutes = startHour * 60 + startMinute;
    // const endMinutes = endHour * 60 + endMinute;

    // return endMinutes > startMinutes || 'Endzeit muss nach der Startzeit liegen';
    return true; // TODO
  };

  useEffect(() => {
    reset(entry);
  }, [entry, reset, isOpen]);

  const onSubmit: SubmitHandler<AbsenceEntry> = (data) => {
    // update minutes
    data.days = 2; // TODO
    onSave(data);
    onClose();
  };

  return (
    <DialogRoot open={isOpen} onOpenChange={() => onClose()} size="md" placement='center' motionPreset='scale'>
      <DialogContent>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <DialogHeader>
            {/* TODO <Text textAlign="left" fontSize="sm">{format(entry.date, 'EEEE d. MMMM', localeOption)}</Text> */}
            <Text textAlign='center'>
              {entry.id > -1 ? 'Abwesenheit bearbeiten' : 'Neue Abwesenheit'}
            </Text>
          </DialogHeader>
          <DialogCloseTrigger />

          <DialogBody>
            <VStack gap={4}>
              {/* absence data entries with colors */}
              <Controller control={control} name='entryId'
                render={({ field: { value, name, onChange, onBlur, ref }, fieldState: { invalid } }) => (
                  <Field id="entryId" label='Abwesenheit' invalid={invalid} required errorText={errors.entryId?.message}>
                    <ReactSelect
                      isSearchable
                      autoFocus={true}
                      required
                      ref={ref}
                      name={name}
                      onBlur={onBlur}
                      placeholder='Eintrag wählen'
                      components={{ Option: customOption }}
                      formatOptionLabel={(option: EntryOption) => customItem(option)}
                      options={options}
                      onChange={val => onChange(val?.value)}
                      value={options.find(c => c.value === value)}
                      defaultValue={options[0]}
                    />
                  </Field>
                )} />

              {/* Time inputs in the same row */}
              <Flex gap={4} w="full">
                <DateSelectController required label='Startzeit' control={control} name='startDate' clearable={false} />

                <DateSelectController required control={control} label='Endzeit' name='endDate' clearable={false} />

                <Field label='Tagen' disabled w='10rem'>
                  <Input
                    value={duration}
                    readOnly
                    bg="gray.50"
                    _dark={{ bg: 'gray.700' }}
                  />
                </Field>
              </Flex>

              <Field label='Anmerkungen' invalid={!!errors.comments} errorText={errors.comments?.message}>
                <Textarea
                  placeholder='Kommentar hinzufügen...'
                  {...register("comments")}
                />
              </Field>
            </VStack>
          </DialogBody>


          <DialogFooter>
            <Group gap={2}>
              <CancelButton onClick={onClose} />
              <SubmitButton isSubmitting={isSubmitting} />
            </Group>
          </DialogFooter>
        </form>
      </DialogContent>
    </DialogRoot>
  );
};

export default AbsenceDialog;
