// Generated by ScalaTS 0.7.0: https://scala-ts.github.io/scala-ts/

declare var exports: any;

export const nsAdmin = exports;

export const AdminInhabitant = 'Admin';

export type Admin = typeof AdminInhabitant;

export function isAdmin(v: any): v is Admin {
  return AdminInhabitant == v;
}

export type AdminSingleton = Admin;
