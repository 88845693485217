import { Text, Box, Grid, GridItem, useDisclosure, Flex, IconButton, Separator, Stack } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import Content from "src/components/Content"
import { format, startOfWeek, addDays, startOfMonth, endOfMonth, endOfWeek, isSameMonth, isSameDay, isWeekend, isToday, getWeek } from "date-fns";
import { de } from 'date-fns/locale';
import MonthNavigation from "./MonthNavigation";
import { timesheetService } from "src/service/TimesheetService";
import { formatMinutes, formatIso8601 } from "src/data/DateTimeUtil";
import TimesheetModal from "./TimesheetDialog";
import { Holiday } from "src/api/Holiday";
import { Timesheet as TimesheetEntry } from "src/api/Timesheet";
import IdAndNameData from "src/data/IdAndNameData";
import ConfirmDialog from "src/components/ConfirmDialog";
import { TimesheetData, timesheetDataMap } from "./TimesheetData";
import { useAuthContext } from "src/auth/AuthContext";
import WaitingSpinner from "@/components/WaitingSpinner";
import { AddIcon, DeleteIcon } from "@/components/icons/Icon";
import { StatLabel, StatRoot, StatHelpText } from "@/components/ui/stat"
import { Button } from "@/components/ui/button";
import { Tag } from "@/components/ui/tag";
import StatContentText from "@/components/StatContentText";


const TimesheetCalendar = () => {
  const localeOption = { locale: de }
  const VISIBLE_ENTRIES = 2;
  const gridWidth = '11rem';
  const gridHeight = '10rem';

  const { user } = useAuthContext();

  const [deleteData, setDeleteData] = useState<IdAndNameData<number> | null>(null);
  const { open: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
  const emptyTimesheetEntry: TimesheetEntry = {
    id: -1, 
    date: new Date(), 
    startTime: '', 
    endTime: '', 
    minutes: 0,
    entryId: undefined,
    projectId: undefined,
    comments: undefined,
    userId: user?.data?.id,
    organizationId: user?.data?.organizationId
  };
  const [editData, setEditData] = useState<TimesheetEntry>(emptyTimesheetEntry);
  const { open: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();

  const [isLoading, setIsLoading] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [timesheetEntries, setTimesheetEntries] = useState<TimesheetEntry[]>([]);
  const [holidayMap, setHolidayMap] = useState<Map<string, Holiday>>(new Map());
  // summary tab
  const [monthMinutes, setMonthMinutes] = useState<number>(0);

  useEffect(() => {
    setIsLoading(true);

    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart, localeOption);
    const endDate = endOfWeek(monthEnd, localeOption);

    const userId = user?.data?.id;
    const from = formatIso8601(startDate);
    const to = formatIso8601(endDate);
    console.log(`timesheets[${userId}] between ${from} -> ${to}`);
    const promiseTs = userId ? timesheetService
      .findAll(user?.data?.id, from, to)
      .then(r => setTimesheetEntries(r)) : Promise.resolve();
    const promiseHd = timesheetService
      .publicHolidays(currentMonth.getFullYear())
      .then(r => setHolidayMap(new Map(r.map(h => [formatIso8601(h.date), h]))))
    Promise.all([promiseTs, promiseHd])
      .finally(() => setIsLoading(false))
  }, [currentMonth, user?.data?.id]);

  useEffect(() => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const minutes = timesheetEntries.filter(e => {
      const date = new Date(e.date);
      return date.getTime() >= monthStart.getTime() && date.getTime() <= monthEnd.getTime()
    }).reduce((sum, entry) => sum + entry.minutes, 0);
    setMonthMinutes(minutes);
  }, [timesheetEntries, currentMonth]);


  const SummaryTabs = () => (<>
    <Flex pt='1rem' justify='left' direction={{ base: 'column', sm: 'row' }} mb='3rem'>
      <Stack shadow='md' rounded='md' textAlign='right' border={'1px solid lightgray'} direction={{ base: 'row', sm: 'row' }}>
        <StatRoot px='1rem' key='s1' borderRight='1px solid lightgray'>
          <StatLabel>Stundensaldo</StatLabel>
          <Separator orientation='horizontal' />
          <StatContentText>{formatMinutes(monthMinutes)}</StatContentText>
          <StatHelpText fontSize='xs'>{format(currentMonth, 'MMMM', localeOption)}</StatHelpText>
        </StatRoot>
        <StatRoot px='1rem' key='s2' borderRight='1px solid lightgray'>
          <StatLabel>Abwesenheit</StatLabel>
          <Separator orientation='horizontal' />
          <StatContentText>🏗️</StatContentText>
          <StatHelpText fontSize='xs'>Im Aufbau</StatHelpText>
        </StatRoot>
        <StatRoot px='1rem' key='s3' borderRight='1px solid lightgray'>
          <StatLabel>Überstunden</StatLabel>
          <Separator orientation='horizontal' />
          <StatContentText>🏗️</StatContentText>
          <StatHelpText fontSize='xs'>Im Aufbau</StatHelpText>
        </StatRoot>
        <StatRoot px='1rem' key='s4' borderRight='1px solid lightgray'>
          <StatLabel>Arbeitszeitmodell</StatLabel>
          <Separator orientation='horizontal' />
          <StatContentText>🏗️</StatContentText>
          <StatHelpText fontSize='xs'>Im Aufbau</StatHelpText>
        </StatRoot>
      </Stack>
    </Flex>
  </>);

  const renderDays = () => {
    const days = [];
    let startDate = startOfWeek(currentMonth, { locale: de });

    for (let i = 0; i < 7; i++) {
      days.push(
        <GridItem key={i} minW={gridWidth}>
          <Text textAlign="center" fontWeight="bold">{format(addDays(startDate, i), 'EEEE', localeOption)}</Text>
        </GridItem>
      );
    }
    return <Grid templateColumns='repeat(7, 1fr)' gap={0}>{days}</Grid>;
  };

  interface TimesheetCellProps {
    hover: boolean;
    day: Date;
    dailyEntries: TimesheetEntry[];
    holiday: Holiday | undefined;
  }

  const TimesheetCell = ({ hover, day, dailyEntries, holiday }: TimesheetCellProps) => {
    const maxEntries = hover ? dailyEntries.length : VISIBLE_ENTRIES;
    const formattedDate = format(day, "d");
    const hasMoreEntries = dailyEntries.length > VISIBLE_ENTRIES;
    const weekNumber = getWeek(day, { locale: de });
    const today = isToday(day);
    const sunday = isSameDay(day, endOfWeek(day, { locale: de }));
    const dailyMinutes = dailyEntries.map(e => e.minutes).reduce((a, b) => a + b, 0);

    return (
      <>
        <Flex justify={'space-between'} pr='1' bgColor={today ? 'blue.500' : 'none'} color={today ? 'white' : 'none'}>
          <Tag size='sm' shadow='sm' variant={today ? 'solid' : 'subtle'} colorPalette={today ? 'blue' : 'yellow'}>{formattedDate}</Tag>
          {holiday && (<Text fontWeight='bold' fontSize='.8rem'>{holiday.name} 🌴</Text>)}
          {sunday && <Text fontWeight='bold' textStyle='xs'>W{weekNumber}</Text>}
          {dailyMinutes > 0 && <Text fontWeight='bold' fontSize='.7rem'>{formatMinutes(dailyMinutes)} 🕗</Text>}
        </Flex>

        {dailyEntries.slice(0, maxEntries).map(entry => {
          const formattedElapsed = formatMinutes(entry.minutes);
          const entryType: TimesheetData | undefined = timesheetDataMap.get(entry?.entryId);

          return (
            <Box key={entry.id} mt='1' p='0'
              borderRadius="md" borderWidth='1px'
              borderLeftWidth='8px'
              borderColor={entryType?.color ?? 'gray'}
              bgColor='gray.100'
              boxShadow='sm'
              position="relative"
              _hover={{
                bgColor: 'blue.300',
                boxShadow: 'lg',
                '& .entry-delete-button': {
                  display: 'flex',
                  zIndex: 20
                },
                '& .time-container': {
                  bgColor: entryType?.color ?? 'gray'
                }
              }}
              onClick={(e) => {
                console.log(`selected time entry ${day} - ${entry}`);
                setEditData(entry);
                onEditOpen();
              }}
            >
              <Flex direction='row' alignItems='center' justify="space-between">
                <Flex
                  className='time-container'
                  direction="column"
                  align="flex-start"
                  p='0' m='0'
                  bgColor='none'
                >
                  <Text fontSize="xs" color="gray.600">{entry.startTime}</Text>
                  <Text fontSize="xs" color="gray.600">{entry.endTime}</Text>
                </Flex>
                <Text fontSize={entryType?.detail ? 'xs' : 'sm'} fontWeight='medium' mt="1">{entryType?.name}</Text>
                <Text fontSize="xs" fontWeight='normal' mt="1">{entryType?.detail}</Text>
                <Flex direction="column" align="flex-end" p='0' m='0'>
                  <Text fontSize="xs" color="gray.500">{formattedElapsed}</Text>
                </Flex>
              </Flex>

              <IconButton
                className='entry-delete-button'
                aria-label="Remove entry"
                position='absolute'
                size='sm'
                top='0'
                right='0'
                display='none'
                cursor='pointer'
                alignItems="center"
                justifyContent="center"
                onClick={(e) => {
                  e.stopPropagation();
                  let da: IdAndNameData<number> = {
                    id: entry.id,
                    name: `${entry.startTime} - ${entry.endTime}`
                  };
                  setDeleteData(da);
                  onDeleteOpen();
                }}
                colorPalette='red'
              ><DeleteIcon /></IconButton>
            </Box>
          );
        })}
        {!hover && hasMoreEntries && (
          <Text fontSize="xs" mt={1} color="gray.600">
            +{dailyEntries.length - VISIBLE_ENTRIES} mehr...
          </Text>
        )}
        {hover && (

          <Button mt='4px' w='full' bgColor='gray.100'
            colorScheme='blue'
            variant='outline'
            _hover={{
              bgColor: 'blue.300',
              boxShadow: 'lg'
            }}
            onClick={(e) => {
              console.log(`selected time entry ${day} - NEW`);
              handleNewEntry(day, dailyEntries);
            }}
          >
            <AddIcon /> Neuer Eintrag
          </Button>

        )}
      </>
    )
  }

  const renderCells = () => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart, localeOption);
    const endDate = endOfWeek(monthEnd, localeOption);

    const rows = [];
    let days = [];
    let day = startDate;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const cloneDay = new Date(day);
        const weekend = isWeekend(day);
        const today = isToday(day);
        const holiday: Holiday | null = holidayMap.get(formatIso8601(day));
        const dailyEntries = timesheetEntries.filter(entry => isSameDay(entry.date, day));

        // styling for weekend or holidays
        const green = holiday ? '200' : '0'
        const alpha = holiday ? '0.2' : '0.05'
        const backgroundImage = weekend || holiday
          ? `repeating-linear-gradient(135deg, transparent, transparent 8px, rgba(0,${green},0,${alpha}) 8px, rgba(0,${green},0,${alpha}) 10px)`
          : 'none'

        // 10rem tall cells, defined in the gridHeight
        days.push(
          <GridItem
            key={day.toString()}
            mt={0}
            p={0}
            position='relative'
            bg={isSameMonth(day, monthStart) ? 'gray.50' : "gray.200"}
            color={isSameMonth(day, monthStart) ? 'black' : "gray.500"}
            backgroundImage={backgroundImage}
            border={today ? '1px solid #3182CE' : '1px solid #ccc'}
            borderRadius='md'
            boxShadow='md'
            textAlign='center'
            cursor='pointer'
            minW={gridWidth}
            _hover={{
              boxShadow: 'lg',
              '& .entries-container': {
                display: 'block',
                zIndex: 10
              }
            }}
          >
            {/* Regular view (limited entries) */}
            <Box
              m='1px'
            >
              <TimesheetCell hover={false} day={cloneDay} dailyEntries={dailyEntries} holiday={holiday} />
            </Box>

            {/* Hover view (all entries) */}
            <Box
              className='entries-container'
              position="absolute"
              top="0" // Position the box to appear at the top of the cell
              left="0"
              right="0"
              display="none" // Initially hidden
              bg="blue.500"
              border='1px solid #3182CE'
              boxShadow='2xl'
              borderRadius='md'
              p={0}
              pb='1.5rem'
              maxH="350px"
              minH={gridHeight}
              overflowY="auto"
              zIndex={1000} // Ensure it appears above other elements
            >
              <TimesheetCell hover={true} day={cloneDay} dailyEntries={dailyEntries} holiday={holiday} />
            </Box>

          </GridItem >
        );
        day = addDays(day, 1);
      }
      rows.push(
        <Grid templateColumns="repeat(7, 1fr)" h={gridHeight} gap={0} key={day.toString()}>
          {days}
        </Grid>
      );
      days = [];
    }
    return <Box>{rows}</Box>;
  };

  const handleEntrySave = (data: TimesheetEntry) => {
    onEditClose();
    console.log('saving entry...');
    // update id for new entries, save it first to BE
    const newEntry = data.id < 0;
    const promise: Promise<number> = newEntry ? timesheetService.add(data) : timesheetService.update(data);
    promise.then((id: number) => {
      const updatedEntry = { ...data, id };
      if (newEntry) {
        // add new entry to the list
        setTimesheetEntries(prevEntries => [...prevEntries, updatedEntry]);
      } else {
        // update existing entry in the list
        setTimesheetEntries(prevEntries => prevEntries.map(entry => entry.id === updatedEntry.id ? updatedEntry : entry));
      }
    });
  };

  const handleEntryDelete = useCallback((id: number, name: string): Promise<void> => {
    console.info(`deleting ${id} ${name}`);
    timesheetService.remove(user.data.id, id);
    setTimesheetEntries(prevEntries => prevEntries.filter(entry => entry.id !== id));
    return;
  }, []);

  // TODO: propose start/end times based on previous or daily activities
  const handleNewEntry = (day: Date, entries: TimesheetEntry[]) => {
    const proposedTimesheetEntry: TimesheetEntry = {
      id: -1,
      date: day,
      startTime: '08:00',
      endTime: '17:00',
      minutes: 8 * 60,
      entryId: undefined,
      projectId: undefined,
      comments: undefined,
      userId: user.data.id,
      organizationId: user.data.organizationId
    };
    setEditData(proposedTimesheetEntry);
    onEditOpen();
  }

  return (
    <Box>
      <SummaryTabs />
      <Flex alignItems='center' gap={4}>
        <MonthNavigation currentMonth={currentMonth} setCurrentMonth={setCurrentMonth} language={de} />
        <Button variant='solid' colorPalette='blue' mb='1rem'
          aria-label='Neuer Eintrag'
          onClick={(e) => {
            const day = new Date();
            console.log(`selected time entry ${day} - NEW`);
            handleNewEntry(day, []);
          }}><AddIcon /> Neuer Eintrag</Button>
        {isLoading && (
          <WaitingSpinner size='lg' mb='1rem' />
        )}
      </Flex>

      {renderDays()}
      {renderCells()}

      <TimesheetModal isOpen={isEditOpen} onClose={onEditClose} entry={editData} onSave={handleEntrySave} />
      <ConfirmDialog
        heading={`${deleteData?.name} Löschen`}
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        onConfirm={async (): Promise<void> => handleEntryDelete(deleteData.id, deleteData.name)}
      />
    </Box>
  );
};

const OverviewTimesheet = () => {
  return (
    <Content caption='Zeiterfassung ⏱️'>
      <TimesheetCalendar />
      <Box mt='5rem' />
    </Content>
  );
}

export default OverviewTimesheet;