import { Flex, Grid, GridItem, useDisclosure } from "@chakra-ui/react";
import Content from "src/components/Content"
import { useState } from "react";
import {
  Box,
  Text,
} from "@chakra-ui/react";
import { format, startOfMonth, endOfMonth, eachDayOfInterval, isSameDay, isWeekend } from 'date-fns';
import Construction from "src/components/Construction";
import React from "react";
import { de } from 'date-fns/locale';
import MonthNavigation from "./MonthNavigation";
import { AddIcon } from "@/components/icons/Icon";
import { useAuthContext } from "src/auth/AuthContext";
import { Absence } from "./AbsenceData";
import WaitingSpinner from "@/components/WaitingSpinner";
import { Button } from "@/components/ui/button"
import { Avatar } from "@/components/ui/avatar"
import AbsenceDialog from "./AbsenceDialog";


const users = [
  { id: 1, name: "John Doe", holidays: ["2024-12-25", "2024-12-26"] },
  { id: 2, name: "Jane Smith", holidays: ["2024-12-24"] },
  { id: 3, name: "Alice Johnson", holidays: ["2024-12-31", "2025-01-01"] },
  { id: 3, name: "Lukas Jackson", holidays: ["2024-12-11", "2025-01-03"] },
];

const AbsenceCalendar = () => {
  const { user } = useAuthContext();

  const emptyAbsenceEntry: Absence = {
    id: -1, startDate: new Date(), endDate: new Date(), days: 0,
    entryId: undefined,
    comments: undefined,
    userId: user?.data?.id,
    organizationId: user?.data?.organizationId
  };
  const [editData, setEditData] = useState<Absence>(emptyAbsenceEntry);
  const { open: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();

  const [isLoading, setIsLoading] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const monthStart = startOfMonth(currentMonth);
  const monthEnd = endOfMonth(monthStart);
  const days = eachDayOfInterval({ start: monthStart, end: monthEnd });

  const renderCells = () => {
    return (
      <Grid templateColumns={`repeat(${days.length + 1}, 1fr)`} gap={1}>
        {/* Header Row */}
        <GridItem colSpan={1} bg="gray.200" p={2} width='12rem' borderRadius='md' boxShadow='md'>
          <Text fontWeight="bold">Mitarbeitende</Text>
        </GridItem>
        {days.map((day, index) => {
          const weekend = isWeekend(day);
          return (
            <GridItem key={index} bg='gray.200' p={2} textAlign='center' borderRadius='md' boxShadow='md'>
              <Text color={weekend ? 'green' : 'none'} fontWeight="bold">{format(day, 'd')}</Text>
              <Text color={weekend ? 'green' : 'none'} fontSize="xs">{format(day, 'EE', { locale: de })}</Text>
            </GridItem>
          );
        })}

        {/* User Rows */}
        {users.map((user, userIndex) => (
          <React.Fragment key={userIndex}>
            <GridItem colSpan={1} bg="gray.100" p={2}>
              <Flex gap='.3rem'>
                <Avatar
                  size='sm'
                  // color={avatarColor}
                  bg='blue.500'
                  borderless={false}
                  name={user.name}
                //src={userService.avatarUrl(webUser)}
                >
                  {/* <AvatarBadge boxSize='1em' bg='green.500' /> */}
                </Avatar>
                <Text display='inline-flex' alignItems='center'>{user.name}</Text>
              </Flex>
            </GridItem>
            {days.map((day, dayIndex) => {
              const weekend = isWeekend(day);
              const holiday = user.holidays.some(holiday => isSameDay(new Date(holiday), day));
              return (
                <GridItem
                  key={dayIndex}
                  bg={holiday ? 'green.400' : 'gray.50'}
                  borderRadius={holiday || weekend ? 'md' : 'none'}
                  boxShadow={holiday ? 'md' : 'none'}
                  p={2}
                  textAlign='center'
                  backgroundImage={
                    weekend
                      ? "repeating-linear-gradient(135deg, transparent, transparent 5px, rgba(0,0,0,0.05) 5px, rgba(0,0,0,0.05) 10px)"
                      : "none"
                  }
                >
                  {holiday && (
                    <Flex h="100%" align="center" justify="center">
                      <Text fontWeight='bold'>🌴</Text>
                    </Flex>
                  )}
                </GridItem>
              );
            })}
          </React.Fragment>
        ))}
      </Grid>
    );
  }

  const handleEntrySave = (data: Absence) => {
      onEditClose();
      console.log('saving entry...');
  }

  const handleNewEntry = (day: Date) => {
    console.info('new absence')
    //setEditData(proposedTimesheetEntry);
    onEditOpen();
  }

  return (
    <Box>
      <Flex alignItems='center' gap={4}>
        <MonthNavigation currentMonth={currentMonth} setCurrentMonth={setCurrentMonth} language={de} />
        <Button variant='solid' colorPalette='blue' mb='1rem'
          aria-label='Neuer Eintrag'
          disabled={false}
          onClick={(e) => {
            const day = new Date();
            console.log(`selected absence entry ${day} - NEW`);
            handleNewEntry(day);
          }}><AddIcon /> Neuer Eintrag</Button>
        {isLoading && (
          <WaitingSpinner size='lg' mb='1rem' />
        )}
      </Flex>
      {renderCells()}
      <AbsenceDialog isOpen={isEditOpen} onClose={onEditClose} entry={editData} onSave={handleEntrySave} />
    </Box>
  );
};

const OverviewAbsence = () => {

  return (
    <Content caption='Abwesenheitskalender 🗓️'>
      <Construction withImage={false} />
      <AbsenceCalendar />
    </Content>
  );
}

export default OverviewAbsence;