import { Skeleton, Stack, Text, HStack, useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnDef, CellContext, Row } from '@tanstack/react-table';
import { Partner } from 'src/api/Partner';
import DataTable, { dateCell } from 'src/components/DataTable';
import { partnerService } from 'src/service/PartnerService';
import CountryFlag from 'src/components/CountryFlag';
import { countries } from 'src/data/Countries';
import ConfirmDialog from 'src/components/ConfirmDialog';
import IdAndNameData from 'src/data/IdAndNameData';
import Content from 'src/components/Content';
import AddButton from 'src/components/buttons/AddButton';
import PartnersProps from './PartnersProps';
import WaitingSpinner from '@/components/WaitingSpinner';
import { DropDownMenu } from '@/components/DropDownMenu';

const OverviewPartners = ({ consultant }: PartnersProps) => {

  const [data, setData] = useState<Partner[]>([]);
  const [deleteData, setDeleteData] = useState<IdAndNameData<number> | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const { open: isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();

  const onDelete = useCallback((id: number, name: string): Promise<void> => {
    console.info(`deleting ${id} ${name}`);
    return partnerService.remove(id)
      .then(() => setDeleteData(null))
      .then(() => window.location.reload());
  }, []);

  const onEdit = useCallback((id: number): void => {
    console.info(`editing ${id}`);
    navigate(`/partners/edit/${id}`);
  }, [navigate]);

  const onView = useCallback((id: number): void => {
    console.info(`viewing ${id}`);
    navigate(`/partners/view/${id}`);
  }, [navigate]);

  useEffect(() => {
    setIsLoading(true);
    partnerService
      .listFiltered(consultant)
      .then(r => setData(r.concat()))
      .finally(() => setIsLoading(false));
  }, [consultant]);

  const columns: ColumnDef<Partner>[] = useMemo(
    () => [
      {
        id: 'name',
        header: 'Name',
        accessorKey: 'name',
        cell: (info: CellContext<Partner, string>) => (<Text fontWeight={'bold'}>{info.getValue()}</Text>),
      },
      {
        id: 'country',
        header: 'Land',
        accessorKey: 'country',
        cell: (info: CellContext<Partner, string>) => (
          <HStack>
            <CountryFlag code={info.getValue()} />
            <Text>{countries[info.getValue()]}</Text>
          </HStack>
        ),
        sortingFn: (rowA: Row<Partner>, rowB: Row<Partner>, _): number => {
          const a = rowA.original;
          const b = rowB.original;
          let ap = countries[a.country] ?? '';
          let bp = countries[b.country] ?? '';
          return ap.trim().localeCompare(bp.trim(), undefined, { sensitivity: 'base' });
        },
      },
      {
        id: 'currency',
        header: 'Währung',
        accessorFn: (row: Partner) => row.currency,
      },
      {
        id: 'bankName',
        header: 'Bank',
        accessorFn: (row: Partner) => row.bankName
      },
      {
        id: 'createdAt',
        header: () => (<div style={{ textAlign: 'right', width: '6rem' }}>Erstellt</div>),
        accessorFn: (row: Partner) => row.createdAt,
        cell: dateCell()
      },
      {
        id: 'edit',
        enableSorting: false,
        cell: info => {
          let row = info.row.original;
          return (
            <DropDownMenu onMenuView={() => onView(row.id)} onMenuEdit={() => onEdit(row.id)} onMenuDelete={() => {
              let da: IdAndNameData<number> = {
                id: row.id,
                name: row.name
              };
              setDeleteData(da);
              onOpen();
            }} />
          )
        }
      }
    ], [onEdit, onOpen, onView]
  );

  return (
    <Content caption={consultant ? 'Konsulenten' : 'Partners'}>
      <AddButton
        caption={`Neuer ${consultant ? 'Konsulent' : 'Partner'}`}
        onClick={() => navigate(consultant ? '/partners/consultants/add' : '/partners/add')} />
      {isLoading ? (
        <Stack py={5}>
          <WaitingSpinner size='xl' />
          <Skeleton height='20px' />
          <Skeleton height='20px' />
          <Skeleton height='20px' />
        </Stack>
      ) : (
        <>
          <DataTable columns={columns} data={data} initialSortByColumn='createdAt' initialSortByDesc={true} hideColumnsOnXs={['currency', 'bankName', 'createdAt']}
            onRowClick={row => onEdit(row.id)}
          />
          <ConfirmDialog
            heading={`${deleteData?.name} Löschen`}
            isOpen={isOpen}
            onClose={onClose}
            onConfirm={async (): Promise<void> => onDelete(deleteData.id, deleteData.name)}
          />
        </>
      )}
    </Content>
  );
}

export default OverviewPartners