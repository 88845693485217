import { Skeleton, Text, Stack, useDisclosure, HStack, Input } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColumnDef, CellContext, Row } from '@tanstack/react-table';
import { ProjectSummary } from 'src/api/ProjectSummary';
import ConfirmDialog from 'src/components/ConfirmDialog';
import DataTable, { amountHeader, dateCell, valueCurrencyText } from 'src/components/DataTable';
import IdAndNameData from 'src/data/IdAndNameData';
import { projectService } from 'src/service/ProjectService';
import CountryFlag from 'src/components/CountryFlag';
import { countries } from 'src/data/Countries';
import Content from 'src/components/Content';
import AddButton from 'src/components/buttons/AddButton';
import WaitingSpinner from '@/components/WaitingSpinner';
import { Button } from '@/components/ui/button';
import { CloseIcon } from '@/components/icons/Icon';
import { InputGroup } from '@/components/ui/input-group';
import { DropDownMenu } from '@/components/DropDownMenu';
import PartnerLink from '@/components/PartnerLink';

const OverviewProjects = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<ProjectSummary[]>([]);
    const [partners, setPartners] = useState<Map<number, string>>(new Map());
    const [deleteData, setDeleteData] = useState<IdAndNameData<number> | null>(null);
    const [nameFilter, setNameFilter] = useState('');

    const { open: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();

    const navigate = useNavigate();

    const onDelete = useCallback((id: number, name: string): Promise<void> => {
        console.info(`deleting ${id} ${name}`);
        return projectService.remove(id)
            .then(() => setDeleteData(null))
            .then(() => window.location.reload());
    }, []);

    const onEdit = useCallback((id: number): void => {
        console.info(`editing ${id}`);
        navigate(`/projects/edit/${id}`);
    }, [navigate]);

    const onView = useCallback((id: number): void => {
        console.info(`viewing ${id}`);
        navigate(`/projects/view/${id}`);
    }, [navigate]);

    useEffect(() => {
        setIsLoading(true);
        projectService
            .listAll()
            .then(r => {
                const partnerId2Name = new Map(r.partners.map(obj => [obj.id, obj.name]));
                setPartners(partnerId2Name);
                setData(r.results.concat());
            })
            .finally(() => setIsLoading(false));

    }, []);

    const onFilter = (row: ProjectSummary, value: string): boolean => {
        if (value.startsWith('name')) {
            const check = nameFilter.trim().toLowerCase();
            return row.project.name.trim().toLowerCase().includes(check);
        }
        return true;
    }

    const columns: ColumnDef<ProjectSummary>[] = useMemo(
        () => [
            {
                id: 'country',
                header: 'Land',
                accessorKey: 'project.country',
                cell: (info: CellContext<ProjectSummary, string>) => (
                    <HStack>
                        <CountryFlag code={info.getValue()} />
                        <Text>{countries[info.getValue()]}</Text>
                    </HStack>
                ),
                sortingFn: (rowA: Row<ProjectSummary>, rowB: Row<ProjectSummary>, _: any): number => {
                    const a = rowA.original;
                    const b = rowB.original;
                    let ap = countries[a.project.country] ?? '';
                    let bp = countries[b.project.country] ?? '';
                    return ap.trim().localeCompare(bp.trim(), undefined, { sensitivity: 'base' });
                },
            },
            {
                id: 'name',
                header: 'Name',
                accessorFn: (row: ProjectSummary) => row.project.name.trim(),
                cell: (info: CellContext<ProjectSummary, string>) => (
                    <Text minW='8em' fontWeight={'bold'}>{info.getValue()}</Text>
                ),
            },
            {
                id: 'partner',
                header: 'Partner',
                accessorKey: 'partnerIds',
                cell: (info: CellContext<ProjectSummary, number[]>) => <PartnerLink partnerIds={info.getValue()} partners={partners} keyPrefix='{info.row.original.project.id}' />,
                sortingFn: (rowA: Row<ProjectSummary>, rowB: Row<ProjectSummary>, _): number => {
                    const a = rowA.original;
                    const b = rowB.original;
                    const ap = partners.get(a.partnerIds[0])?.trim() ?? '';
                    const bp = partners.get(b.partnerIds[0])?.trim() ?? '';
                    return ap.localeCompare(bp);
                },
            },
            {
                id: 'startDate',
                header: 'Anfang',
                accessorKey: 'project.startDate',
                cell: dateCell()
            },
            {
                id: 'budget',
                header: amountHeader({ name: 'Budget', width: '10rem' }),
                accessorFn: (row: ProjectSummary) => row.budget.reduce((a, yb) => a + (yb.budget ?? 0), 0),
                cell: (info: CellContext<ProjectSummary, number>) => valueCurrencyText(info.getValue(), info.row.original.project.currency, '10rem', 'normal'),
            },
            {
                id: 'budgetChf',
                header: amountHeader({ name: 'Projekt CHF', width: '8rem' }),
                accessorFn: (row: ProjectSummary) => row.budget.reduce((a, yb) => a + (yb.projectBudget ?? 0), 0),
                cell: (info: CellContext<ProjectSummary, number>) => valueCurrencyText(info.getValue(), 'CHF', '8rem', 'normal'),
            },
            {
                id: 'fundraisingChf',
                header: amountHeader({ name: 'Fundraising CHF', width: '8rem' }),
                accessorFn: (row: ProjectSummary) => row.budget.reduce((a, yb) => a + (yb.fundraisingBudget ?? 0), 0),
                cell: (info: CellContext<ProjectSummary, number>) => valueCurrencyText(info.getValue(), 'CHF', '8rem', 'normal'),
            },
            {
                id: 'edit',
                enableSorting: false,
                cell: info => {
                    const row = info.row.original;
                    return (
                        <DropDownMenu onMenuView={() => onView(row.project.id)} onMenuEdit={() => onEdit(row.project.id)} onMenuDelete={() => {
                            let da: IdAndNameData<number> = {
                                id: row.project.id,
                                name: row.project.name
                            };
                            setDeleteData(da);
                            onDeleteOpen();
                        }} />
                    )
                }
            }
        ], [partners, onDeleteOpen, onEdit, onView]
    );

    return (
        <Content caption='Projekte'>
            <HStack>
                <AddButton caption='Neues Projekt' onClick={() => navigate('/projects/add')} />
                <InputGroup width='lg' endElement={
                    <Button variant={'ghost'} onClick={() => setNameFilter('')}>
                        <CloseIcon />
                    </Button>
                }>
                    <Input width='lg' placeholder='Filter' autoFocus={true} value={nameFilter} onChange={ev => setNameFilter(ev.currentTarget.value)} />
                </InputGroup>
            </HStack>
            {isLoading ? (
                <Stack py={5}>
                    <WaitingSpinner size='xl' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                </Stack>
            ) : (
                <>
                    <DataTable columns={columns} data={data}
                        hideColumnsOnXs={['country', 'startDate', 'budget', 'budgetChf', 'fundraisingChf', 'edit']}
                        onRowClick={row => onEdit(row.project.id)}
                        initialSortByColumn={'country'} initialSortByDesc={true}
                        onFilter={onFilter} filter={nameFilter.trim().length > 0 ? `name${nameFilter}` : ''} // filter name needs to change to pickup the latest value
                    />
                    <ConfirmDialog
                        heading={`${deleteData?.name} Löschen`}
                        isOpen={isDeleteOpen}
                        onClose={onDeleteClose}
                        onConfirm={async (): Promise<void> => onDelete(deleteData.id, deleteData.name)}
                    />
                </>
            )}
        </Content>
    );

}

export default OverviewProjects