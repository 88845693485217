import {
  Input,
  Text,
  Textarea,
  VStack,
  Flex,
  Box,
  Group,
  Heading
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Timesheet as TimesheetEntry } from "src/api/Timesheet";
import { elapsedMinutes, formatMinutes } from "src/data/DateTimeUtil";
import { de } from 'date-fns/locale';
import { format } from "date-fns";
import {
  chakraComponents,
  OptionBase,
  Select as ReactSelect
} from "chakra-react-select";
import { timesheetData } from "./TimesheetData";
import CancelButton from "src/components/buttons/CancelButton";
import SubmitButton from "@/components/buttons/SubmitButton";
import { Field } from "@/components/ui/field";
import { DialogRoot, DialogContent, DialogHeader, DialogBody, DialogCloseTrigger, DialogFooter } from "@/components/ui/dialog";

interface TimesheetModalProps {
  isOpen: boolean;
  onClose: () => void;
  entry: TimesheetEntry;
  onSave: (data: TimesheetEntry) => void;
}

interface EntryOption extends OptionBase {
  label: string;
  value: number;
  detail?: string;
  color: string;
}

const options = timesheetData.map((e) => {
  let eo: EntryOption = {
    label: e.name,
    value: e.id,
    color: e.color,
    detail: e.detail
  }
  return eo;
});

const customItem = (item: EntryOption) => (
  <Flex align="center" justifyContent="flex-start">
    <Box w="1rem" h="1rem" borderRadius="50%" bg={item?.color} mr="2px" shadow='md' />
    <Text ml="1px">{item?.label}</Text>
    <Text ml="1px" fontSize='.8rem' color="gray.500">{item?.detail}</Text>
  </Flex>
);

const customOption = (props) => (
  <chakraComponents.Option label={props.data.name} {...props}>
    {customItem(props.data)}
  </chakraComponents.Option>
);

const TimesheetModal = ({ isOpen, onClose, entry, onSave }: TimesheetModalProps) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    watch,
  } = useForm<TimesheetEntry>({
    defaultValues: entry
  });

  const localeOption = { locale: de };
  const startTime = watch("startTime");
  const endTime = watch("endTime");
  const duration: string = formatMinutes(elapsedMinutes(startTime, endTime));

  const validateTimes = (endTime: string) => {
    if (!startTime || !endTime) return true;

    const [startHour, startMinute] = startTime.split(":").map(Number);
    const [endHour, endMinute] = endTime.split(":").map(Number);

    const startMinutes = startHour * 60 + startMinute;
    const endMinutes = endHour * 60 + endMinute;

    return endMinutes > startMinutes || 'Endzeit muss nach der Startzeit liegen';
  };

  useEffect(() => {
    reset(entry);
  }, [entry, reset, isOpen]);

  const onSubmit: SubmitHandler<TimesheetEntry> = (data) => {
    // update minutes
    data.minutes = elapsedMinutes(data.startTime, data.endTime);
    onSave(data);
    onClose();
  };

  return (
    <DialogRoot open={isOpen} onOpenChange={() => onClose()} size="md" placement='center' motionPreset='scale'>
      <DialogContent>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <DialogHeader>
            <Heading textAlign="left" size="md">{format(entry.date, 'EEEE d. MMMM', localeOption)}</Heading>
            <Heading textAlign='center' size='md'>
              {entry.id > -1 ? 'Zeiterfassung bearbeiten' : 'Neue Zeiterfassung'}
            </Heading>
          </DialogHeader>
          <DialogCloseTrigger />

          <DialogBody>
            <VStack gap={4}>
              {/* Time inputs in the same row */}
              <Flex gap={4} w="full">
                <Field label='Startzeit' required invalid={!!errors.startTime} errorText={errors.startTime?.message}>
                  <Input
                    type="time"
                    autoFocus={true}
                    {...register("startTime", {
                      required: 'Die Startzeit ist erforderlich'
                    })}
                  />
                </Field>

                <Field label='Endzeit' required invalid={!!errors.endTime} errorText={errors.endTime?.message}>
                  <Input
                    type="time"
                    {...register("endTime", {
                      required: 'Die Endzeit ist erforderlich',
                      validate: validateTimes
                    })}
                  />
                </Field>

                <Field label='Arbeitsstunden' disabled>
                  <Input
                    value={duration}
                    readOnly
                    bg="gray.50"
                    _dark={{ bg: 'gray.700' }}
                  />
                </Field>
              </Flex>

              {/* timesheet data entries with colors */}
              <Controller control={control} name='entryId'
                render={({ field: { value, name, onChange, onBlur, ref }, fieldState: { invalid } }) => (
                  <Field id="entryId" label='Eintrag' invalid={invalid} errorText={errors.entryId?.message}>
                    <ReactSelect
                      isSearchable
                      // isClearable
                      ref={ref}
                      name={name}
                      onBlur={onBlur}
                      placeholder='Eintrag wählen'
                      components={{ Option: customOption }}
                      formatOptionLabel={(option: EntryOption) => customItem(option)}
                      options={options}
                      onChange={val => onChange(val?.value)}
                      value={options.find(c => c.value === value)}
                    />
                  </Field>
                )} />

              <Field label='Anmerkungen' invalid={!!errors.comments} errorText={errors.comments?.message}>
                <Textarea
                  placeholder='Kommentar hinzufügen...'
                  {...register("comments")}
                />
              </Field>
            </VStack>
          </DialogBody>


          <DialogFooter>
            <Group gap={2}>
              <CancelButton onClick={onClose}/>
              <SubmitButton isSubmitting={isSubmitting} />
            </Group>
          </DialogFooter>
        </form>
      </DialogContent>
    </DialogRoot>
  );
};

export default TimesheetModal;
