import { useNavigate } from "react-router-dom";
import {
    Box,
    Flex,
    HStack,
    Link,
    IconButton,
    useDisclosure,
    Stack,
    Icon,
    Image,
    Text,
    useBreakpointValue,
    Badge,
    Float,
    Circle,
    MenuItemCommand,
} from '@chakra-ui/react';
import { BiExit } from 'react-icons/bi';
import Logo from './Logo'
import { useAuthContext } from "src/auth/AuthContext";
import WebUser from "src/auth/WebUser";
import apiClient from "src/service/core/ApiClient";
import { IconType } from "react-icons";
import { FcBarChart, FcCalendar, FcClock, FcComboChart, FcDoughnutChart, FcFlowChart, FcInfo, FcInspection, FcLandscape, FcList, FcPieChart, FcVoicePresentation } from "react-icons/fc";
import { OrgUtil } from "src/data/OrgUtil";
import { PathUtil } from "src/data/PathUtil";
import { AdminInhabitant } from "src/api/Admin";
import { userService } from "src/service/UserService";
import { User } from "src/api/User";
import { Role } from "src/api/Role";
import { MemberInhabitant } from "src/api/Member";
import { useColorModeValue, ColorModeButton } from "@/components/ui/color-mode"
import { Avatar } from "@/components/ui/avatar"
import { Button } from "@/components/ui/button";
import {
    MenuContent,
    MenuItem,
    MenuRoot,
    MenuSeparator,
    MenuTrigger,
} from "@/components/ui/menu"
import { ChevronDownIcon, CloseIcon, MenuIcon } from "./icons/Icon";
import {
    DrawerBackdrop,
    DrawerBody,
    DrawerCloseTrigger,
    DrawerContent,
    DrawerHeader,
    DrawerRoot,
} from "@/components/ui/drawer"
import {
    AccordionItem,
    AccordionItemContent,
    AccordionItemTrigger,
    AccordionRoot,
} from "@/components/ui/accordion"
import { LuUser } from "react-icons/lu";

// defines a menu as a tree structure, shown as multilevel navbar for desktop and mobile screens
interface MenuNode {
    name: string,
    link?: string,
    nodes?: Array<MenuNode>,
    icon?: IconType,
    expectedRole?: Role,
}
const menuNodes: Array<MenuNode> = [
    { name: 'Startseite', link: '/' },
    {
        name: 'Fundraising', nodes: [
            { name: 'Fundraising Übersicht', link: '/fundraising/', icon: FcComboChart },
            { name: 'Fundraising Pro Projekt', link: '/fundraising/projects/', icon: FcBarChart },
            { name: 'Zusagen', link: '/fundraising/commitments/' },
            { name: 'Eigenmittel Übertrag Partner', link: '/transfers/moves/' },
        ]
    },
    {
        name: 'Projektverwaltung', nodes: [
            { name: 'Projekte Übersicht', link: '/projects/overview/', icon: FcDoughnutChart },
            { name: 'Ansicht Pro Projekt', link: '/projects/overview/projects/', icon: FcBarChart },
            { name: 'Projekte', link: '/projects/' },
            { name: 'Partner', link: '/partners/' },
            { name: 'Konsulenten', link: '/partners/consultants/' },
            { name: 'Ausgaben', link: '/expenses/' },
        ]
    },
    // { name: 'Donors', link: '/donations/donors/' },
    {
        name: 'Finanzverwaltung', nodes: [
            { name: 'Ansicht Projektfonds', link: '/funds/provisions/', icon: FcPieChart },
            { name: 'Ansicht Zuweisung Fonds', link: '/funds/moves/overview/', icon: FcList },
            { name: 'Kostenstellen', link: '/donations/codes' },
            { name: 'Fonds', link: '/funds/' },
            { name: 'Zuweisung Fonds', link: '/funds/moves/' },
            { name: 'Transfers', link: '/transfers/' },
        ]
    },
    {
        name: 'Administration', expectedRole: MemberInhabitant, nodes: [
            { name: 'Mitarbeitende', link: '/admin/users/', icon: FcFlowChart, expectedRole: AdminInhabitant },
            { name: '', expectedRole: AdminInhabitant }, // stands for separator
            { name: 'Zeiterfassung', link: '/timesheet/', icon: FcClock },
            { name: 'Abwesenheitskalender - Demo', link: '/timesheet/absences', icon: FcCalendar },
            { name: 'Feiertage', link: '/timesheet/holidays', icon: FcLandscape },
        ]
    },
]


const NavButton = ({ active, children, ...props }) => (
    <HStack
        fontWeight='semibold'
        rounded={'md'}
        background={active ? 'blue.500' : 'gray.200'}
        color={active ? 'white' : 'black'}
        shadow={active ? 'lg' : 'none'}
        px={2}
        py={2}
        cursor={'pointer'}
        minW={'fit-content'}
        borderWidth='1px'
        borderColor='gray.300'
        _hover={{ background: active ? 'blue.700' : 'gray.400' }}
        {...props}
    >{children}{<ChevronDownIcon />}</HStack>)

const NavLink = ({ label, link, active }) => {
    return (
        <Link
            fontWeight='semibold'
            rounded={'md'}
            background={active ? 'blue.500' : 'gray.200'}
            color={active ? 'white' : 'black'}
            shadow={active ? 'lg' : 'none'}
            px={2}
            py={2}
            minW={'fit-content'}
            borderWidth='1px'
            borderColor='gray.300'
            _hover={{
                textDecoration: 'none',
                bg: active ? 'blue.700' : 'gray.400',
            }}
            href={link}>
            {label}
        </Link>
    )
};

const logoContent = (user: User | null) => {
    if (OrgUtil.isDemo(user)) {
        return (
            <Box w='165px'>
                <Image
                    src='/images/org/demo/fr-simple.webp'
                    alt='Fundraising'
                    pos='absolute'
                    width='220px'
                    transform='translate(-25px, -35px)'
                />
            </Box>
        );
    }
    if (OrgUtil.isAlgorithmWatch(user)) {
        return <Text>Algorithm Watch</Text>;
    }
    return <Logo boxSize={36} />;
};

const Header = () => {
    const { open: isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate();
    const { user, logout } = useAuthContext();

    const avatarSize: 'sm' | 'md' = useBreakpointValue({ base: 'sm', md: 'md' });
    const webUser = user as WebUser | null;
    const avatarName = `${webUser?.data?.firstName} ${webUser?.data?.lastName}`;
    const avatarColor = useColorModeValue('white', 'gray.800');
    const isTest = apiClient.baseUrl.includes('localhost');
    const bgColor = useColorModeValue('gray.100', 'gray.900');

    const handleLogout = () => {
        logout();
        console.info('logged out...');
        navigate('/');
    }

    interface NavMenuItemProps {
        name: string;
        link: string;
        icon?: IconType;
    }
    const NavMenuItem = ({ name, link, icon }: NavMenuItemProps) => {
        return (
            <MenuItem
                key={`${name}`}
                rounded={'md'}
                {...(path === PathUtil.dropSlashesAndId(link ?? '') && { background: 'blue.700', color: 'white' })}
                value={link}
                onClick={e => navigate(link)}
            >
                {icon && <Icon boxSize={'6'} as={icon} />}{name}
            </MenuItem>
        );
    }

    const ProfileMenu = ({ user }: { user: WebUser }) => {
        return (
            <MenuRoot>
                <MenuTrigger asChild>
                    <Flex>
                        <Flex hideBelow='md'>
                            <NavButton active={userMenuActive}><Text minW={{ base: '', md: '2rem' }} textAlign='left'>{user?.data?.firstName}</Text></NavButton>
                        </Flex>
                        <Stack hideFrom='md'
                            rounded={'md'}
                            background='blue.600'//{userMenuActive ? 'blue.500' : 'gray.200'}
                            color={userMenuActive ? 'black' : 'white'}
                            px={3}
                            py={3}
                            minW={'fit-content'}
                            borderWidth='1px'
                            borderColor='gray.300'
                            _hover={{
                                textDecoration: 'none',
                                bg: userMenuActive ? 'blue.700' : 'gray.400',
                            }}
                        >
                            <LuUser />
                        </Stack>
                    </Flex>
                </MenuTrigger>
                <MenuContent minW='14rem'>
                    <NavMenuItem name='Profil' link='/profile' icon={FcVoicePresentation} />
                    <NavMenuItem name='Info' link='/info/' icon={FcInfo} />
                    <NavMenuItem name='Entwicklung' link='/dev/' icon={FcInspection} />
                    <MenuSeparator key='profile_sep' />
                    <MenuItem value='x' key='x' onClick={() => handleLogout()}><Icon boxSize={'6'} as={BiExit} /> Abmelden<MenuItemCommand>⌘O</MenuItemCommand></MenuItem>
                </MenuContent>
            </MenuRoot>
        )
    }

    // without leading and trailing slash - to check if the menu is active
    const path = PathUtil.dropSlashesAndId(window.location.pathname);
    const userMenuActive = ['profile', 'info', 'dev'].includes(path);

    return (
        <Box bg={bgColor} px={4} boxShadow={'lg'} mb={5}>
            <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
                {/* hamburger menu only below md */}
                {user && (<IconButton
                    size={'md'}
                    colorPalette='blue'
                    aria-label={'Menü öffnen'}
                    display={{ md: 'none' }}
                    onClick={isOpen ? onClose : onOpen}
                >{isOpen ? <CloseIcon /> : <MenuIcon />}</IconButton>)}
                <HStack gap={8} alignItems={'center'}>
                    <Link href='/' aria-label='Fundraising'>
                        {logoContent(webUser?.data)}
                    </Link>
                    {isTest && (<Badge colorScheme='red' variant='outline'>Test</Badge>)}
                    {/* navbar menu, only from MD size */}
                    {user && (
                        <HStack
                            gap={1}
                            display={{ base: 'none', md: 'flex' }}>
                            {menuNodes.filter(item => OrgUtil.isAllowed(user?.data?.role, item.expectedRole)).map((item, ix) => {
                                // is link active?
                                const active = PathUtil.dropSlashesAndId(item.link ?? 'x') === path || item.nodes?.map(n => PathUtil.dropSlashesAndId(n.link ?? 'x')).includes(path);
                                //console.log(`active=${active}, path=${path}`);
                                return (
                                    item.link ?
                                        <NavLink key={item.name} label={item.name} link={item.link} active={active} />
                                        :
                                        <MenuRoot key={item.name}>
                                            <MenuTrigger asChild>
                                                <NavButton active={active}>{item.name} </NavButton>
                                            </MenuTrigger>
                                            <MenuContent>
                                                {item.nodes?.filter(subitem => OrgUtil.isAllowed(user?.data?.role, subitem.expectedRole)).map((subitem, ix) => subitem.name ? (
                                                    <NavMenuItem name={subitem.name} link={subitem.link} icon={subitem.icon} />) : (<MenuSeparator key={`menu_sep_${ix}`} />)
                                                )}
                                            </MenuContent>
                                        </MenuRoot>
                                )
                            }
                            )}
                        </HStack>
                    )}
                </HStack>
                <Flex alignItems={'center'}>
                    {/* profile menu */}
                    {user ? (
                        <>
                            <ProfileMenu user={user} />
                            {/* hide bwlow MD */}
                            <Avatar
                                size={avatarSize}
                                hideBelow='md'
                                color={avatarColor}
                                bg={isTest ? 'red.500' : 'blue.500'}
                                name={avatarName}
                                borderWidth='1px'
                                ml='.5rem'
                                shape='full'
                                src={userService.avatarUrl(webUser)}
                            >
                                <Float placement="bottom-end" offsetX="1" offsetY="1">
                                    <Circle
                                        bg="green.500"
                                        size="8px"
                                        outline="0.2em solid"
                                        outlineColor="bg"
                                    />
                                </Float>
                            </Avatar>
                        </>
                    ) : (
                        <Button colorPalette='blue' size="sm" rounded="md" onClick={() => navigate('/login/')}>Anmelden</Button>
                    )}
                    {/* color mode switcher shown from MD only */}
                    <ColorModeButton hideBelow='md' />
                </Flex>
            </Flex>

            {/* hamburger menu */}
            <DrawerRoot placement='start' open={isOpen} onOpenChange={onClose}>
                <DrawerBackdrop />
                <DrawerContent>
                    <DrawerCloseTrigger />
                    <DrawerHeader bg={bgColor} borderBottomWidth={'1px'} display={'flex'} alignItems={'center'}>
                        &nbsp;
                        <Flex pos="absolute">
                            <Logo boxSize={36} />
                        </Flex>
                    </DrawerHeader>

                    <DrawerBody p={0}>
                        <AccordionRoot collapsible>
                            {menuNodes.filter(item => OrgUtil.isAllowed(user?.data?.role, item.expectedRole)).map((item) => {
                                // is link active?
                                const active = PathUtil.dropSlashesAndId(item.link ?? 'x') === path || item.nodes?.map(n => PathUtil.dropSlashesAndId(n.link ?? '')).includes(path);
                                return (
                                    item.link ?
                                        <Stack key={`ms-${item.name}`} as={'nav'} gap={4} my='0.2rem'>
                                            <NavLink key={`ml-${item.name}`} label={item.name} link={item.link} active={PathUtil.dropSlashesAndId(item.link) === path} />
                                        </Stack>
                                        :
                                        <AccordionItem key={`m-${item.name}`} value={item.name}>
                                            <AccordionItemTrigger {...(active && { background: 'blue.700', color: 'white' })}>
                                                <Box as="span" flex='1' textAlign='left'>
                                                    {item.name}
                                                </Box>
                                            </AccordionItemTrigger>
                                            <AccordionItemContent pb={4} ml='1rem'>
                                                {item.nodes?.filter(subitem => OrgUtil.isAllowed(user?.data?.role, subitem.expectedRole)).map((subitem) => {
                                                    const active = PathUtil.dropSlashesAndId(subitem.link) === path
                                                    return (
                                                        <HStack key={`msa-${subitem.name}`} as={'nav'} gap={4} my='0.2rem' rounded='md' {...(active && { background: 'blue.500', color: 'white' })}>
                                                            <NavLink key={`mla-${subitem.name}`} label={subitem.name} link={subitem.link} active={active} />
                                                            {subitem.icon && <Icon boxSize={'5'} as={subitem.icon} />}
                                                        </HStack>
                                                    )
                                                })}
                                            </AccordionItemContent>
                                        </AccordionItem>
                                )
                            })}
                        </AccordionRoot>
                    </DrawerBody>
                </DrawerContent>
            </DrawerRoot>
        </Box>
    );
}

export default Header