import { Card, Container, Flex, GridItem, Heading, Presence, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { FcCloseUpMode, FcCollaboration, FcBullish } from "react-icons/fc";
import { IconType } from 'react-icons';


interface FeatureProps {
    title: string;
    text: string;
    delay: number;
    icon: IconType;
    color: string;
}


const features: FeatureProps[] = [
    {
        title: 'Projekte',
        text: `Einfacher Überblick`,
        delay: 1,
        icon: FcCloseUpMode,
        color: '#EFC94C'
    },
    {
        title: 'Analyse',
        text: `Weniger Verwaltung`,
        delay: 3,
        icon: FcBullish,
        color: '#D55342'
    },
    {
        title: 'Zeiterfassung',
        text: `Alles auf einen Blick`,
        delay: 5,
        icon: FcCollaboration,
        color: '#00BAA3'
    },
];

const FeatureCard = (props: FeatureProps) => {
    return (
        <Presence
            present={true}
            insetY='20px'
            animationName={{
                _open: "slide-from-bottom, fade-in",
                _closed: "slide-to-bottom, fade-out",
            }}
            animationDuration={`${props.delay}s`}
        >
            <Card.Root bg={props.color} shadow='lg' color='white' border='0' borderColor='gray.200'>
                <Card.Header>
                    <Flex
                        p={2}
                        w="max-content"
                        rounded="md"
                        marginInline="auto"
                        pos="absolute"
                        left={0}
                        right={0}
                        top="-2.5rem"
                    >
                        <props.icon size='4em' />
                    </Flex>
                    <Heading size='md'>{props.title}</Heading>
                </Card.Header>
                <Text textAlign='center'>{props.text}</Text>
                <Card.Body>
                    <Stack alignItems={{ base: 'flex-end' }}>
                        <Text fontSize='xs' textAlign='right'>und mehr...</Text>
                    </Stack>
                </Card.Body>
            </Card.Root>
        </Presence>
    );
}

const Features = () => {
    return (
        <Container maxW={{ base: '2xl', sm: '3xl', md: '4xl', lg: '6xl' }} mt='4rem'>
            <SimpleGrid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={{ base: 6, md: 10 }}>
                {features.map((cardProps, index) => (
                    <GridItem key={index}>
                        <FeatureCard {...cardProps} />
                    </GridItem>
                ))}
            </SimpleGrid>
        </Container>
    )
}

export default Features