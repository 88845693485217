import { Image, Box, Heading, Stack, Flex, HStack } from "@chakra-ui/react";
import { FcVlc } from "react-icons/fc";
import { useColorModeValue } from "@/components/ui/color-mode";

const Construction = ({withImage=true}: {withImage?:boolean}) => {
    const baseH = withImage ? '60vh' : '20vh';
    return (
        <Flex
            minH={baseH}
            align='center'
            justify='center'>
            <Box
                rounded={'lg'}
                bg={useColorModeValue('gray.100', 'gray.700')}
                boxShadow={'lg'}
                p={5}>

                <Stack direction={'column'} align={'center'} py={5} gap={5}>
                    <Image boxSize='100%' objectFit='cover' src='/images/c-stripe.png' alt='Under Construction' />
                    <HStack>
                        <Heading>Seite im Aufbau</Heading>
                        <FcVlc size='4em' />
                    </HStack>
                    {withImage && <Image maxW={'100%'} src='/images/aufbau.webp' alt='Under Construction' />}
                </Stack>
            </Box>
        </Flex>
    );
}

export default Construction