import {
  Flex,
  Box,
  Input,
  Image,
  Stack,
  Heading,
  Text,
  Link,
  Fieldset,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from 'src/auth/AuthContext';
import { authService } from 'src/service/AuthService';
import { userService } from 'src/service/UserService';
import WebUser from 'src/auth/WebUser';
import { OrgUtil } from 'src/data/OrgUtil';
import { Button } from "@/components/ui/button"
import { InputGroup } from "@/components/ui/input-group"
import { Field } from "@/components/ui/field"
import { Avatar } from '@/components/ui/avatar';
import { Switch } from '@/components/ui/switch';
import { PasswordInput } from "@/components/ui/password-input";
import { EmailIcon, LockIcon } from '@/components/icons/Icon';
import Footer from '@/components/Footer';

interface LoginEntry {
  email: string;
  password: string;
  rememberMe: boolean
}


const Login = () => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginEntry>({
    defaultValues: {
      email: '',
      password: '',
      rememberMe: true
    }
  });

  const { login, logout } = useAuthContext();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();


  const onSubmitLogin: SubmitHandler<LoginEntry> = (entry) => {
    console.info('logging in...');
    setIsLoading(true);
    setError('');
    authService.login(entry.email, entry.password, entry.rememberMe).then(r => userService.getMe(r.token).then(me => {
      let webUser: WebUser = { token: r.token, data: me };
      login(webUser);
      setIsLoading(false);
      console.info(`${webUser.data.firstName} succesfully logged in...`);
      // go to the landing page
      navigate('/');
    })).catch(error => {
      console.error(error.message)
      // in case of errors, logout
      logout();
      if (error.status === 401) {
        setError('Falsche Anmeldedaten');
      } else {
        setError('Verbindungsproblem');
      }
    }).finally(() =>
      setIsLoading(false)
    );
  };

  return (
    <>
      <Stack as={Box} direction={{ base: 'column', md: 'row' }}
        align='center' // valign
        justify={{ base: 'left', lg: 'center' }}
        py={{ base: '5vh', lg: '0vh' }}
      >
        <form noValidate onSubmit={handleSubmit(onSubmitLogin)}>
          <Fieldset.Root size='md' maxW='md' invalid={error && error.length > 0} rounded={'lg'}
            boxShadow={'lg'}
            py='3rem'
            px='2rem'
          >
            <Stack align={'center'}>
              <Avatar bg="blue.400" />
              <Heading fontSize={'4xl'} textAlign={'center'}>Anmeldung</Heading>
              <Text fontSize={'lg'} color={'gray.600'}>Willkommen zurück 🎉</Text>
            </Stack>

            <Fieldset.Content>
              <Field id='email' label='E-Mail' required invalid={!!errors.email} errorText={errors.email?.message}>
                <InputGroup startElement={<EmailIcon color='gray.400' />} w='full'>
                  <Input autoFocus={true} type="email" {...register("email", { required: 'E-Mail ist erforderlich' })} />
                </InputGroup>
              </Field>
              <Field id='password' label='Passwort' required invalid={!!errors.password} errorText={errors.password?.message}>
                <InputGroup startElement={<LockIcon color='gray.400' />} w='full'>
                  <PasswordInput {...register("password", { required: 'Passwort ist erforderlich' })} />
                </InputGroup>
              </Field>
              <Stack gap='3rem'>
                <Field id='rememberMe'>
                  <Stack
                    direction={{ base: 'column', sm: 'row' }}
                    align={'start'}
                    justify={'space-between'}
                    gap='20px'>
                    <Controller
                      control={control}
                      name='rememberMe'
                      render={({ field: { onChange, value, ref } }) => (
                        <Switch colorPalette='blue' checked={value} onCheckedChange={e => onChange(e.checked)} ref={ref}>Angemeldet bleiben</Switch>
                      )}
                    />
                    <Text textStyle='sm'>
                      <Link color='blue.600' onClick={_ => navigate('/forgot')}>Passwort vergessen?</Link>
                    </Text>
                  </Stack>
                </Field>
                <Button type='submit' loading={isLoading} loadingText='Anmeldung...' size="lg" bg={'blue.400'} color={'white'} _hover={{ bg: 'blue.700' }}>
                  Anmelden
                </Button>
              </Stack>
            </Fieldset.Content>
            <Fieldset.ErrorText>
              {error}
            </Fieldset.ErrorText>

          </Fieldset.Root>
        </form>

        <Box display="flex" hideBelow='md'>
          <Flex minH={{ base: '50vh', lg: '70vh' }}>
            <Image
              maxW={{ base: '4xl' }}
              alt={'Login image'}
              objectFit={'scale-down'}
              src={OrgUtil.isDemo(null) ? '/images/org/demo/plant.webp' : '/images/org/cooperaid/learn.webp'}
              px={5}
            />
          </Flex>
        </Box>

      </Stack >

      <Box hideBelow='md'><Footer loggedIn={false} /></Box>
    </>
  );
}

export default Login