import { FcCalendar, FcCheckmark, FcDocument } from 'react-icons/fc';
import { OverallBudget } from 'src/api/OverallBudget';
import { AmountUtil } from 'src/data/AmountUtil';
import {
    StepsItem,
    StepsList,
    StepsRoot,
} from "@/components/ui/steps"

const HorizontalTimeline = ({ events }: { events: OverallBudget[] }) => {
    const steps = events.filter(e => e.year > 2019);
    const currentYear = new Date().getFullYear();
    const currentIndex = steps.findIndex(e => e.year === currentYear);


    return (
        <StepsRoot defaultStep={currentIndex} count={steps.length} colorPalette='green'>
            <StepsList>
                {steps.map((step, index) => (
                    <StepsItem index={index} 
                        title={step.year}
                        key={`timeline-${index}`}
                        description={`${AmountUtil.format(step.budget.value)} ${step.budget.currency}`}
                        icon={index < currentIndex ? <FcCheckmark /> : index > currentIndex ? <FcDocument /> : <FcCalendar />}
                    >
                    </StepsItem>
                ))}
            </StepsList>
        </StepsRoot>
    );
};

export default HorizontalTimeline;