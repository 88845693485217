// Generated by ScalaTS 0.7.0: https://scala-ts.github.io/scala-ts/

declare var exports: any;

export const nsMember = exports;

export const MemberInhabitant = 'Member';

export type Member = typeof MemberInhabitant;

export function isMember(v: any): v is Member {
  return MemberInhabitant == v;
}

export type MemberSingleton = Member;
