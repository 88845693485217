import { IoMdClose } from "react-icons/io";
import { LuMenu, LuChevronDown, LuClipboardPen, LuTrash2, LuLockKeyhole, LuLockKeyholeOpen, LuInfo, LuMail, LuEye, LuEyeOff, LuEllipsisVertical, LuFilePlus, LuClock, LuArrowLeft, LuArrowRight } from "react-icons/lu";

export const CloseIcon = () => <IoMdClose/>

export const MenuIcon = () => <LuMenu />

export const ChevronDownIcon = () => <LuChevronDown />;

export const EditIcon = () => <LuClipboardPen />

export const DeleteIcon = ({...props}) => <LuTrash2 {...props}/>

export const LockIcon = ({...props}) => <LuLockKeyhole {...props}/>

export const UnlockIcon = ({...props}) => <LuLockKeyholeOpen {...props}/>

export const InfoIcon = ({...props}) => <LuInfo {...props}/>

export const EmailIcon = ({...props}) => <LuMail {...props}/>

export const ViewIcon = ({...props}) => <LuEye {...props}/>

export const ViewOffIcon = ({...props}) => <LuEyeOff {...props}/>

export const EllipsisIcon = () => <LuEllipsisVertical />

export const AddIcon = () => <LuFilePlus />

export const TimeIcon = () => <LuClock />

export const ArrowBackIcon = () => <LuArrowLeft />

export const ArrowForwardIcon = () => <LuArrowRight />