import { ReactElement } from "react";
import { Button } from "@/components/ui/button"


export interface SubmitButtonProps {
    caption?: string;
    isSubmitting?: boolean;
}

const SubmitButton = (props: SubmitButtonProps): ReactElement => {
    return (<Button
        bg={'blue.400'}
        color={'white'}
        _hover={{ bg: 'blue.700' }}
        type='submit'
        loading={props.isSubmitting}
        loadingText='Einreichung...'
    >{props.caption ?? 'Speichern'}</Button>)
}


export default SubmitButton