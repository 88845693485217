import {
    AspectRatio,
    Box,
    Container,
    Heading,
    Image,
    Input,
    Stack,
    Text
} from "@chakra-ui/react";
import { useState } from "react";
import apiClient from "src/service/core/ApiClient";
import ErrorMessage from "./ErrorMessage";
import SuccessMessage from "./SuccessMessage";
import { ProgressBar, ProgressRoot } from "@/components/ui/progress"


export const FileSelect = ({ path, onUpload, ...props }: { path: string, onUpload: () => Promise<void> }) => {

    const handleUpload = (file2Upload: File): Promise<void> => {
        console.info(`uploading ${file2Upload.name}`);
        setIsLoading(true);
        setIsSuccess(false);
        let formData = new FormData();
        formData.append('file', file2Upload);
        let res = apiClient.upload(path, formData);
        return res
            .catch(apiClient.handleError)
            .then(_res => {
                setIsLoading(false);
                setIsSuccess(true);
                return onUpload();
            })
            .catch(err => {
                setIsLoading(false);
                setError(err.message);
                return;
            });
    }

    const [error, setError] = useState<string>('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Container mx='0px' px='0px' {...props}>
            <AspectRatio width="40" ratio={1}>
                <Box
                    borderColor="gray.300"
                    borderStyle="dashed"
                    borderWidth="2px"
                    rounded="lg"
                    shadow="md"
                    role="group"
                    transition="all 150ms ease-in-out"
                    _hover={{
                        shadow: "lg",
                        bg: 'gray.100'
                    }}
                >
                    <Box position="relative" height="100%" width="100%">
                        <Box
                            position="absolute"
                            top="0"
                            left="0"
                            height="100%"
                            width="100%"
                            display="flex"
                            flexDirection="column"
                        >
                            <Stack
                                height="100%"
                                width="100%"
                                display="flex"
                                alignItems="center"
                                justify="center"
                                gap="2"
                            >
                                <Box height="12" width="9" position="relative">
                                    <Image src='/images/csv.png'
                                        bg="white"
                                        top="0"
                                        height="100%"
                                        width="100%"
                                        rotate='-15deg'
                                        translate='-20px'
                                        // animation="bounce" 
                                        animationTimingFunction="ease-in-out"
                                        position="absolute"
                                        boxShadow='lg'
                                        rounded="sm"
                                        borderColor="gray.400"
                                        backgroundSize="cover"
                                        backgroundRepeat="no-repeat"
                                        backgroundPosition="center" />
                                    <Image src='/images/file.png'
                                        bg="white"
                                        top="0"
                                        height="100%"
                                        width="100%"
                                        rotate='15deg'
                                        translate='20px'
                                        // animation="bounce" 
                                        animationTimingFunction="ease-in-out"
                                        position="absolute"
                                        boxShadow='lg'
                                        rounded="sm"
                                        borderColor="gray.400"
                                        backgroundSize="cover"
                                        backgroundRepeat="no-repeat"
                                        backgroundPosition="center" />
                                    <Image src='/images/excel2.png'
                                        bg="white"
                                        top="0"
                                        height="100%"
                                        width="100%"
                                        //rotate='15deg'
                                        //translate='-20px'
                                        // animation="bounce" 
                                        animationTimingFunction="ease-in-out"
                                        position="absolute"
                                        boxShadow='lg'
                                        rounded="sm"
                                        borderColor="gray.400"
                                        backgroundSize="cover"
                                        backgroundRepeat="no-repeat"
                                        backgroundPosition="center" />
                                    {/* <PreviewImage
                                        variants={second}
                                        backgroundImage="/images/file.png"
                                    />
                                    <PreviewImage
                                        variants={third}
                                        backgroundImage="/images/excel2.png"
                                    /> */}
                                </Box>
                                <Stack p="0" textAlign="center" gap="1">
                                    <Heading fontSize="lg" color="gray.700" fontWeight="bold">
                                        Datei ablegen
                                    </Heading>
                                    <Text hidden={isSuccess || error !== ''} fontSize='xs'>oder zum Hochladen klicken</Text>

                                    {isLoading && <ProgressRoot colorScheme='green' size='md' value={null}><ProgressBar /></ProgressRoot>}
                                    {error && <ErrorMessage message={error} />}
                                    {isSuccess && <SuccessMessage message='Erfolgreich!' />}
                                </Stack>
                            </Stack>
                        </Box>
                        <Input
                            type='file'
                            cursor='pointer'
                            title='keine Datei ausgewählt'
                            height="100%"
                            width="100%"
                            position="absolute"
                            top="0"
                            left="0"
                            opacity="0"
                            aria-hidden="true"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                            multiple={false}
                            // onDragEnter={startAnimation}
                            // onDragLeave={stopAnimation}
                            onChange={ev => {
                                setError('');
                                setIsSuccess(false);
                                if (ev.target.files.length > 0) {
                                    handleUpload(ev.target.files[0]);
                                }
                            }}
                        />
                    </Box>
                </Box>
            </AspectRatio>
        </Container>
    );
}

export const FileDownload = ({ path, name, ...props }: { path: string, name: string }) => {

    const [isLoading, setIsLoading] = useState(false);

    const handleDownload = async (path: string) => {
        console.log(`downloading from ${path}`);
        setIsLoading(true);
        const blob = await apiClient.download(path).finally(() => setIsLoading(false));
        // create a download link for the Blob
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        // set the download attribute and trigger the download
        a.setAttribute('download', name);
        document.body.appendChild(a);
        console.log('saving...');
        a.click();
        console.log('cleaning up...');
        // cleanup the temporary URL
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }

    return (
        <Container mx='0px' px='0px' {...props}>
            <AspectRatio width="40" ratio={1}>
                <Box
                    borderColor="gray.300"
                    borderStyle="dashed"
                    borderWidth="2px"
                    rounded="lg"
                    shadow="md"
                    role="group"
                    transition="all 150ms ease-in-out"
                    _hover={{
                        shadow: "lg",
                        bg: 'gray.100'
                    }}
                >
                    <Box position="relative" height="100%" width="100%">
                        <Box
                            position="absolute"
                            top="0"
                            left="0"
                            height="100%"
                            width="100%"
                            display="flex"
                            flexDirection="column"
                        >
                            <Stack
                                height="100%"
                                width="100%"
                                display="flex"
                                alignItems="center"
                                justify="center"
                                gap="4"
                            >
                                <Box height="12" width="9" position="relative">
                                    <Image src='/images/excel2.png'
                                        bg="white"
                                        top="0"
                                        height="100%"
                                        width="100%"
                                        // animation="bounce" 
                                        animationTimingFunction="ease-in-out"
                                        position="absolute"
                                        boxShadow='lg'
                                        rounded="sm"
                                        borderColor="gray.400"
                                        backgroundSize="cover"
                                        backgroundRepeat="no-repeat"
                                        backgroundPosition="center" />
                                </Box>
                                <Stack p="0" textAlign="center" gap="1">
                                    <Heading fontSize="lg" color="gray.700" fontWeight="bold">
                                        Daten herunterladen
                                    </Heading>
                                    {isLoading && <ProgressRoot colorPalette='green' size='md' value={null}><ProgressBar /></ProgressRoot>}
                                </Stack>
                            </Stack>
                        </Box>
                        <Box
                            cursor='pointer'
                            title='zum Herunterladen klicken'
                            height="100%"
                            width="100%"
                            position="absolute"
                            top="0"
                            left="0"
                            opacity="0"
                            aria-hidden="true"
                            onClick={ev => handleDownload(path)}
                        />
                    </Box>
                </Box>
            </AspectRatio>
        </Container>
    )
}