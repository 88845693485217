import { Skeleton, Stack, useDisclosure, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable, { dateCell } from 'src/components/DataTable';
import { ColumnDef, CellContext } from '@tanstack/react-table';
import Content from 'src/components/Content';
import { userService } from "src/service/UserService";
import IdAndNameData from 'src/data/IdAndNameData';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { EntryUtil } from 'src/data/EntryUtil';
import { User } from 'src/api/User';
import { Role } from 'src/api/Role';
import RoleBadge from 'src/components/RoleBadge';
import { UserAudit } from 'src/api/UserAudit';
import { parseIso } from 'src/data/DateTimeUtil';
import { Status } from 'src/api/Status';
import StatusBadge from 'src/components/StatusBadge';
import AddButton from 'src/components/buttons/AddButton';
import WaitingSpinner from '@/components/WaitingSpinner';

const OverviewUsers = () => {
    const [data, setData] = useState<User[]>([]);
    const [audit, setAudit] = useState<Map<number, UserAudit>>(new Map()); // user id to audit structure
    const [deleteData, setDeleteData] = useState<IdAndNameData<number> | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const { open: isOpen, onOpen, onClose } = useDisclosure(); // for delete dialog

    const navigate = useNavigate();

    const onDelete = useCallback((id: number, name: string): Promise<void> => {
        console.info(`deleting ${id} ${name}`);
        return userService.remove(id)
            .then(() => setDeleteData(null))
            .then(() => window.location.reload());
    }, []);

    const onEdit = useCallback((id: number): void => {
        console.info(`editing ${id}`);
        navigate(`/admin/users/edit/${id}`);
    }, [navigate]);

    const onView = useCallback((id: number): void => {
        console.info(`viewing ${id}`);
        navigate(`/admin/users/view/${id}`);
    }, [navigate]);

    const loadAllData = (): Promise<void> => {
        setIsLoading(true);
        return userService.listAll().then(r => {
            setData(r.results.concat());
            // update the date fieldf, otherwise formatting doesn't work with Date type
            setAudit(new Map(r.audit.map(a => [a.userId, { ...a, ...{ accessedAt: parseIso(a.accessedAt) } }])));
        })
            .finally(() => setIsLoading(false));
    }

    const onUploadFinished = useCallback((): Promise<void> => {
        console.info(`upload expenses suceeded...`);
        return loadAllData();
    }, []);

    useEffect(() => {
        loadAllData();
    }, [onUploadFinished]);

    const columns: ColumnDef<User>[] = useMemo(
        () => [
            {
                id: 'createDate',
                header: 'Erstellt',
                accessorFn: (row: User) => row.createdAt,
                cell: dateCell()
            },
            {
                id: 'name',
                header: 'Name',
                accessorFn: (row: User) => `${row.firstName} ${row.lastName}`,
            },
            {
                id: 'email',
                header: 'E-mail',
                accessorKey: 'email',
            },
            {
                id: 'role',
                header: 'Rolle',
                accessorKey: 'role',
                cell: (info: CellContext<User, Role>) => {
                    let role = info.getValue();
                    return <RoleBadge role={role} />;
                }
            },
            {
                id: 'status',
                header: 'Status',
                accessorKey: 'status',
                cell: (info: CellContext<User, Status>) => {
                    let status = info.getValue();
                    return <StatusBadge status={status} />;
                }
            },
            {
                id: 'access',
                header: 'Letzer Zugriff',
                accessorKey: 'id',
                cell: (info: CellContext<User, number>) => {
                    let id = info.getValue();
                    return <Text>{audit.get(id)?.accessedAt.toLocaleString() ?? ''}</Text>
                }
            },
        ], [onEdit, onOpen, onView, audit]
    );

    return (
        <Content caption='Alle Benutzer'>
            <Stack align={'baseline'} direction={{ base: 'column', sm: 'row' }} justify={'space-between'}>
                <AddButton caption='Neuer Benutzer' onClick={() => navigate('/admin/users/add')} />
            </Stack>
            {isLoading ? (
                <Stack py={5}>
                    <WaitingSpinner size='xl' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                </Stack>
            ) : (
                <>
                    <Text fontSize='small'>{EntryUtil.text(data.length)}</Text>
                    <DataTable columns={columns} data={data}
                        initialSortByColumn='createDate' initialSortByDesc={true} hideColumnsOnXs={['createDate', 'email']}
                    //onRowClick={row => onEdit(row.id)}
                    />
                    <ConfirmDialog
                        heading={`${deleteData?.name} Löschen`}
                        isOpen={isOpen}
                        onClose={onClose}
                        onConfirm={async (): Promise<void> => onDelete(deleteData.id, deleteData.name)}
                    />
                </>
            )}
        </Content>
    );
}

export default OverviewUsers