
import { Spinner } from "@chakra-ui/react"

interface WaitingSpinnerProps {
    size: 'lg' | 'xl' | 'md' | 'sm' | 'xs'
    mb?: string
}

const WaitingSpinner = (props: WaitingSpinnerProps) => {
    return (<Spinner borderWidth='4px' animationDuration='0.65s' color='blue.500' size={props.size} mb={props.mb ?? ''} />)
}

export default WaitingSpinner