// Generated by ScalaTS 0.7.0: https://scala-ts.github.io/scala-ts/

declare var exports: any;

export const nsActive = exports;

export const ActiveInhabitant = 'Active';

export type Active = typeof ActiveInhabitant;

export function isActive(v: any): v is Active {
  return ActiveInhabitant == v;
}

export type ActiveSingleton = Active;
