import { useAuthContext } from 'src/auth/AuthContext';
import Hero from '../components/Hero'
import { Separator, Image, Skeleton, Stack, Text, Heading, Flex, VStack } from '@chakra-ui/react';
import Features from 'src/components/Features';
import Footer from 'src/components/Footer';
import Content from 'src/components/Content';
import ProjectTimeline from 'src/components/ProjectTimeline';
import { projectService } from 'src/service/ProjectService';
import { useEffect, useState } from 'react';
import { ProjectsActiveListResponse } from 'src/api/ProjectsActiveListResponse';
import { isWinterHoliday } from 'src/data/DateTimeUtil';
import HorizontalTimeline from 'src/components/HorizontalTimeline';
import ExchangeRates from 'src/components/ExchangeRates';
import WaitingSpinner from '@/components/WaitingSpinner';

// logged in landing page
const Dashboard = ({ firstName }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [projects, setProjects] = useState<ProjectsActiveListResponse | null>(null);
    const [timelineHeight, setTimelineHeight] = useState(700);

    useEffect(() => {
        setIsLoading(true);
        projectService
            .listActives()
            .then(r => {
                setProjects(r);
                // set the height of the project timeline based on the amount of active projects
                if (r.results.length <= 6) {
                    setTimelineHeight(300);
                } else {
                    setTimelineHeight(700);
                }
            })
            .finally(() => setIsLoading(false));

    }, []);

    return (
        <Content>
            {isWinterHoliday(new Date()) && <Image boxSize='100%' objectFit='cover' src='/images/holidays/new-year.webp' alt='Christmas' position='relative' top='-39px' />}
            <Text fontSize={{ base: '1xl', lg: `2xl` }}>Schön dich zu sehen, <b>{firstName}</b> 👋</Text>
            {isLoading ? (
                <Stack py={5}>
                    <WaitingSpinner size='xl' />
                    {Array(15).fill(0).map((_, ix) => (
                        <Skeleton key={`sk-${ix}`} height='2rem' />
                    ))}
                </Stack>
            ) : (
                <>
                    <ProjectTimeline
                        projects={projects?.results.concat() ?? []}
                        countries={projects?.countries.concat() ?? []}
                        latestEndDate={projects?.endLatestDate}
                        height={timelineHeight} />
                    <Flex hideBelow='md'>
                        <HorizontalTimeline events={projects?.budgets.concat() ?? []} />
                    </Flex>
                </>
            )}
            <Separator mt='4rem' mb='1rem' />
            <VStack hideBelow='md'>
                <Heading mb='1rem' textAlign='center'>Indikative Wechselkurse</Heading>
                <ExchangeRates base={"CHF"} all={["USD", "BDT", "KES"]} />
            </VStack>
            <Footer loggedIn={true} />
        </Content>
    )
}

// logged out landing page
const LoggedOut = () => {
    return (
        <>
            {isWinterHoliday(new Date()) && <Image boxSize='100%' objectFit='cover' src='/images/holidays/new-year.webp' alt='Christmas' position='relative' top='-18px' />}
            <Hero />
            <Features />
            <Footer loggedIn={false} />
        </>
    );
}

const Landing = () => {
    const { user } = useAuthContext();

    let comp = user ?
        <Dashboard firstName={user.data.firstName} />
        :
        <LoggedOut />;
    return comp;
}

export default Landing