import { ArrowBackIcon, ArrowForwardIcon, TimeIcon } from '@/components/icons/Icon';
import { Box, IconButton, Text } from "@chakra-ui/react";
import { addMonths, format, Locale, subMonths } from "date-fns";
import { Tooltip } from "@/components/ui/tooltip";

interface MonthNavigationProps {
    currentMonth: Date;
    setCurrentMonth: (date: Date) => void;
    language: Locale;
}

const MonthNavigation = ({ currentMonth, setCurrentMonth, language }: MonthNavigationProps) => {
    const dateFormat = "MMMM yyyy";
    return (
        <Box display="flex" alignItems="left" mb={4}>
            <IconButton
                borderRadius='full'
                variant='solid'
                colorPalette='blue'
                aria-label='Previous'
                fontSize='20px'
                onClick={() => setCurrentMonth(subMonths(currentMonth, 1))}
            ><ArrowBackIcon /></IconButton>
            <Text width='12rem' fontSize="2xl" fontWeight={'bold'} textAlign={'center'} mx={2}>{format(currentMonth, dateFormat, { locale: language })}</Text>
            <IconButton
                borderRadius='full'
                variant='solid'
                colorPalette='blue'
                aria-label='Next'
                fontSize='20px'
                onClick={() => setCurrentMonth(addMonths(currentMonth, 1))}
            ><ArrowForwardIcon /></IconButton>
            <Tooltip content='Heute'>
                <IconButton
                    borderRadius='full'
                    variant='solid'
                    colorPalette='blue'
                    aria-label='Heute'
                    fontSize='20px'
                    ml='5'
                    onClick={() => setCurrentMonth(new Date())}
                ><TimeIcon /></IconButton>
            </Tooltip>
        </Box>
    );
};

export default MonthNavigation;