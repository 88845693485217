import { Flex, Text } from "@chakra-ui/react";
import {
    OptionBase, chakraComponents
} from "chakra-react-select";
import { Control, Controller } from "react-hook-form";
import { countries, supported } from "src/data/Countries";
import CountryFlag from "../CountryFlag";
import { Field } from "@/components/ui/field";
import {
    Select as ReactSelect
  } from "chakra-react-select";


export interface CountryOption extends OptionBase {
    label: string;
    value: string;
    icon: React.ReactElement
}

const options = Object.keys(countries)
    .filter((k) => supported.has(k))
    .map((k) => {
        let co: CountryOption = {
            label: countries[k],
            value: k,
            icon: <CountryFlag code={k} />
        }
        return co;
    });


const customItem = (item: CountryOption) => (
    <Flex align="center">
        {item.icon}
        <Text ml="10px">{item.label}</Text>
    </Flex>
);

const customOption = (props) => (
    <chakraComponents.Option label={props.data.name} {...props}>
        {customItem(props.data)}
    </chakraComponents.Option>
);

const CountrySelect = ({ control, name, readOnly, ...props }: { control: Control<any, any>, name: string, readOnly: boolean, [key: string]: any }) => {
    return (
        <Controller control={control} name={name}
            rules={{ required: "Land auswählen", validate: (value, formValues) => value.length > 0 }}
            render={({ field: { value, name, onChange, onBlur, ref }, fieldState: { error } }) => (
                <Field id='country' label='Land' required invalid={!!error} errorText={error?.message} disabled={readOnly}>
                    <ReactSelect
                        isSearchable
                        required
                        name={name}
                        onBlur={onBlur}
                        onChange={val => onChange(val.value)}
                        value={options.find(c => c.value === value)}
                        placeholder="Land auswählen"
                        options={options}
                        components={{ Option: customOption }}
                        // show the land flag for the selected item
                        formatOptionLabel={(option: CountryOption) => customItem(option)}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        {...props}
                    />
                </Field>
            )} />
    );
}

export default CountrySelect