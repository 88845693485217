import { ReactElement } from "react";
import {
    MenuContent,
    MenuItem,
    MenuRoot,
    MenuTrigger,
} from "@/components/ui/menu"
import { DeleteIcon, EditIcon, EllipsisIcon, ViewIcon } from "@/components/icons/Icon";
import { Box } from "@chakra-ui/react";


export interface DropDownProps {
    onMenuView?: () => Promise<void> | void;
    onMenuEdit?: () => Promise<void> | void;
    onMenuDelete?: () => Promise<void> | void;
}

export const DropDownMenu = ({ onMenuView, onMenuEdit, onMenuDelete }: DropDownProps): ReactElement => {
    return (
        <MenuRoot lazyMount={true}>
            <MenuTrigger onClick={e => e.stopPropagation()}>
                <Box boxSize='1.5rem' p='0px'
                    bg="gray.300"
                    color="blue.500"
                    cursor="pointer"
                    rounded='sm'
                    shadow='sm'
                    borderWidth='1px'
                    borderColor='gray.400'
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    _hover={{
                        bg: 'gray.400'
                    }}
                >
                    <EllipsisIcon />
                </Box>
            </MenuTrigger>
            <MenuContent>
                {onMenuView && (<MenuItem value='view' onClick={(e) => {
                    e.stopPropagation();
                    onMenuView()

                }}><ViewIcon /> Ansicht</MenuItem>)}
                {onMenuEdit && (<MenuItem value='edit' onClick={(e) => {
                    e.stopPropagation();
                    onMenuEdit()
                }}><EditIcon /> Bearbeiten</MenuItem>)}
                {onMenuDelete && (<MenuItem value='delete' onClick={(e) => {
                    e.stopPropagation();
                    onMenuDelete()
                }}><DeleteIcon color="red.500" /> Löschen</MenuItem>)}
            </MenuContent>
        </MenuRoot>
    )
}