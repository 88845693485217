import { Skeleton, Stack, useDisclosure, Box } from '@chakra-ui/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DataTable, { amountCell, amountHeader, dateCell } from 'src/components/DataTable';
import { ColumnDef, CellContext, Row } from '@tanstack/react-table';
import Content from 'src/components/Content';
import { expenseService } from "src/service/ExpenseService";
import IdAndNameData from 'src/data/IdAndNameData';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { Expense } from 'src/api/Expense';
import ReachLink from 'src/components/ReachLink';
import { FileSelect } from 'src/components/FileSelect';
import AddButton from 'src/components/buttons/AddButton';
import WaitingSpinner from '@/components/WaitingSpinner';
import { DropDownMenu } from '@/components/DropDownMenu';

const OverviewExpenses = () => {
    const [data, setData] = useState<Expense[]>([]);
    const [projects, setProjects] = useState<Map<number, string>>(new Map()); // id to project name
    const [partners, setPartners] = useState<Map<number, string>>(new Map()); // id to partner name
    const [deleteData, setDeleteData] = useState<IdAndNameData<number> | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const { open: isOpen, onOpen, onClose } = useDisclosure(); // for delete dialog

    const navigate = useNavigate();

    const onDelete = useCallback((id: number, name: string): Promise<void> => {
        console.info(`deleting ${id} ${name}`);
        return expenseService.remove(id)
            .then(() => setDeleteData(null))
            .then(() => window.location.reload());
    }, []);

    const onEdit = useCallback((id: number): void => {
        console.info(`editing ${id}`);
        navigate(`/expenses/edit/${id}`);
    }, [navigate]);

    const onView = useCallback((id: number): void => {
        console.info(`viewing ${id}`);
        navigate(`/expenses/view/${id}`);
    }, [navigate]);

    const loadAllData = (): Promise<void> => {
        setIsLoading(true);
        return expenseService.listAll().then(r => {
            setData(r.results.concat());
            setProjects(new Map(r.projects.map(p => [p.id, p.name])));
            setPartners(new Map(r.partners.map(p => [p.id, p.name])));
        })
            .finally(() => setIsLoading(false));
    }

    const onUploadFinished = useCallback((): Promise<void> => {
        console.info(`upload expenses suceeded...`);
        return loadAllData();
    }, []);

    useEffect(() => {
        loadAllData();
    }, [onUploadFinished]);

    const columns: ColumnDef<Expense>[] = useMemo(
        () => [
            {
                id: 'entryDate',
                header: 'Datum',
                accessorFn: (row: Expense) => row.entryDate,
                cell: dateCell()
            },
            {
                id: 'partner',
                header: 'Partner',
                accessorFn: (row: Expense) => row.partnerId,
                cell: (info: CellContext<Expense, number>) => {
                    let pid = info.getValue();
                    let partnerName = partners.get(pid);
                    return (<ReachLink to={`/partners/view/${pid}`} name={partnerName} tooltip='zu den Partnerdetails' />);
                },
                sortingFn: (rowA: Row<Expense>, rowB: Row<Expense>, _): number => {
                    const a = rowA.original;
                    const b = rowB.original;
                    let ap = partners.get(a.partnerId);
                    let bp = partners.get(b.partnerId);
                    return ap.trim().localeCompare(bp.trim());
                },
            },
            {
                id: 'project',
                header: 'Projekt',
                accessorFn: (row: Expense) => row.projectId,
                cell: (info: CellContext<Expense, number>) => {
                    let pid = info.getValue();
                    let projectName = projects.get(pid);
                    return (<ReachLink to={`/projects/view/${pid}`} name={projectName} tooltip='zu den Projektdetails' />);
                },
                sortingFn: (rowA: Row<Expense>, rowB: Row<Expense>, _): number => {
                    const a = rowA.original;
                    const b = rowB.original;
                    let ap = projects.get(a.projectId) ?? '';
                    let bp = projects.get(b.projectId) ?? '';
                    return ap.trim().localeCompare(bp.trim());
                },
            },
            {
                id: 'amount',
                header: amountHeader({ name: 'Summe' }),
                accessorKey: 'amount',
                cell: amountCell({}),
            },
            {
                id: 'currency',
                header: 'Währung',
                accessorKey: 'currency'
            },
            {
                id: 'comments',
                header: () => (<div style={{ width: '20rem' }}>Kommentar</div>),
                accessorKey: 'comments',
                enableSorting: false
            },
            {
                id: 'edit',
                enableSorting: false,
                cell: info => {
                    let row = info.row.original;
                    return (<DropDownMenu onMenuView={() => onView(row.id)} onMenuEdit={() => onEdit(row.id)} onMenuDelete={() => {
                        let da: IdAndNameData<number> = {
                            id: row.id,
                            name: `${projects.get(row.projectId)} / ${partners.get(row.partnerId)}`
                        };
                        setDeleteData(da);
                        onOpen();
                    }} />)
                }
            }
        ], [projects, partners, onEdit, onOpen, onView]
    );

    return (
        <Content caption='Ausgaben'>
            <Stack align={'baseline'} direction={{ base: 'column', sm: 'row' }} justify={'space-between'}>
                <AddButton caption='Neue Ausgabe' onClick={() => navigate('/expenses/add')} />
                <Box>
                    <FileSelect path='/api/expenses/upload' onUpload={onUploadFinished} />
                </Box>
            </Stack>
            {isLoading ? (
                <Stack py={5}>
                    <WaitingSpinner size='xl' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                </Stack>
            ) : (
                <>
                    <DataTable columns={columns} data={data}
                        initialSortByColumn='entryDate' initialSortByDesc={true} hideColumnsOnXs={['entryDate', 'comments']}
                        onRowClick={row => onEdit(row.id)}
                    />
                    <ConfirmDialog
                        heading={`${deleteData?.name} Löschen`}
                        isOpen={isOpen}
                        onClose={onClose}
                        onConfirm={async (): Promise<void> => onDelete(deleteData.id, deleteData.name)}
                    />
                </>
            )}
        </Content>
    );
}

export default OverviewExpenses