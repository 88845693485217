import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { de } from 'date-fns/locale/de';
import { Controller } from "react-hook-form";
import { BiCalendar } from "react-icons/bi";
import { forwardRef } from "react";
import { parseIso } from "src/data/DateTimeUtil";
import { InputGroup } from "@/components/ui/input-group"
import { Input } from "@chakra-ui/react";
import { Field } from "@/components/ui/field";

const customInput = ({ value, onClick, onChange, onFocus, onBlur, ...props }: any, ref: any) => (
    <InputGroup startElement={<BiCalendar color='blue' />}>
        <Input
            autoComplete="off"
            value={value}
            ref={ref}
            onBlur={onBlur}
            onClick={onClick}
            onChange={onChange}
            onFocus={onFocus} // fixes typing in date from the keyboard // won't disapear for yyyy if is there
            type='text'
            {...props}
        />
    </InputGroup>
);
customInput.displayName = "DateSelect";

export const CustomDateInput = forwardRef(customInput);

const parseSafeIso = (v: Date): Date => {
    try {
        return parseIso(v);
    } catch {
        return undefined;
    }
}

export const formatDate = (v: Date | null): Date => v ? parseSafeIso(v) : undefined;

const DateSelectController = ({ control, label, name, clearable, ...props }: { control, label: string, name: string, clearable: boolean, [key: string]: any }) => {
    const mandatory = props['required'] ?? false;
    return (<Controller control={control} name={name} 
        rules={{ required: mandatory && `${label} auswählen`, validate: (value) => !mandatory || value !== null }}
        render={({ field: { value, name, onChange }, fieldState: { error, invalid } }) => (
        <Field id={name} label={label} required={mandatory} disabled={props['disabled'] ?? false} invalid={invalid} errorText={error?.message}>
            <DatePicker
                isClearable={clearable}
                selected={formatDate(value)}
                onChange={(date: Date | null) => onChange(date)}
                dateFormat='dd.MM.yyyy'
                locale={de}
                showPopperArrow={true}
                customInput={<CustomDateInput />}
                {...props}
            />
        </Field>
    )} />)
}

export default DateSelectController

// readonly component to show a date entry
export const DateSelectInfo = ({ date }: { date: Date }) => {
    return <DatePicker
        isClearable={false}
        selected={formatDate(date)}
        dateFormat='dd.MM.yyyy'
        locale={de}
        showPopperArrow={true}
        customInput={<CustomDateInput />}
        readOnly={true}
        disabled={true}
    />
}