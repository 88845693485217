import { Role } from "src/api/Role";
import { AdminInhabitant } from "src/api/Admin";
import { MemberInhabitant } from "src/api/Member";
import { RootInhabitant } from "src/api/Root";
import { User } from "src/api/User";
import { isDemo } from "src/api/Demo";
import { isAlgorithmWatch } from "src/api/AlgorithmWatch";

// Utility functions to decide the organization and related media.
export const OrgUtil = {

    // returns true if the subdomain is demo or the request has a query parameter for org=demo
    isDemo(user: User | null): boolean {
        return isDemo(user?.organizationId) ||
            window.location.hostname.includes('demo') || 
            window.location.search.includes('org=demo');
    },
    isAlgorithmWatch(user: User | null): boolean {
        return isAlgorithmWatch(user?.organizationId);
    },
    orgName(user: User | null): string {
        if (OrgUtil.isDemo(user)) return 'Demo';
        if (OrgUtil.isAlgorithmWatch(user)) return 'Algorithm Watch';
        return 'Co-operaid';
    },

    isRoleMember(role: Role): boolean {
        return role === MemberInhabitant || role === 'member';
    },
    isRoleAdmin(role: Role): boolean {
        return role === AdminInhabitant || role === 'admin';
    },
    isRoleRoot(role: Role): boolean {
        return role === RootInhabitant || role === 'root';
    },

    isAllowed(currentRole: Role, expectedRole?: Role): boolean {
        //console.log(`current: ${currentRole}`);
        if (!expectedRole) return true; // Handles undefined case first

        if (OrgUtil.isRoleRoot(expectedRole)) return OrgUtil.isRoleRoot(currentRole);
        if (OrgUtil.isRoleAdmin(expectedRole)) return OrgUtil.isRoleAdmin(currentRole) || OrgUtil.isRoleRoot(currentRole);
        if (OrgUtil.isRoleMember(expectedRole)) return true; // Member role is accessible to all

        return false;
    },
}
