import { LockIcon } from "@/components/icons/Icon";
import { Flex, Stack, Heading, Box, Input, Group, Fieldset } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { BiEnvelope, BiUser } from "react-icons/bi";
import PasswordStrengthBar from "react-password-strength-bar";
import { useNavigate } from "react-router-dom";
import CancelButton from "src/components/buttons/CancelButton";
import ErrorMessage from "src/components/ErrorMessage";
import SubmitButton from "@/components/buttons/SubmitButton";
import { userService } from "src/service/UserService";
import { InputGroup } from "@/components/ui/input-group"
import { Field } from "@/components/ui/field";
import { PasswordInput } from "@/components/ui/password-input";

interface NewUser {
    email: string;
    password: string;
    password2: string,
    firstName: string;
    lastName: string;
}

const UsersAdd = () => {
    const { register, handleSubmit, formState: { errors, isSubmitting }, watch } = useForm<NewUser>({
        defaultValues: {
            email: null,
            password: '',
            password2: '',
            firstName: null,
            lastName: null,
        }
    });
    const newPassword = watch('password', '');
    const newPassword2 = watch('password2', '');
    const [serverError, setServerError] = useState('');

    const readOnly = false;
    const navigate = useNavigate();
    const onSubmitInternal = useCallback(
        (p: NewUser): Promise<void> => {
            return userService.add(p.email, p.password, p.firstName, p.lastName).then(() =>
                navigate('/admin/users/')).catch(err => setServerError('Fehler'));
        }, [navigate]
    );

    return (
        <Flex
            minH={{ base: '50vh', lg: '70vh' }}
            align={'center'}
            justify={'center'}
        >
            <Fieldset.Root size='md' maxW='md' invalid={serverError && serverError.length > 0} rounded={'lg'}
                boxShadow={'lg'}
                py='3rem'
                px='2rem'
            >
                <form noValidate onSubmit={handleSubmit(onSubmitInternal)}>
                    <Stack align={'center'}>
                        <Heading fontSize={'4xl'} textAlign={'center'}>Neuer Benutzer</Heading>
                    </Stack>
                    <Stack gap={4}>
                        {serverError && <ErrorMessage message={serverError} />}

                        <Field id="email" label="E-Mail" required readOnly={readOnly} invalid={!!errors['email']} errorText={errors.email?.message}>
                            <InputGroup w='full' startElement={<BiEnvelope color='blue' />}>
                                <Input autoFocus={true} type="text" {...register('email', {
                                    required: 'E-Mail muss angegeben werden',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'Ungültige E-Mail-Adresse',
                                    },
                                })} />
                            </InputGroup>
                        </Field>

                        <Box>
                            <Field id="password" label='Passwort' required invalid={!!errors.password} errorText={errors.email?.message}>
                                <InputGroup w='full' startElement={<LockIcon color='orange' />}>
                                    <PasswordInput {...register('password', { required: 'Passwort ist erforderlich' })} />
                                </InputGroup>
                            </Field>
                            <PasswordStrengthBar password={newPassword} shortScoreWord='zu kurz' scoreWords={['schwach', 'schwach', 'okay', 'gut', 'stark']} />

                            <Field id="confirmPassword" label='Passwort wiederholen' required invalid={newPassword !== newPassword2} errorText={errors.password2?.message}>
                                <InputGroup w='full' startElement={<LockIcon color='orange' />}>
                                    <PasswordInput {...register('password2', {
                                        validate: (value) => value === newPassword || 'Passwörter stimmen nicht überein'
                                    })} />
                                </InputGroup>
                            </Field>
                        </Box>

                        <Box>
                            <Field id="firstName" label='Vorname' required readOnly={readOnly} invalid={!!errors['firstName']} errorText={errors.firstName?.message}>
                                <InputGroup w='full' startElement={<BiUser color='blue' />}>
                                    <Input type="text" {...register('firstName', { required: 'Vorname ist erforderlich' })} />
                                </InputGroup>
                            </Field>
                        </Box>

                        <Box>
                            <Field id="lastName" label='Nachname' required readOnly={readOnly} invalid={!!errors['lastName']} errorText={errors.lastName?.message}>
                                <InputGroup w='full' startElement={<BiUser color='blue' />}>
                                    <Input type="text" {...register('lastName', { required: 'Nachname ist erforderlich' })} />
                                </InputGroup>
                            </Field>
                        </Box>

                        {/* submittion */}
                        <Flex justifyContent='flex-end'>
                            <Group gap={2}>
                                <CancelButton onClick={() => navigate('/admin/users/')} />
                                {!readOnly && (<SubmitButton isSubmitting={isSubmitting} />)}
                            </Group>
                        </Flex>
                    </Stack>
                </form>
            </Fieldset.Root>
        </Flex>
    )
}

export default UsersAdd