import { Badge, Box, HStack } from "@chakra-ui/react";
import { MenuContent, MenuItem, MenuRoot, MenuTrigger } from "./ui/menu";
import { Tooltip } from "./ui/tooltip";
import { ChevronDownIcon } from "./icons/Icon";
import ReachLink from "./ReachLink";
import { useNavigate } from "react-router-dom";


interface PartnerLinkProps {
    partnerIds: number[];
    partners: Map<number, string>; // id to name
    keyPrefix?: string
}

const PartnerLink = ({ partnerIds, partners, keyPrefix }: PartnerLinkProps) => {
    const navigate = useNavigate();
    
    let count = partnerIds.length;
    if (count === 0) {
        return;
    }
    if (count === 1) {
        const pid = partnerIds[0];
        const partnerName = partners.get(pid);
        return (<ReachLink to={`/partners/view/${pid}`} name={partnerName} tooltip='zu den Partnerdetails' />);
    }
    // has more than one, provide a badge and dropdown menu
    return (
        <MenuRoot lazyMount={true}>
            <MenuTrigger onClick={e => e.stopPropagation()}>
                <Box
                    bg="gray.100"
                    color="blue.500"
                    cursor="pointer"
                    rounded='sm'
                    shadow='sm'
                    borderWidth='1px'
                    borderColor='gray.400'
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    _hover={{
                        bg: 'gray.400'
                    }}
                >
                    <Tooltip content='zu den Partnerdetails'>
                        <HStack>
                            <Badge variant='plain' colorPalette='current'>{count} Partners</Badge>
                            <ChevronDownIcon />
                        </HStack>
                    </Tooltip>
                </Box>
            </MenuTrigger>
            <MenuContent>
                {partnerIds.map(pid => {
                    let partnerName = partners.get(pid);
                    return (<MenuItem
                        key={`${keyPrefix ?? 'partner'}-${pid}`}
                        value={`/partners/view/${pid}`}
                        onClick={e => {
                            e.stopPropagation();
                            navigate(`/partners/view/${pid}`);
                        }}>{partnerName}</MenuItem>);
                })}
            </MenuContent>
        </MenuRoot>
    )
}

export default PartnerLink