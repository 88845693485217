import { Text, useDisclosure, Skeleton, Stack, Link } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import IdAndNameData from 'src/data/IdAndNameData';
import ConfirmDialog from 'src/components/ConfirmDialog';
import DataTable from 'src/components/DataTable';
import { DonationCode } from 'src/api/DonationCode';
import { donationsCodeService } from 'src/service/DonationCodeService';
import Content from 'src/components/Content';
import DonationCodeDialog from './DonationCodeDialog';
import AddButton from 'src/components/buttons/AddButton';
import WaitingSpinner from '@/components/WaitingSpinner';
import { useColorModeValue } from '@/components/ui/color-mode';
import { DropDownMenu } from '@/components/DropDownMenu';

const OverviewDonationCodes = () => {
    const [data, setData] = useState<DonationCode[]>([]);
    const [types, setTypes] = useState<Map<number, string>>(new Map());
    // when entering a new entry
    const emptyDonationCode: DonationCode = { group: '', code: '', name: '', donationTypeId: -1 };
    const [editData, setEditData] = useState<DonationCode>(emptyDonationCode);
    const [deleteData, setDeleteData] = useState<IdAndNameData<string> | null>(null);

    const [isLoading, setIsLoading] = useState(false);
    const { open: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();
    const { open: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();

    useEffect(() => {
        setIsLoading(true);
        donationsCodeService
            .listAll()
            .then(r => {
                const typeId2Name = new Map(r.types.map(obj => [obj.id, obj.name]));
                setTypes(typeId2Name);
                setData(r.results.concat());
            })
            .then(() => setIsLoading(false));
    }, []);

    const colorLinkHover = useColorModeValue('gray.900', 'gray.200');

    const columns: ColumnDef<DonationCode>[] = useMemo(
        () => [
            {
                id: 'group',
                header: 'Gruppe',
                accessorFn: (row: DonationCode) => row.group.trim(),
                cell: (info: CellContext<DonationCode, string>) => (<Text fontWeight={'bold'}>{info.getValue()}</Text>),
            },
            {
                id: 'code',
                header: 'Kostenstelle',
                accessorKey: 'code',
                enableGrouping: false,
                cell: (table): JSX.Element => {
                    const rowData: DonationCode = table.row.original;
                    return (
                        <Text fontWeight='extrabold' color='blue.600' _hover={{ color: colorLinkHover }}>
                            <Link onClick={() => {
                                setEditData(rowData);
                                onEditOpen();
                            }}>
                                {rowData?.code}
                            </Link>
                        </Text>
                    );
                }
            },
            {
                id: 'type',
                header: 'Projektmittel',
                accessorFn: (row: DonationCode) => types.get(row.donationTypeId) ?? 'n/a',
                enableGrouping: false,
            },
            {
                id: 'name',
                header: 'Bezeichnung',
                accessorKey: 'name',
                enableGrouping: false,
                enableSorting: false,
            },
            {
                id: 'edit',
                enableSorting: false,
                enableGrouping: false,
                cell: (info) => {
                    let row = info.row.original;
                    return (
                        <DropDownMenu onMenuEdit={() => {
                            setEditData(row);
                            onEditOpen();
                        }} onMenuDelete={() => {
                            let da: IdAndNameData<string> = {
                                id: row.code,
                                name: row.name
                            };
                            setDeleteData(da);
                            onDeleteOpen();
                        }} />
                    )
                }
            }
        ], [colorLinkHover, types, onDeleteOpen, onEditOpen]
    );

    return (
        <Content caption='Kostenstelle'>
            <AddButton caption='Neue Kostenstelle' onClick={() => {
                setEditData(emptyDonationCode);
                onEditOpen()
            }} />
            {isLoading ? (
                <Stack py={5}>
                    <WaitingSpinner size='xl' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                </Stack>
            ) : (
                <>
                    <DataTable columns={columns} data={data} initialSortByColumn='group' initialSortByDesc={false}
                        initialGroupByColumns={['group']} hideColumnsOnXs={['name', 'type']}
                        onRowClick={row => {
                            setEditData(row);
                            onEditOpen();
                        }} />
                    <ConfirmDialog
                        heading={`${deleteData?.id} Löschen`}
                        isOpen={isDeleteOpen}
                        onClose={onDeleteClose}
                        onConfirm={async (): Promise<void> => {
                            donationsCodeService.remove(deleteData.id).then(() => setData(prev => prev.filter((a) => a.code !== deleteData.id)));
                        }}
                    />
                    <DonationCodeDialog
                        initialData={editData}
                        isOpen={isEditOpen}
                        onClose={onEditClose}
                        onConfirm={async (dc: DonationCode): Promise<void> =>
                            (editData?.code ? donationsCodeService.update(dc) : donationsCodeService.add(dc))
                                .then(() => setData(prev => [].concat(prev.filter((a) => a.code !== dc.code), dc)))
                        }
                        existingGroups={new Set(data.map(d => d.group))}
                        existingTypes={types}
                    />
                </>
            )}
        </Content>
    );
}

export default OverviewDonationCodes