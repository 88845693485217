import { Box, Separator, Flex, Heading, HStack, Icon, Text } from "@chakra-ui/react";
import { ReactElement } from "react";
import { TiWarningOutline } from "react-icons/ti";
import CancelButton from "./buttons/CancelButton";
import { Button } from "@/components/ui/button"
import { DialogRoot, DialogBody, DialogContent, DialogHeader, DialogCloseTrigger, DialogFooter } from "@/components/ui/dialog"


export interface ConfirmDialogProps {
    heading: string;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => Promise<void>;
}

export const ConfirmDialog = ({ heading, isOpen, onClose, onConfirm }: ConfirmDialogProps): ReactElement => {
    return (
        <DialogRoot lazyMount={true} open={isOpen} onOpenChange={() => onClose()}
            placement='center' size='sm' scrollBehavior='outside'>
            <DialogContent>
                <DialogHeader borderTopWidth='5px' borderTopColor='red' />
                <DialogCloseTrigger boxSize='1rem'/>
                <DialogBody>
                    <Flex flexDirection='row'>
                        {/* <FcHighPriority size='4em'/> */}
                        <Icon as={TiWarningOutline} color={'orange'} boxSize='4em' />
                        <Box w='100%'>
                            <Heading as='h1' w='100%' color='red' textAlign='center' size='md' mb={4}>
                                {heading}
                            </Heading>
                            <Text textAlign='center' fontWeight='bold'>Willst du wirklich fortfahren?</Text>
                        </Box>
                    </Flex>
                    <Separator colorScheme='orange' mt='15px' />
                </DialogBody>
                <DialogFooter>
                    <HStack gap={2} alignItems='flex-end'>
                        <CancelButton onClick={onClose} caption='Nein' flex={1} />
                        <Button bgColor='red' color='white' _hover={{ bg: 'red.700' }}
                            onClick={() => onConfirm().then(() => onClose())} flex={1}>
                            Ja
                        </Button>
                    </HStack>
                </DialogFooter>
            </DialogContent>
        </DialogRoot>
    );
};

export default ConfirmDialog