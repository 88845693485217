import { HStack, Input, Text, Group, Fieldset } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FcList } from "react-icons/fc";
import {
    OptionBase,
    CreatableSelect,
    Select as SingleSelect
} from "chakra-react-select";
import ErrorMessage from "src/components/ErrorMessage";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { countries, supported } from "src/data/Countries";
import { DonationCode } from "src/api/DonationCode";
import CancelButton from "src/components/buttons/CancelButton";
import SubmitButton from "@/components/buttons/SubmitButton";
import { Field } from "@/components/ui/field"
import { DialogRoot, DialogContent, DialogHeader, DialogBody, DialogCloseTrigger, DialogFooter } from "@/components/ui/dialog";


export interface DonationCodeDialogProps {
    initialData: DonationCode | null;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (dc: DonationCode) => Promise<void>;
    existingGroups: Set<string>,
    existingTypes: Map<number, string>;
}

export interface CodeOption extends OptionBase {
    label: string;
    value: string;
}

export interface TypeOption extends OptionBase {
    label: string;
    value: number;
}

const DonationCodeDialog = ({ initialData, isOpen, onClose, onConfirm, existingGroups, existingTypes }: DonationCodeDialogProps) => {
    const { control, register, handleSubmit, reset, formState: { errors, isSubmitting } } = useForm<DonationCode>({
        defaultValues: {
            group: '', code: '', name: '', donationTypeId: -1
        }
    });
    const [serverError, setServerError] = useState('');

    // merge existing ones with supported countries
    const supportedCountries = new Set<string>(Object.keys(countries).filter((k) => supported.has(k)).map(c => countries[c]));
    const groupOptions = Array.from(new Set([...existingGroups, ...supportedCountries])).map((k) => {
        let co: CodeOption = {
            label: k,
            value: k,
        }
        return co;
    });
    const typeOptions = Array.from(existingTypes).map(([id, name]) => {
        let co: TypeOption = {
            label: name,
            value: id,
        }
        return co;
    });

    // update the state got from the props at every open
    useEffect(() => {
        console.info(`resetting form to ${JSON.stringify(initialData)}`)
        reset(initialData);
    }, [initialData, reset]);

    const onSubmitInternal: SubmitHandler<DonationCode> = (data) => {
        console.info(`submitting: ${JSON.stringify(data)}`);
        return onConfirm(data).then(() => onClose()).catch(err => setServerError('Fehler'));
    };

    return (
        <DialogRoot 
            open={isOpen} 
            onOpenChange={() => onClose()}
            placement='center'
            size='sm'
            motionPreset='scale'
            scrollBehavior='outside'>
            <DialogContent>
                <form noValidate onSubmit={handleSubmit(onSubmitInternal)}>
                    <DialogHeader>
                        <HStack><FcList size='1.5em' /><Text>Kostenstelle</Text></HStack>
                    </DialogHeader>
                    <DialogCloseTrigger />

                    <DialogBody>
                        <Fieldset.Root invalid={serverError && serverError.length > 0}>
                            {serverError && serverError.length > 0 && <ErrorMessage message={serverError} />}

                            <Controller control={control} name='group'
                                rules={{ required: "Gruppe auswählen", validate: (value) => value?.length > 0 || "Gruppe auswählen" }}
                                render={({ field: { value, name, onChange, onBlur, ref }, fieldState: { error, invalid } }) => (
                                    <Field id="group" label='Gruppe (oder neue Gruppe erstellen)' required invalid={!!errors.group} errorText={error?.message}>
                                        <CreatableSelect
                                            isSearchable
                                            required
                                            ref={ref}
                                            name={name}
                                            onBlur={onBlur}
                                            autoFocus={true}
                                            placeholder='Asien und Afrika'
                                            options={groupOptions}
                                            onChange={val => onChange(val.value)}
                                            value={groupOptions.find(c => c.value === value)}
                                            formatCreateLabel={(item) => `Neue Gruppe für ${item}`}
                                        />
                                    </Field>
                                )} / >

                            <Field label='Kostenstelle' required invalid={!!errors.code} errorText={errors.code?.message}>
                                <Input type='text' placeholder='1AF000PS' {...register('code', { required: "Kostenstelle auswählen" })} />
                            </Field>

                            <Controller control={control} name='donationTypeId'
                                rules={{ required: "Projektmittel typ auswählen", validate: (value) => value > -1 || "Projektmittel typ auswählen" }}
                                render={({ field: { value, name, onChange, onBlur, ref }, fieldState: { error } }) => (
                                    <Field id="donationTypeId" label='Projektmittel Typ' required invalid={!!errors.donationTypeId} errorText={error?.message}>
                                        <SingleSelect
                                            isSearchable
                                            required
                                            ref={ref}
                                            name={name}
                                            onBlur={onBlur}
                                            placeholder='Einzelspenden'
                                            options={typeOptions}
                                            onChange={val => onChange(val.value)}
                                            value={typeOptions.find(c => c.value === value)}
                                        />
                                    </Field>
                                )} />

                            <Field label='Bezeichnung' required invalid={!!errors.name} errorText={errors.name?.message}>
                                <Input placeholder='Bildung für Kinder in Afrika' {...register('name', { required: "Bezeichnung eingeben" } )} />
                            </Field>
                        </Fieldset.Root>
                    </DialogBody>

                    <DialogFooter>
                        {/* submittion */}
                        <Group gap={2}>
                            <CancelButton onClick={onClose} />
                            <SubmitButton isSubmitting={isSubmitting} />
                        </Group>
                    </DialogFooter>
                </form>
            </DialogContent>
        </DialogRoot>
    );
}

export default DonationCodeDialog