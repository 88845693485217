import { BiBuilding, BiEnvelope, BiUser, BiWallet } from 'react-icons/bi';
import { Fieldset, Flex, Group, Heading, Icon, Input, Stack } from "@chakra-ui/react"
import CurrencySelect from '@/components/fields/CurrencySelect';
import ErrorMessage from "src/components/ErrorMessage";
import CountrySelect from '@/components/fields/CountrySelect';
import { useForm } from "react-hook-form";
import { useCallback, useEffect, useState } from 'react';
import { Partner } from 'src/api/Partner';
import { partnerService } from 'src/service/PartnerService';
import { useNavigate } from 'react-router-dom';
import CancelButton from 'src/components/buttons/CancelButton';
import SubmitButton from '@/components/buttons/SubmitButton';
import { InputGroup } from '@/components/ui/input-group';
import { Field } from '@/components/ui/field';
import { IconType } from 'react-icons';

export interface PartnersFormProps {
    partnerId?: number;
    organizationId: number;
    onSubmit: (partner: Partner) => Promise<void>;
    viewOnly?: boolean;
    header: string;
    // set the flag when want to explicitly edit consultan or partner
    // if not set then will be configured at loading time in the effect hook
    // e.g. if partnerId is set, then this flag will be derived at loading time
    consultantOnly?: boolean;
}

const PartnersForm = ({ partnerId, organizationId, onSubmit, viewOnly, header, consultantOnly }: PartnersFormProps): JSX.Element => {
    const { control, register, handleSubmit, reset, formState: { errors, isSubmitting } } = useForm<Partner>({
        defaultValues: {
            id: partnerId,
            organizationId: organizationId,
            createdAt: new Date(),
            consultant: consultantOnly,
        }
    });
    const [serverError, setServerError] = useState('');
    const [consultant, setConsultant] = useState<boolean | null>(consultantOnly ?? null);
    const navigate = useNavigate();

    const onSubmitInternal = useCallback(
        (p: Partner): Promise<void> => {
            return onSubmit(p).then(() =>
                navigate(p.consultant ? '/partners/consultants' : '/partners')).catch(err => setServerError('Fehler'));
        }, [onSubmit, navigate]
    );

    useEffect(() => {
        if (partnerId > -1) {
            partnerService.find(partnerId).then(p => {
                setConsultant(p.consultant);
                console.log(`consultant=${p.consultant}`)
                reset(p)
            });
        }
    }, [partnerId, reset]);
    const readOnly = viewOnly ?? false;
    // console.info(`form is readonly: ${readOnly}`);

    const MandatoryInputField = ({ id, label, disabled, icon, autoFocus, placeholder }: { id: any, label: string, disabled: boolean, icon: IconType, autoFocus?: boolean, placeholder?: string }) => {
        return (
            <Field id={id} label={label} required disabled={disabled} invalid={!!errors[id]} errorText={errors[id]?.message}>
                <InputGroup w='full' startElement={<Icon as={icon} color='blue' />}>
                    <Input autoFocus={autoFocus ?? false} type="text" {...register(id, { required: `${label} ist erforderlich` })} placeholder={placeholder ?? ''} />
                </InputGroup>
            </Field>
        )
    }

    return (
        <Flex
            minH={{ base: '50vh', lg: '70vh' }}
            align={'center'}
            justify={'center'}
        >
            <form noValidate onSubmit={handleSubmit(onSubmitInternal)}>
                <Fieldset.Root size='lg' maxW='lg' rounded={'lg'}
                    boxShadow={'lg'}
                    py='3rem'
                    px='2rem'
                    invalid={serverError && serverError.length > 0}
                >
                    <Stack align={'center'}>
                        <Heading fontSize={'4xl'} textAlign={'center'}>{`${header}  ${consultant ? 'Konsulent' : 'Partner'}`}</Heading>
                    </Stack>

                    <Stack gap={4}>
                        {serverError && <ErrorMessage message={serverError} />}

                        <MandatoryInputField id="name" label='Name' disabled={readOnly} icon={BiUser} autoFocus={true} />

                        <CountrySelect control={control} name='country' readOnly={readOnly} />

                        <MandatoryInputField id="bankName" label='Bank' disabled={readOnly} icon={BiBuilding} placeholder='Bankverbindung' />

                        <Stack direction={{ base: 'column', sm: 'row' }}>
                            <MandatoryInputField id="accountName" label='Konto-Inhaber' disabled={readOnly} icon={BiUser} />

                            <CurrencySelect control={control} name='currency' readOnly={readOnly} />
                        </Stack>


                        <Stack direction={{ base: 'column', sm: 'row' }}>
                            <MandatoryInputField id="accountNumber" label='Konto Nr' disabled={readOnly} icon={BiUser} />

                            <MandatoryInputField id="swiftCode" label='SWIFT Code' disabled={readOnly} icon={BiWallet} />
                        </Stack>

                        <Field id="bankCode" label='Bank Code' disabled={readOnly}>
                            <Input type="text" {...register('bankCode')} />
                        </Field>

                        <MandatoryInputField id="email" label='E-Mail' disabled={readOnly} icon={BiEnvelope} />

                        {/* submittion */}
                        <Flex justifyContent='flex-end'>
                            <Group gap={2}>
                                <CancelButton onClick={() => navigate(consultant ? '/partners/consultants/' : '/partners/')} />
                                {!readOnly && (<SubmitButton isSubmitting={isSubmitting} />)}
                            </Group>
                        </Flex>
                    </Stack>
                </Fieldset.Root>
            </form>
        </Flex>
    );
}

export default PartnersForm