import { Fieldset, Flex, Group, Heading, Stack, Textarea } from "@chakra-ui/react"
import ErrorMessage from "src/components/ErrorMessage";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Commitment } from 'src/api/Commitment';
import DateSelectController from '@/components/fields/DateSelect';
import { BiWallet } from "react-icons/bi";
import {
    CreatableSelect,
    OptionBase,
    Select as ReactSelect
} from "chakra-react-select";
import { projectService } from 'src/service/ProjectService';
import { commitmentService } from "src/service/CommitmentService";
import CancelButton from "src/components/buttons/CancelButton";
import SubmitButton from "@/components/buttons/SubmitButton";
import { Field } from "@/components/ui/field";
import { InputGroup } from "@/components/ui/input-group";
import { CustomAmountInput } from "@/components/fields/AmountInput";

export interface CommitmentsFormProps {
    commitmentId?: number;
    organizationId: number;
    onSubmit: (ps: Commitment) => Promise<void>;
    viewOnly?: boolean;
    header: string;
}

export interface ProjectOption extends OptionBase {
    label: string;
    value: number;
}

export interface InstitutionOption extends OptionBase {
    label: string;
    value: string;
}

const CommitmentsForm = ({ commitmentId, organizationId, onSubmit, viewOnly, header }: CommitmentsFormProps): JSX.Element => {
    const { control, register, handleSubmit, reset, formState: { isSubmitting } } = useForm<Commitment>({
        defaultValues: {
            id: commitmentId,
            projectId: -1,
            fundSourceName: '',
            amount: 0,
            entryDate: new Date(),
            comments: '',
            organizationId: organizationId
        }
    });
    const [serverError, setServerError] = useState('');
    const [projectNames, setProjectNames] = useState<ProjectOption[]>([]);
    const [institutions, setInstitutions] = useState<InstitutionOption[]>([]); // unique names for institutes
    const navigate = useNavigate();

    useEffect(() => {
        if (commitmentId > -1) {
            commitmentService.find(commitmentId).then(p => reset(p));
        }
    }, [commitmentId, reset]);

    useEffect(() => {
        let a = projectService.listNames().then(ps => {
            let ops = ps.map(p => {
                let n: ProjectOption = {
                    label: p.name,
                    value: p.id
                }
                return n;
            });
            setProjectNames(ops);
        });
        let b = commitmentService.fundSourceNames().then(ns => {
            let ops = ns.map(n => {
                let o: InstitutionOption = {
                    label: n,
                    value: n
                }
                return o;
            });
            setInstitutions(ops);
        });
        Promise.all([a, b]);
    }, []);

    const readOnly = viewOnly ?? false;
    console.info(`form is readonly: ${readOnly}`);

    const onSubmitInternal = (c: Commitment): Promise<void> => {
        console.info(`submitting ${JSON.stringify(c)}`);
        return onSubmit(c)
            .then(() => navigate('/fundraising/commitments'))
            .catch(_err => setServerError('Fehler'));
    };

    return (
        <Flex
            minH={{ base: '50vh', lg: '70vh' }}
            align={'center'}
            justify={'center'}
        >
            <form noValidate onSubmit={handleSubmit(onSubmitInternal)}>
                <Fieldset.Root size='md' maxW='md' rounded={'lg'}
                    boxShadow={'lg'}
                    py='3rem'
                    px='2rem'
                    invalid={serverError && serverError.length > 0}
                >
                    <Stack align={'center'}>
                        <Heading fontSize={'4xl'} textAlign={'center'}>{header}</Heading>
                    </Stack>

                    <Stack gap={4}>
                        {serverError && <ErrorMessage message={serverError} />}

                        <Controller control={control} name='projectId'
                            rules={{ required: "Projekt auswählen", validate: (value) => value > -1 || "Projekt auswählen" }}
                            render={({ field: { value, name, onChange, onBlur, ref }, fieldState: { error, invalid } }) => (
                                <Field id="projectId" label='Projekt' required disabled={readOnly} invalid={invalid} errorText={error?.message}>
                                    <ReactSelect
                                        isSearchable
                                        required
                                        isDisabled={readOnly}
                                        autoFocus={true}
                                        ref={ref}
                                        name={name}
                                        onBlur={onBlur}
                                        placeholder='Projekt'
                                        options={projectNames}
                                        onChange={val => onChange(val.value)}
                                        value={projectNames.find(c => c.value === value)}
                                    />
                                </Field>
                            )} />

                        <Controller control={control} name='fundSourceName'
                            rules={{ required: "Institution auswählen", validate: (value) => value.length > 0 }}
                            render={({ field: { value, name, onChange, onBlur, ref }, fieldState: { error, invalid } }) => (
                                <Field id="fundSourceName" label='Institution (oder neue Institution erstellen)' disabled={readOnly} required invalid={invalid} errorText={error?.message}>
                                    <CreatableSelect
                                        isSearchable
                                        required
                                        readOnly={readOnly}
                                        ref={ref}
                                        name={name}
                                        autoFocus={false}
                                        onBlur={onBlur}
                                        placeholder='Institution'
                                        options={institutions}
                                        onChange={val => onChange(val.value)}
                                        value={institutions.find(c => c.value === value)}
                                        formatCreateLabel={(item) => `Neue Institution Erstellen: ${item}`}
                                    />
                                </Field>
                            )} />

                        <Stack direction={{ base: 'column', sm: 'row' }}>
                            
                            <DateSelectController label='Datum' control={control} name='entryDate' clearable={false} required disabled={readOnly} />

                            <Controller control={control} name='amount'
                                rules={{ required: "Summe eingeben", validate: (value) => value > 0 || "Summe eingeben" }}
                                render={({ field: { value, name, onChange, onBlur, ref }, fieldState: { error, invalid } }) => {
                                    console.log(error);
                                    return (
                                        <Field id="amount" label='Summe' required invalid={invalid} errorText={error?.message} disabled={readOnly}>
                                            <InputGroup startElement={<BiWallet color='blue' />}>
                                                <CustomAmountInput value={value} onChange={onChange} onBlur={onBlur} ref={ref} />
                                            </InputGroup>
                                        </Field>
                                    )
                                }} />
                        </Stack>

                        <Field id="comments" label='Bemerkungen' disabled={readOnly}>
                            <Textarea {...register('comments')} />
                        </Field>

                        {/* submittion */}
                        <Flex justifyContent='flex-end'>
                            <Group gap={2}>
                                <CancelButton onClick={() => navigate('/fundraising/commitments/')} />
                                {!readOnly && (<SubmitButton isSubmitting={isSubmitting} />)}
                            </Group>
                        </Flex>
                    </Stack>
                </Fieldset.Root>
            </form>
        </Flex>
    );
}

export default CommitmentsForm