
export interface Absence {
  id: number;
  startDate: Date;
  endDate: Date;
  days: number;
  entryId: (number | null);
  comments: (string | null);
  userId: number;
  organizationId: number;
}

export interface AbsenceData {
  id: number;
  name: string;
  detail?: string;
  color: string;
}

export const absenceData: AbsenceData[] = [
  {
    id: 100,
    name: "Ferien",
    color: "#9FEE33", // green
  },
  {
    id: 101,
    name: "Kompensation",
    color: "#FFB533", // Orange
  },
  {
    id: 102,
    name: "Krankheit",
    color: "#FF33B5", // rosa
  },
];

export const absenceDataMap = new Map<number, AbsenceData>(
  absenceData.map((data) => [data.id, data])
);
