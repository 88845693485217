// Generated by ScalaTS 0.7.0: https://scala-ts.github.io/scala-ts/

declare var exports: any;

export const nsRoot = exports;

export const RootInhabitant = 'Root';

export type Root = typeof RootInhabitant;

export function isRoot(v: any): v is Root {
  return RootInhabitant == v;
}

export type RootSingleton = Root;
