import { EditIcon } from "@/components/icons/Icon";
import { Box, Text, Separator, Flex, IconButton } from "@chakra-ui/react";
import { FcAlarmClock } from "react-icons/fc";
import Tags from "./Tags";
import { Tag } from "@/components/ui/tag";
import { CloseButton } from "@/components/ui/close-button"


const CardComponent = ({
  id,
  title,
  label,
  description,
  tags,
  cardColor,
  onDelete,
  onClick,
  onChange,
  className,
  cardStyle,
  showDeleteButton,
  t
}) => {
  const clickDelete = e => {
    onDelete()
    e.stopPropagation()
  }

  let deg = Math.random() * 4 - 2; // Generates a random number between -1 and 1

  //console.log(`id=${id}`);

  return (
    <Box style={{
      display: 'flex',
      flexDirection: 'column',
      transform: `rotate(${deg}deg)`,
    }}
      boxShadow={'lg'}
      fontFamily={'Caveat'}
      fontWeight={'bold'}
      fontSize={'1.4rem'}
      my={'10px'}
      mx={'2px'}
      pb={'2'}
      px={'1'}
      color={cardColor}
      backgroundColor={'#F4F265'}
      cursor={'pointer'}
      borderRadius={'5'}
      borderBottom='1px solid #eee'
      justifyContent='space-between'
      maxW='20rem'
    >
      <Text width={'12rem'}>{title}</Text>
      <IconButton aria-label='Edit card' size='xs' variant='ghost' onClick={onClick}
        style={{ transition: 'all 0.6s ease', position: 'absolute', top: '-1px', right: '28px' }} >
        <EditIcon />
      </IconButton>
      {showDeleteButton && <CloseButton size='sm' onClick={clickDelete}
        style={{ transition: 'all 0.6s ease', position: 'absolute', top: '-1px', right: '2px' }} />}
      {description && (
        <>
          <Separator borderWidth='1px' borderColor='orange' />
          <Text fontWeight='light' fontSize='md' whiteSpace='break-spaces'>{description}</Text>
        </>
      )}
      {tags && (
        <Flex justifyContent='space-between' flexDirection='row'>
          <Tags tags={tags} />
          {label && (
            <Tag size='md' key='Issue' variant='subtle' colorPalette='yellow' fontFamily='Verdana' fontSize='xs' dropShadow='3px'>
              <FcAlarmClock /> {label}
            </Tag>
          )}
        </Flex>
      )}
    </Box>
  )
}

export default CardComponent