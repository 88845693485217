// Generated by ScalaTS 0.7.0: https://scala-ts.github.io/scala-ts/

declare var exports: any;

export const nsAlgorithmWatch = exports;

export const AlgorithmWatchInhabitant = 2;

export type AlgorithmWatch = typeof AlgorithmWatchInhabitant;

export function isAlgorithmWatch(v: any): v is AlgorithmWatch {
  return AlgorithmWatchInhabitant == v;
}

export type AlgorithmWatchSingleton = AlgorithmWatch;
