import WaitingSpinner from "@/components/WaitingSpinner";
import { Text, Stack, Skeleton, Box, Flex, HStack, Icon, VStack, Separator, SimpleGrid, Heading } from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { IconType } from "react-icons";
import { BiCircle, BiCircleHalf, BiCircleQuarter, BiCircleThreeQuarter } from "react-icons/bi";
import { AccountingEntry } from "src/api/AccountingEntry";
import { ProvisionQuarterDetail } from "src/api/ProvisionQuarterDetail";
import { ProvisionsDetailResponse } from "src/api/ProvisionsDetailResponse";
import CancelButton from "src/components/buttons/CancelButton";
import DataTable, { amountCell, amountHeader, dateCell } from "src/components/DataTable";
import LineWithDot from "src/components/LineWithDot";
import { AmountUtil } from "src/data/AmountUtil";
import { reportService } from "src/service/ReportService";
import { useColorModeValue } from "@/components/ui/color-mode"
import { DialogRoot, DialogContent, DialogHeader, DialogBody, DialogCloseTrigger, DialogFooter } from "@/components/ui/dialog";
import { StatRoot, StatHelpText, StatValueText } from "@/components/ui/stat"


interface ProvisioningEntry {
    entry: AccountingEntry;
    source: string;
}

interface QuarterProps {
    title: string;
    detail: ProvisionQuarterDetail;
    icon: IconType;
}

const Quarter = ({ title, detail, icon }: QuarterProps) => {

    const columns: ColumnDef<ProvisioningEntry>[] = useMemo(
        () => [
            {
                id: 'date',
                header: () => <div style={{ textAlign: 'left', width: '8rem', paddingRight: '.1rem' }}>Datum</div>,
                accessorKey: 'entry.entryDate',
                cell: dateCell(),
            },
            {
                id: 'year',
                header: 'Fonds Yahr',
                accessorKey: 'entry.year',
            },
            {
                id: 'amount',
                header: amountHeader({ name: 'Summe', width: '8rem' }),
                accessorKey: 'entry.amount',
                cell: amountCell({ width: '8rem' }),
            },
            {
                id: 'entryType',
                header: () => <div style={{ textAlign: 'right', width: '8rem', paddingRight: '.1rem' }}>Typ</div>,
                accessorKey: 'entry.entryType',
            },
            {
                id: 'source',
                header: 'Aus',
                accessorKey: 'source',
            },
            {
                id: 'comments',
                header: () => <div style={{ textAlign: 'left', width: '20rem', paddingRight: '.1rem' }}>Bemerkungen</div>,
                accessorKey: 'entry.comments',
            },
        ], []);

    const entries = detail.previous.map(p => {
        let a: ProvisioningEntry = { entry: p, source: 'Vorjahr' };
        return a;
    }).concat(detail.current.map(c => {
        let a: ProvisioningEntry = { entry: c, source: 'Laufjahr' };
        return a;
    }));

    const sum = (list: readonly AccountingEntry[]): number => list.reduce((e, accu) => e + (accu.amount ?? 0), 0);

    return (
        <HStack
            p={{ base: 1, sm: 3 }}
            bg={useColorModeValue('gray.100', 'gray.800')}
            gap={2}
            w='full'
            rounded="lg"
            alignItems="center"
            alignContent='center'
            textAlign='center'
            pos="relative"
            _before={{
                content: `""`,
                w: '0',
                h: '0',
                borderColor: `transparent ${useColorModeValue('#edf2f6', '#1a202c')} transparent`,
                borderStyle: 'solid',
                borderWidth: '15px 15px 15px 0',
                position: 'absolute',
                left: '-15px',
                display: 'block'
            }}
        >
            <Icon as={icon} w={8} h={8} color="teal.400" />
            <Heading>{title}</Heading>
            <VStack gap={2} mb={3} textAlign='center' w='full'>
                {entries.length > 0 && (
                    <Box w='100%'>
                        <DataTable columns={columns} data={entries} initialSortByColumn='date' initialSortByDesc={false} />
                    </Box>)}
                <Separator borderColor='teal.400' borderWidth='1px' />
                {/* stats */}
                <Box
                    shadow={'md'}
                    w='full'
                    mt={2}
                    border={'1px solid'}
                    borderColor={useColorModeValue('gray.200', 'gray.800')}
                    rounded={'lg'}
                    textAlign="center"
                    alignItems='center'
                    alignContent='center'
                >
                    <SimpleGrid columns={{ base: 1, md: 3 }} gap={1} mt={0} mb={3}
                        textAlign="center"
                        alignItems='center'
                        alignContent='center'>
                        <VStack textAlign="center" align="center">
                            <StatRoot>
                                <StatHelpText fontWeight='bold'>Vorjahr</StatHelpText>
                                <StatValueText>{AmountUtil.format(detail.start + sum(detail.previous))}</StatValueText>
                                <StatHelpText>CHF</StatHelpText>
                            </StatRoot>
                        </VStack>
                        <VStack textAlign="center" align="center">
                            <StatRoot>
                                <StatHelpText fontWeight='bold'>Laufjahr</StatHelpText>
                                <StatValueText>{AmountUtil.format(sum(detail.current))}</StatValueText>
                                <StatHelpText>CHF</StatHelpText>
                            </StatRoot>
                        </VStack>
                        <VStack textAlign="center" align="center">
                            <StatRoot>
                                <StatHelpText fontWeight='bold'>Saldo</StatHelpText>
                                <StatValueText>{AmountUtil.format(detail.end)}</StatValueText>
                                <StatHelpText>CHF</StatHelpText>
                            </StatRoot>
                        </VStack>
                    </SimpleGrid>
                </Box>
            </VStack>
        </HStack>
    );
};

export interface DashboardProvisionsDialogProps {
    projectId: number;
    projectName: string;
    projectYear: number;
    donationCode: string;
    isOpen: boolean;
    onClose: () => void;
}

const DashboardProvisionsDialog = ({ projectId, projectName, projectYear, donationCode, isOpen, onClose }: DashboardProvisionsDialogProps) => {

    const [data, setData] = useState<ProvisionsDetailResponse | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        console.log(`details for ${donationCode}`);
        setIsLoading(true);
        reportService.provisionsDetail(projectYear, projectId, donationCode)
            .then(r => setData(r))
            .finally(() => setIsLoading(false));
    }, [projectYear, projectId, donationCode, isOpen]);

    return (
        <DialogRoot
            open={isOpen}
            onOpenChange={() => onClose()}
            placement='center'
            size='cover'
            motionPreset='scale'
            scrollBehavior='outside'>
            <DialogContent>
                <DialogHeader>{projectName}<Text textStyle='xs'>{projectYear} - {donationCode}</Text></DialogHeader>
                <DialogCloseTrigger />
                <DialogBody>
                    {!data && isLoading ? (
                        <Stack py={5}>
                            <WaitingSpinner size='xl' />
                            <Skeleton height='40px' />
                            <Skeleton height='40px' />
                            <Skeleton height='40px' />
                            <Skeleton height='40px' />
                        </Stack>
                    ) : (
                        <>
                            {[
                                { title: 'Q1', detail: data.q1, icon: BiCircleQuarter },
                                { title: 'Q2', detail: data.q2, icon: BiCircleHalf },
                                { title: 'Q3', detail: data.q3, icon: BiCircleThreeQuarter },
                                { title: 'Q4', detail: data.q4, icon: BiCircle }
                            ].map((milestone, index) => (
                                <Flex key={index} mb="10px">
                                    <LineWithDot />
                                    <Quarter {...milestone} />
                                </Flex>
                            ))}
                        </>
                    )}
                </DialogBody>

                <DialogFooter>
                    <Flex justifyContent='flex-end'>
                        <CancelButton onClick={onClose} />
                    </Flex>
                </DialogFooter>
            </DialogContent>
        </DialogRoot>
    );

}

export default DashboardProvisionsDialog