import { Input, Textarea, Flex, Fieldset } from "@chakra-ui/react";
import { Select, chakraComponents } from "chakra-react-select";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { CustomDateInput } from '@/components/fields/DateSelect';
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { BoardCard } from "src/api/BoardCard";
import { useEffect, useState } from "react";
import ErrorMessage from "src/components/ErrorMessage";
import { dueFormat, formatDue, parseDue } from "src/data/DateTimeUtil";
import { BoardTag } from "src/api/BoardTag";
import { Field } from "@/components/ui/field"
import { Tag } from "@/components/ui/tag";
import { DialogRoot, DialogContent, DialogHeader, DialogBody, DialogCloseTrigger, DialogFooter } from "@/components/ui/dialog";
import CancelButton from "@/components/buttons/CancelButton";
import SubmitButton from "@/components/buttons/SubmitButton";


export interface CardDialogProps {
    data: BoardCard;
    isOpen: boolean;
    onClose: () => void;
    onConfirm: (b: BoardCard) => Promise<void>;
}

const tagOptions = [
    { value: "feature", label: "Feature", colorPalette: "green" },
    { value: "improvement", label: "Improvement", colorPalette: "orange" },
    { value: "bug", label: "Bug", colorPalette: "red" },
    { value: "todo", label: "To Do", colorPalette: "blue" },
    { value: "tech", label: "Tech", colorPalette: "purple" },
    // { value: "purple", label: "Purple", colorPalette: "cyan" },
    // { value: "yellow", label: "Yellow", colorPalette: "yellow" },
];

const customOptionItem = (props) => (
    <chakraComponents.Option label={props.data.name} {...props}>
        <Flex align="center">
            <Tag size='md' key={props.data.value} variant='subtle' colorPalette={props.data.colorPalette} fontFamily='Verdana' fontSize='xs' dropShadow='3px'>
                {props.data.label}
            </Tag>
        </Flex>
    </chakraComponents.Option>
);

const CardDialog = ({ data, isOpen, onClose, onConfirm }: CardDialogProps) => {

    const [error, setError] = useState('');

    const createDefault = (): BoardCard => {
        return {
            id: crypto.randomUUID(),
            title: '',
            description: null,
            label: null,
            tags: [],
        }
    }

    const { control, register, handleSubmit, reset, formState: { isSubmitting } } = useForm<BoardCard>({
        defaultValues: createDefault()
    });

    const onSubmitInternal: SubmitHandler<BoardCard> = (p) => {
        console.info(`submitting: ${JSON.stringify(p)}`);
        return onConfirm(p).then(() => {
            reset();
            onClose()
        }).catch(err => setError('Fehler'));
    };

    const safeParseDate = (s: string): Date => {
        if (s && s.length > 0) {
            try {
                return parseDue(s);
            } catch (e) {
                return undefined;
            }
        } else {
            return undefined;
        }
    }

    const safeFormatDue = (d: Date): string => {
        try {
            return formatDue(d);
        } catch (e) {
            return null;
        }
    }


    // update the state got from the props at every open
    useEffect(() => {
        console.info(`resetting form to ${JSON.stringify(data)}`)
        data ? reset(data) : reset(createDefault());
    }, [data, reset, isOpen]);

    return (
        <DialogRoot
            open={isOpen}
            onOpenChange={() => onClose()}
            placement='center'
            size='lg'
            motionPreset='scale'
            scrollBehavior='outside'
        >
            <DialogContent>
                <form onSubmit={handleSubmit(onSubmitInternal)}>
                    <DialogHeader>{data ? 'Update' : 'Create'} a task</DialogHeader>
                    <DialogCloseTrigger />
                    <DialogBody pb={6}>
                        <Fieldset.Root>
                            {error && <ErrorMessage message={error} />}
                            <Field label='Task to do' required>
                                <Input autoFocus={true} placeholder='To Do' {...register('title')} />
                            </Field>

                            <Field label='Description' mt={4}>
                                <Textarea placeholder='More details ...' {...register('description')} height='12rem' />
                            </Field>

                            <Controller control={control} name='tags'
                                render={({ field: { value, name, onChange, onBlur, ref }, fieldState: { error } }) => (
                                    <Field label='Tag' errorText={error?.message}>
                                        <Select
                                            isMulti
                                            options={tagOptions}
                                            placeholder="Select tags..."
                                            closeMenuOnSelect={false}
                                            components={{ Option: customOptionItem }}
                                            //formatOptionLabel={(option: Option) => customSelectItem(option)}
                                            ref={ref}
                                            name={name}
                                            onBlur={onBlur}
                                            onChange={(val: any[]) => {
                                                let ops = Array.from(val.values()).map(v => {
                                                    let tag: BoardTag = {
                                                        title: v.label,
                                                        bgcolor: v.colorPalette,
                                                        color: 'white'
                                                    }
                                                    return tag;
                                                });
                                                onChange(ops);
                                            }}
                                            value={tagOptions.filter(c => value.find(t => t.title === c.label))}
                                        />
                                    </Field>
                                )} />

                            <Controller control={control} name='label' render={({ field }) => (
                                <Field label='Due'>
                                    <Flex w='40rem'>
                                        <DatePicker
                                            selected={safeParseDate(field.value)}
                                            onChange={(date: Date | null) => {
                                                //console.log(`date value ${date}`);
                                                let label = date ? safeFormatDue(date) : null;
                                                field.onChange(label);
                                            }}
                                            showTimeSelect
                                            timeIntervals={60}
                                            dateFormat={dueFormat}
                                            isClearable
                                            customInput={<CustomDateInput w='20rem' />}
                                            placeholderText='Set due date if available'
                                        />
                                    </Flex>
                                </Field>
                            )} />
                        </Fieldset.Root>
                    </DialogBody>

                    <DialogFooter>
                        <CancelButton onClick={onClose}/>
                        <SubmitButton isSubmitting={isSubmitting} /> 
                    </DialogFooter>
                </form>
            </DialogContent>
        </DialogRoot>
    )
}

export default CardDialog