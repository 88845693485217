export interface TimesheetData {
  id: number;
  name: string;
  detail?: string;
  color: string;
}

export const timesheetData: TimesheetData[] = [
  {
    id: 100,
    name: "Finanzen",
    color: "#ff3b14", // Orange-red
  },
  {
    id: 101,
    name: "PM allgemein",
    color: "#3357FF", // Blue
  },
  {
    id: 102,
    name: "PM spezifisch",
    color: "#1111AA", // Dark Blue
  },
  {
    id: 103,
    name: "FR Inst allgemein",
    color: "#008080", // teal
  },
  {
    id: 104,
    name: "FR Inst spezifisch",
    color: "#008080", // teal
  },
  {
    id: 105,
    name: "FR Private allgemein",
    color: "#FFB533", // Orange
  },
  {
    id: 106,
    name: "FR Private spezifisch",
    detail: "Patenschaften",
    color: "#FF8033", // Dark orange
  },
  {
    id: 107,
    name: "FR Private spezifisch",
    detail: "Friends",
    color: "#FF8033", // Dark orange
  },
  {
    id: 108,
    name: "FR Private spezifisch",
    detail: "Individuell GG",
    color: "#FF8033", // Dark orange
  },
  {
    id: 109,
    name: "FR Private spezifisch",
    detail: "Testament / Nachlassplanung",
    color: "#FF8033", // Dark orange
  },
  {
    id: 110,
    name: "Online Fundraising",
    color: "#FF5733", // Coral
  },
  {
    id: 111,
    name: "Spendenaufruf",
    color: "#FF33B5", // Rose
  },
  {
    id: 112,
    name: "Bulletin, JB, Drucksachen",
    color: "#DDDD33", // dark Yellow
  },
  {
    id: 113,
    name: "Internet",
    color: "#DDDD33", // dark Yellow
  },
  {
    id: 114,
    name: "Events, PR, Werbung, Info",
    color: '#39db2e', // green
  },
  {
    id: 115,
    name: "Verein",
    color: '#39db2e', // green
  },
  {
    id: 116,
    name: "Team, Personal",
    color: '#39db2e', // green
  },
  {
    id: 117,
    name: "Administration",
    color: '#39db2e', // green
  },
];

export const timesheetDataMap = new Map<number, TimesheetData>(
  timesheetData.map((data) => [data.id, data])
);
