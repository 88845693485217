
import { Provider } from "@/components/ui/provider"
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Header from './components/Header'
import Landing from './pages/Landing'
import Login from './pages/auth/Login'
import Info from './pages/Info'

import './App.css';
import { AuthProvider, useAuthContext } from './auth/AuthContext';
import OverviewPartners from './pages/partners/OverviewPartners';
import PartnersAdd from './pages/partners/PartnersAdd';
import OverviewProjects from './pages/projects/OverviewProjects';
import PartnersEdit from './pages/partners/PartnersEdit';
import PartnersView from './pages/partners/PartnersView';
import ProjectsAdd from './pages/projects/ProjectsAdd';
import ProjectsEdit from './pages/projects/ProjectsEdit';
import ProjectsView from './pages/projects/ProjectsView';
import OverviewDonationCodes from './pages/donations/OverviewDonationCodes';
import OverviewTransfers from './pages/funds/OverviewTransfers';
import OverviewFunds from './pages/funds/OverviewFunds';
import OverviewFundsMoves from './pages/funds/OverviewFundsMoves';
import DashboardProvisions from './pages/funds/DashboardProvisions';
import OverviewCommitments from './pages/fundraising/OverviewCommitments';
import DashboardFundraisingAll from './pages/fundraising/DashboardFundraisingAll';
import Forgot from './pages/auth/Forgot';
import Profile from './pages/auth/Profile';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import CommitmentsAdd from './pages/fundraising/CommitmentsAdd';
import OverviewDonors from './pages/donations/OverviewDonors';
import CommitmentsEdit from './pages/fundraising/CommitmentsEdit';
import CommitmentsView from './pages/fundraising/CommitmentsView';
import OverviewExpenses from './pages/expenses/OverviewExpenses';
import ExpensesAdd from './pages/expenses/ExpensesAdd';
import ExpensesEdit from './pages/expenses/ExpensesEdit';
import ExpensesView from './pages/expenses/ExpensesView';
import Logout from './pages/auth/Logout';
import OverviewTransfersMoves from './pages/funds/OverviewTransfersMoves';
import TransfersMoveAdd from './pages/funds/TransfersMoveAdd';
import TransfersMoveEdit from './pages/funds/TransfersMoveEdit';
import TransfersMoveView from './pages/funds/TransfersMoveView';
import NotFound from './pages/NotFound';
import DashboardFundraisingProject from './pages/fundraising/DashboardFundraisingProject';
import Dev from './pages/boards/Dev';
import DashboardProjectsAll from './pages/projects/DashboardProjectsAll';
import DashboardProject from './pages/projects/DashboardProject';
import DashboardFundsMoves from './pages/funds/DashboardFundsMoves';
import OverviewUsers from './pages/admin/OverviewUsers';
import UsersAdd from './pages/admin/UsersAdd';
import OverviewTimesheet from './pages/timesheet/OverviewTimesheet';
import OverviewAbsence from './pages/timesheet/OverviewAbsence';
import OverviewPublicHoliday from './pages/timesheet/OverviewPublicHoliday';


ReactGA.initialize([
  { trackingId: 'G-7B41YC11PS' }
]);

const Layout = ({ children }) => {
  const { user } = useAuthContext();
  const userId = user?.data?.id;
  //console.log(`setting userId ${userId}`);
  ReactGA.set({ userId: userId });
  return (
    <>
      <Header />
      {children}
    </>
  );
}

const App = () => {
  useEffect(() => {
    const page = window.location.pathname + window.location.search;
    //console.log(`sending pageview for ${page}`);
    ReactGA.send({ hitType: "pageview", page: page });
  }, []);

  return (
    <Provider defaultTheme='light'>
      <AuthProvider>
        <BrowserRouter future={{
          v7_startTransition: true,
          v7_relativeSplatPath: true,
        }}>
          <Routes>
            <Route path="/" element={<Layout><Landing /></Layout>} />
            <Route path="/login" element={<Layout><Login /></Layout>} />
            <Route path="/logout" element={<Layout><Logout /></Layout>} />
            <Route path="/forgot" element={<Layout><Forgot /></Layout>} />
            <Route path="/profile" element={<Layout><Profile /></Layout>} />
            <Route path="/partners" element={<Layout><OverviewPartners consultant={false} /></Layout>} />
            <Route path="/partners/consultants" element={<Layout><OverviewPartners consultant={true} /></Layout>} />
            <Route path="/partners/add" element={<Layout><PartnersAdd consultant={false} /></Layout>} />
            <Route path="/partners/consultants/add" element={<Layout><PartnersAdd consultant={true} /></Layout>} />
            <Route path="/partners/edit/:id" element={<Layout><PartnersEdit /></Layout>} />
            <Route path="/partners/view/:id" element={<Layout><PartnersView /></Layout>} />
            <Route path="/projects" element={<Layout><OverviewProjects /></Layout>} />
            <Route path="/projects/add" element={<Layout><ProjectsAdd /></Layout>} />
            <Route path="/projects/edit/:id" element={<Layout><ProjectsEdit /></Layout>} />
            <Route path="/projects/view/:id" element={<Layout><ProjectsView /></Layout>} />
            <Route path="/projects/overview" element={<Layout><DashboardProjectsAll /></Layout>} />
            <Route path="/projects/overview/projects/:id?" element={<Layout><DashboardProject /></Layout>} />
            <Route path="/donations/donors" element={<Layout><OverviewDonors /></Layout>} />
            <Route path="/donations/codes" element={<Layout><OverviewDonationCodes /></Layout>} />
            <Route path="/transfers/moves" element={<Layout><OverviewTransfersMoves /></Layout>} />
            <Route path="/transfers/moves/add" element={<Layout><TransfersMoveAdd /></Layout>} />
            <Route path="/transfers/moves/edit/:id" element={<Layout><TransfersMoveEdit /></Layout>} />
            <Route path="/transfers/moves/view/:id" element={<Layout><TransfersMoveView /></Layout>} />
            <Route path="/transfers" element={<Layout><OverviewTransfers /></Layout>} />
            <Route path="/funds/moves" element={<Layout><OverviewFundsMoves /></Layout>} />
            <Route path="/funds/provisions" element={<Layout><DashboardProvisions /></Layout>} />
            <Route path="/funds/moves/overview" element={<Layout><DashboardFundsMoves /></Layout>} />
            <Route path="/funds" element={<Layout><OverviewFunds /></Layout>} />
            <Route path="/fundraising" element={<Layout><DashboardFundraisingAll /></Layout>} />
            <Route path="/fundraising/projects/:id?" element={<Layout><DashboardFundraisingProject /></Layout>} />
            <Route path="/fundraising/commitments" element={<Layout><OverviewCommitments /></Layout>} />
            <Route path="/fundraising/commitments/add" element={<Layout><CommitmentsAdd /></Layout>} />
            <Route path="/fundraising/commitments/edit/:id" element={<Layout><CommitmentsEdit /></Layout>} />
            <Route path="/fundraising/commitments/view/:id" element={<Layout><CommitmentsView /></Layout>} />
            <Route path="/expenses" element={<Layout><OverviewExpenses /></Layout>} />
            <Route path="/expenses/add" element={<Layout><ExpensesAdd /></Layout>} />
            <Route path="/expenses/edit/:id" element={<Layout><ExpensesEdit /></Layout>} />
            <Route path="/expenses/view/:id" element={<Layout><ExpensesView /></Layout>} />
            <Route path="/admin/users" element={<Layout><OverviewUsers /></Layout>} />
            <Route path="/admin/users/add" element={<Layout><UsersAdd /></Layout>} />
            <Route path="/timesheet" element={<Layout><OverviewTimesheet /></Layout>} />
            <Route path="/timesheet/absences" element={<Layout><OverviewAbsence /></Layout>} />
            <Route path="/timesheet/holidays" element={<Layout><OverviewPublicHoliday /></Layout>} />
            <Route path="/info" element={<Layout><Info /></Layout>} />
            <Route path="/dev" element={<Layout><Dev /></Layout>} />
            <Route path="*" element={<Layout><NotFound /></Layout>} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </Provider>
  );
}

export default App
