// Generated by ScalaTS 0.7.0: https://scala-ts.github.io/scala-ts/

declare var exports: any;

export const nsDemo = exports;

export const DemoInhabitant = 999;

export type Demo = typeof DemoInhabitant;

export function isDemo(v: any): v is Demo {
  return DemoInhabitant == v;
}

export type DemoSingleton = Demo;
