import { ArrowBackIcon, ArrowForwardIcon, TimeIcon } from '@/components/icons/Icon';
import { Box, Separator, Flex, IconButton, Skeleton, Stack, Text } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { timesheetService } from 'src/service/TimesheetService';
import { Holiday } from "src/api/Holiday";
import Content from "src/components/Content";
import { ColumnDef, CellContext } from '@tanstack/react-table';
import DataTable from "src/components/DataTable";
import { isWeekend } from "date-fns";
import { formatOverview } from "src/data/DateTimeUtil";
import WaitingSpinner from "@/components/WaitingSpinner";
import { Tag } from "@/components/ui/tag";
import { Tooltip } from "@/components/ui/tooltip";
import { StatLabel, StatRoot, StatHelpText } from "@/components/ui/stat"
import StatContentText from '@/components/StatContentText';


// TODO levi
const ZurichFlag = () => {
    return <Box
        as="svg"
        //xmlns="http://www.w3.org/2000/svg"
        //viewBox="0 0 100 100"
        fill="none"
        boxSize='1rem'
        borderWidth='1px'
    >
        <rect width="100" height="100" fill="white" />
        <polygon points="0,0 100,100 0,100" fill="#007AC8" />
    </Box>
}

const OverviewPublicHoliday = () => {
    const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<Holiday[]>([]);

    useEffect(() => {
        setIsLoading(true);
        timesheetService
            .publicHolidays(currentYear)
            .then(r => setData(r))
            .finally(() => setIsLoading(false));

    }, [currentYear]);

    const SummaryTabs = () => (<>
        <Flex pt='1rem' justify='left' mb='3rem'>
            <Stack shadow='md' rounded='md' textAlign='right' border={'1px solid lightgray'} direction={{ base: 'row', sm: 'row' }}>
                <StatRoot px='1rem' key='s1' borderRight='1px solid lightgray'>
                    <StatLabel>Total</StatLabel>
                    <Separator orientation='horizontal' />
                    <StatContentText>{data.length}</StatContentText>
                    <StatHelpText fontSize='xs'>Feiertage gesamt</StatHelpText>
                </StatRoot>
                <StatRoot px='1rem' key='s2' borderRight='1px solid lightgray'>
                    <StatLabel>Werktage</StatLabel>
                    <Separator orientation='horizontal' />
                    <StatContentText>{data.filter(h => !h.weekend).length}</StatContentText>
                    <StatHelpText fontSize='xs'>Feiertage an Werktagen</StatHelpText>
                </StatRoot>
            </Stack>
        </Flex>
    </>);

    const YearNavigation = () => {
        return (
            <Box display="flex" alignItems="left" mb={4}>
                <IconButton
                    rounded='full'
                    variant='solid'
                    colorPalette='blue'
                    aria-label='Previous'
                    fontSize='20px'
                    onClick={() => setCurrentYear(c => c - 1)}
                ><ArrowBackIcon /></IconButton>
                <Text width='8rem' fontSize="2xl" fontWeight={'bold'} textAlign={'center'} mx={2}>{currentYear}</Text>
                <IconButton
                    rounded='full'
                    variant='solid'
                    colorPalette='blue'
                    aria-label='Next'
                    fontSize='20px'
                    onClick={() => setCurrentYear(c => c + 1)}
                ><ArrowForwardIcon /></IconButton>
                <Tooltip content='Dieses Jahr'>
                    <IconButton
                        rounded='full'
                        variant='solid'
                        colorPalette='blue'
                        aria-label='Dieses Jahr'
                        fontSize='20px'
                        ml='5'
                        onClick={() => setCurrentYear(new Date().getFullYear())}
                    ><TimeIcon /></IconButton>
                </Tooltip>
            </Box>
        );
    };

    const columns: ColumnDef<Holiday>[] = useMemo(
        () => [
            {
                id: 'date',
                header: 'Datum',
                accessorKey: 'date',
                cell: (info: CellContext<Holiday, Date>) => {
                    const day = info.getValue();
                    const weekend = isWeekend(day);
                    return <Text fontSize='xs' color={weekend ? 'green' : 'none'}>{formatOverview(info.getValue())}</Text>;
                }
            },
            {
                id: 'day',
                header: 'Wochentag',
                accessorKey: 'date',
                cell: (info: CellContext<Holiday, Date>) => {
                    const day = new Date(info.getValue());
                    const weekend = isWeekend(day);
                    return <Text fontSize='xs' color={weekend ? 'green' : 'none'}>{day.toLocaleDateString('de-CH', { weekday: 'long' })}</Text>;
                }
            },
            {
                id: 'name',
                header: 'Beschreibung',
                accessorKey: 'name',
                cell: (info: CellContext<Holiday, string>) => {
                    const name = info.getValue();
                    const weekend = isWeekend(info.row.original.date)
                    return <Text fontSize='xs' color={weekend ? 'green' : 'none'}>{name}</Text>;
                }
            },
            {
                id: 'tags',
                header: 'Anmerkungen',
                accessorFn: (row: Holiday): string[] => {
                    const tags = new Array<string>();
                    // if (!row.nationwide) {
                    //     tags.push('Regional')
                    // }
                    if (row.lengthInDays < 1) {
                        tags.push('½ Halbtag')
                    }
                    return tags;
                },
                cell: (info: CellContext<Holiday, string[]>) => {
                    const tags = info.getValue();
                    return tags.map((tag: string) => (
                            <Tag key={tag} variant='surface' size='sm' colorPalette='teal' fontSize='xs' dropShadow='3px'>
                                {tag}
                            </Tag>
                    ));
                }
            }
        ], []
    );

    return (
        <Content caption='Feiertage'>
            <Flex gap='.5rem' justifyContent='center'>
                <Text fontSize='lg' fontWeight='bold'>Zürich</Text>
                <span style={{ display: 'inline-flex', alignItems: 'center' }}><ZurichFlag /></span>
            </Flex>

            <SummaryTabs />

            <YearNavigation />

            {isLoading ? (
                <Stack py={5}>
                    <WaitingSpinner size='xl' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                    <Skeleton height='20px' />
                </Stack>
            ) : (
                <Box p='2px' width={{
                    base: '100%', // 0-48em
                    md: '80%',     // 48em+
                    lg: '70%',     // 62em+
                    xl: '65%',     // 80em+'
                }}>
                    <DataTable columns={columns} data={data} tableSize='sm'
                        hideColumnsOnXs={['tags']} initialSortByColumn={'date'} initialSortByDesc={false}
                    />
                </Box>
            )}
        </Content>
    );
}

export default OverviewPublicHoliday;