import { AddIcon } from "@/components/icons/Icon";
import { Button } from "@/components/ui/button"
import { ReactElement } from "react";


export interface AddButtonProps {
    caption: string;
    onClick: () => void;
}

const AddButton = (props: AddButtonProps): ReactElement => {
    return (<Button colorPalette="blue" rounded="md" onClick={() => props.onClick()}><AddIcon /> {props.caption}</Button>)
}

export default AddButton