import ProgressBar from "@ramonak/react-progress-bar";
import { useColorModeValue } from "./ui/color-mode";

const normalize = (value: number): number => Math.min(100, Math.max(0, value * 100));
const color = (percent: number, chakra: boolean = true): string => {
    let colour = chakra ? 'red' : 'tomato'
    if (percent > 80) {
        colour = chakra ? 'whatsapp' : 'limegreen'
    } else if (percent > 60) {
        colour = chakra ? 'teal' : 'gold'
    } else if (percent > 40) {
        colour = chakra ? 'telegram' : 'sandybrown'
    } else if (percent > 20) {
        colour = 'salmon'
    }
    return colour;
}

export const ProgressBarReact = (value: number, fontWeight: string = 'normal') => {
    const percent = normalize(value);
    const labelColor = useColorModeValue('black', 'white');
    return (<ProgressBar completed={percent.toFixed(0)}
        transitionDuration='0.7s'
        animateOnRender
        bgColor={color(percent, false)}
        labelAlignment='outside'
        labelColor={labelColor}
        customLabel={`${(100 * value).toFixed(0)}%`}
        customLabelStyles={{ fontSize: '.9rem', fontWeight: fontWeight }}
    />)
}