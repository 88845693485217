import { BiBarChartAlt, BiPieChartAlt, BiWallet, BiWindow } from 'react-icons/bi';
import {
    Text, Box, Flex, Heading, Input, Stack,
    SimpleGrid, HStack, Textarea, Separator, useDisclosure,
    Tabs, Group,
    Fieldset
} from "@chakra-ui/react"
import CurrencySelect from '@/components/fields/CurrencySelect';
import ErrorMessage from "src/components/ErrorMessage";
import { Controller, useForm } from "react-hook-form";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { projectService } from 'src/service/ProjectService';
import { partnerService } from 'src/service/PartnerService';
import { useNavigate } from 'react-router-dom';
import DataTable from 'src/components/DataTable';
import { ColumnDef, CellContext } from '@tanstack/react-table';
import { ProjectSummary } from 'src/api/ProjectSummary';
import CountrySelect from '@/components/fields/CountrySelect';
import DateSelectController from '@/components/fields/DateSelect';
import { YearlyBudget } from 'src/api/YearlyBudget';
import { formatOverview } from 'src/data/DateTimeUtil';
import BudgetDialog from './BudgetDialog';
import { AmountUtil } from 'src/data/AmountUtil';
import ConfirmDialog from 'src/components/ConfirmDialog';
import {
    OptionBase,
    Select as ReactSelect
} from "chakra-react-select";
import AddButton from 'src/components/buttons/AddButton';
import ColorPicker from 'src/components/ColorPicker';
import { countries } from "src/data/Countries";
import CancelButton from 'src/components/buttons/CancelButton';
import SubmitButton from '@/components/buttons/SubmitButton';
import { Field } from '@/components/ui/field';
import { InputGroup } from '@/components/ui/input-group';
import { Switch } from '@/components/ui/switch';
import { Radio, RadioGroup } from "@/components/ui/radio"
import { StatLabel, StatRoot, StatValueText } from "@/components/ui/stat"
import { DropDownMenu } from '@/components/DropDownMenu';


export interface ProjectsFormProps {
    projectId: number;
    organizationId: number;
    onSubmit: (ps: ProjectSummary) => Promise<void>;
    viewOnly?: boolean;
    header: string;
}

export interface PartnerOption extends OptionBase {
    label: string;
    value: number;
}

const ProjectsForm = ({ projectId, organizationId, onSubmit, viewOnly, header }: ProjectsFormProps): JSX.Element => {
    const { control, register, handleSubmit, reset, getValues, formState: { errors, isSubmitting }, watch } = useForm<ProjectSummary>({
        defaultValues: {
            project: {
                id: projectId,
                name: '',
                startDate: new Date(),
                endDate: new Date(),
                internal: false,
                color: projectId > -1 ? '#FFFFFF' : '#FE9200', // otherwise flickers
                createdAt: new Date(),
                organizationId: organizationId,
            },
            budget: [],
            partnerIds: []
        }
    });
    const [serverError, setServerError] = useState('');

    // fixed or open
    const [budgetType, setBudgetType] = useState<string>('fixed');
    // use a default, will be updated before the modal opens
    const [changeBudget, setChangeBudget] = useState<YearlyBudget>(
        {
            projectId: -1,
            year: new Date().getFullYear(),
            budget: undefined,
            projectBudget: undefined,
            rate: undefined,
            rateDate: new Date(),
            indicativeRate: false,
            fundraisingBudget: undefined,
            overhead: 20,
            organizationId: organizationId
        });
    const { open: isBudgetOpen, onOpen: onBudgetOpen, onClose: onBudgetClose } = useDisclosure();
    const { open: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure();

    // manage the budget list in a state (not in the form)
    const [yearlyBudget, setYearlyBudget] = useState<YearlyBudget[]>([]);
    // manage configured partner ids in a state (not in the form)
    const [partnerIds, setPartnerIds] = useState<number[]>([]);

    const navigate = useNavigate();

    // read all partner details to map it with the partner ids configured to the project
    const [partners, setPartners] = useState([]);
    useEffect(() => {
        partnerService.listAll().then(ps => setPartners(ps.concat()));
    }, []);

    // in edit or view mode fill in the form details, budget and partner ids
    useEffect(() => {
        if (projectId > -1) {
            projectService.find(projectId).then(p => {
                console.info(`found project ${JSON.stringify(p)}`);
                setBudgetType(p.project.endDate ? 'fixed' : 'open');
                setYearlyBudget(p.budget.concat());
                setPartnerIds(p.partnerIds.concat());
                reset(p);
            });
        }
    }, [projectId, reset]);


    const onBudgetChange = useCallback((yb: YearlyBudget): Promise<void> => {
        console.info(`upserting budget for ${yb.year}`);
        setChangeBudget(yb);
        // update data, override the same year
        setYearlyBudget(prev => {
            let old = prev.filter(p => p.year !== yb.year);
            let newBudget: YearlyBudget[] = [].concat(old, yb).sort((a, b) => a.year - b.year);
            return newBudget;
        });
        return Promise.resolve();
    }, []);

    const readOnly = viewOnly ?? false;
    //console.info(`form is readonly: ${readOnly}`);

    const onSubmitInternal = (p: ProjectSummary): Promise<void> => {
        let validated: ProjectSummary = {
            ...p,
            ...{
                project: { ...p.project, ...{ endDate: (budgetType === 'open' ? null : p.project.endDate) } },
                budget: yearlyBudget,
                partnerIds: partnerIds
            }
        }
        console.info(`submitting ${JSON.stringify(validated)}`);
        return onSubmit(validated)
            .then(() => navigate('/projects'))
            .catch(_err => setServerError('Fehler'));
    };


    const amountHeader = (name: string) => () => (<div style={{ fontSize: 'xs', textAlign: 'right', width: '8rem', paddingRight: '.2rem' }}>{name}</div>)
    const amountCell = (fontWeight: string = 'normal') => (info: CellContext<YearlyBudget, number>) =>
        (<Text fontSize='xs' fontWeight={fontWeight} width='8rem' textAlign='right'>{AmountUtil.format(info.getValue())}</Text>)
    const columns: ColumnDef<YearlyBudget>[] = useMemo(
        () => [
            {
                id: 'year',
                header: 'Jahr',
                accessorKey: 'year',
                cell: (info: CellContext<YearlyBudget, number>) => (<Text textStyle='xs' fontWeight='bold'>{info.getValue()}</Text>)
            },
            {
                id: 'budget',
                header: amountHeader('Budget'),
                accessorKey: 'budget',
                cell: amountCell()
            },
            {
                id: 'rate',
                header: () => (<div style={{ textAlign: "right" }}>Kurs</div>),
                accessorFn: (row: YearlyBudget) => AmountUtil.format(row.rate),
                cell: (info: CellContext<YearlyBudget, string>) => (<Text fontSize='xs' textAlign='right'>{info.getValue()}</Text>)
            },
            {
                id: 'rateDate',
                header: () => (<div style={{ textAlign: "right" }}>Kursdatum</div>),
                accessorFn: (row: YearlyBudget) => formatOverview(row.rateDate),
                cell: (info: CellContext<YearlyBudget, string>) => (<Text fontSize='xs' textAlign='right'>{info.getValue()}</Text>)
            },
            {
                id: 'projectChf',
                header: amountHeader('Projekt CHF'),
                accessorKey: 'projectBudget',
                cell: amountCell('bold')
            },
            {
                id: 'overhead',
                header: () => (<div style={{ textAlign: "right" }}>Overhead</div>),
                accessorKey: 'overhead',
                cell: (info: CellContext<YearlyBudget, number>) => (<Text fontSize='xs' textAlign='right' fontWeight='bold'>{AmountUtil.format(info.getValue(), 1)}%</Text>)
            },
            {
                id: 'fundraisingChf',
                header: amountHeader('Fundraising CHF'),
                accessorKey: 'fundraisingBudget',
                cell: amountCell('bold')
            },
            {
                id: 'edit',
                enableSorting: false,
                cell: (info) => {
                    let row = info.row.original;
                    return (
                        <>
                            {!readOnly && (
                                <DropDownMenu onMenuEdit={() => {
                                    //console.info(`set to ${JSON.stringify(row)}`);
                                    setChangeBudget(row);
                                    onBudgetOpen();
                                }} onMenuDelete={() => {
                                    //console.info(`set to ${JSON.stringify(row)}`);
                                    setChangeBudget(row);
                                    onDeleteOpen();
                                }} />
                            )}
                        </>
                    )
                }
            }
        ], [onBudgetOpen, onDeleteOpen, readOnly]
    );

    return (
        <Flex
            minH={{ base: '50vh', lg: '70vh' }}
            align={'center'}
            justify={'center'}
        >
            <form noValidate onSubmit={handleSubmit(onSubmitInternal)}>
                <Fieldset.Root rounded={'lg'}
                    boxShadow={'lg'}
                    py='3rem'
                    px='2rem'
                    invalid={serverError && serverError.length > 0}
                >
                    <Stack align={'center'}>
                        <Heading fontSize={'4xl'} textAlign={'center'}>{header}</Heading>
                    </Stack>

                    <Stack gap={4}>
                        {serverError && <ErrorMessage message={serverError} />}

                        {/* 2 col layout */}
                        <Stack direction={{ base: 'column', sm: 'row' }} gap={8}>
                            <Stack gap={4}>
                                <Field id="name" label='Name' fontWeight='bold' required disabled={readOnly} invalid={!!errors.project?.name} errorText={errors.project?.name?.message}>
                                    <InputGroup w='full' startElement={<BiWindow color='blue' />}>
                                        <Input autoFocus={true} type="text" {...register('project.name', { required: 'Name ist erforderlich' })} />
                                    </InputGroup>
                                </Field>

                                {/* multi select for partners */}
                                <Box maxW={{ sm: '320px', base: '' }} minW='320px'>
                                    <Field id="partnerId" label='Partner Organisationen' disabled={readOnly}>
                                        <ReactSelect
                                            isSearchable={true}
                                            isMulti={true}
                                            isClearable={false}
                                            isDisabled={readOnly}
                                            name='partnersId'
                                            placeholder='Partners'
                                            options={partners.map(p => {
                                                let po: PartnerOption = {
                                                    value: p.id,
                                                    label: p.name
                                                }
                                                return po;
                                            })}
                                            value={partners
                                                .filter(p => partnerIds.includes(p.id))
                                                .map(p => {
                                                    let po: PartnerOption = {
                                                        value: p.id,
                                                        label: p.name
                                                    }
                                                    return po;
                                                })}
                                            onChange={val => setPartnerIds(val.map(v => v.value))}
                                        />
                                    </Field>
                                </Box>

                                <CountrySelect control={control} name='project.country' readOnly={readOnly} />

                                <CurrencySelect control={control} name='project.currency' readOnly={readOnly} />
                            </Stack>

                            {/* second column on large screen */}
                            <Stack gap='4'>
                                <Field id="budgetType" label='Budget' fontWeight={'bold'} required disabled={readOnly}>
                                    <RadioGroup value={budgetType} onValueChange={e => setBudgetType(e.value)} disabled={readOnly}>
                                        <Stack direction='row'>
                                            <Radio value='fixed'>Fester Zeitraum</Radio>
                                            <Radio value='open'>Laufend</Radio>
                                        </Stack>
                                    </RadioGroup>
                                </Field>

                                <Stack direction={{ base: 'column', sm: 'row' }} justify={'space-between'}>
                                    <DateSelectController control={control} required disabled={readOnly} label='Anfangsdatum' name='project.startDate' clearable={false} />
                                    <DateSelectController control={control}
                                        required={budgetType === 'fixed'}
                                        disabled={readOnly || budgetType === 'open'} label='Enddatum' name='project.endDate' clearable={true} />
                                </Stack>

                                <Stack direction={{ base: 'column', sm: 'row' }} justify={'space-between'}>
                                    <Field id="internal" readOnly={readOnly} disabled={readOnly}>
                                        <Controller
                                            control={control}
                                            name='project.internal'
                                            render={({ field: { onChange, value, ref } }) => (
                                                <Switch checked={value} onCheckedChange={e => onChange(e.checked)} ref={ref}>Interne Reserve</Switch>
                                            )}
                                        />
                                    </Field>

                                    <Field id='color' disabled={readOnly}>
                                        <Controller
                                            control={control}
                                            name='project.color'
                                            render={({ field: { value, onChange } }) => (
                                                <HStack alignItems="start">
                                                    <ColorPicker value={value} onChange={onChange} isReadOnly={readOnly} />
                                                    <Text textAlign='start' color={readOnly ? 'gray' : ''}>Projekt Farbe</Text>
                                                </HStack>
                                            )}
                                        />
                                    </Field>
                                </Stack>

                                <Field id="comments" label='Bemerkungen' readOnly={readOnly}>
                                    <Textarea {...register('project.comments')} height='7rem' />
                                </Field>

                            </Stack>
                        </Stack>
                        <Separator borderColor={watch('project.color')} borderWidth='2px' dropShadow='2px' />

                        {/* yearly budget and summary with multiple countries and currencies */}
                        <Tabs.Root variant='outline' defaultValue='singleCountry'>
                            <Tabs.List>
                                <Tabs.Trigger value='singleCountry'>{countries[watch('project.country')] ?? 'Land'}</Tabs.Trigger>
                            </Tabs.List>
                            <Tabs.Content value='singleCountry'>
                                <Stack>

                                    <Stack alignItems={{ base: 'flex-end' }}>
                                        <Box>
                                            <AddButton caption='Jahresbudget' onClick={() => onBudgetOpen()} />
                                        </Box>
                                    </Stack>
                                    <BudgetDialog
                                        data={changeBudget}
                                        currency={getValues('project.currency') ?? 'CHF'}
                                        isOpen={isBudgetOpen}
                                        onClose={onBudgetClose}
                                        onConfirm={async (yb: YearlyBudget): Promise<void> => onBudgetChange(yb)}
                                    />

                                    <DataTable
                                        caption={yearlyBudget.length > 0 ? '' : 'kein Budget eingetragen'}
                                        columns={columns}
                                        data={yearlyBudget}
                                        initialSortByColumn='year'
                                        initialSortByDesc={false}
                                        onRowClick={row => {
                                            setChangeBudget(row);
                                            onBudgetOpen();
                                        }}
                                    />
                                    <ConfirmDialog
                                        heading={`${changeBudget?.year} Löschen`}
                                        isOpen={isDeleteOpen}
                                        onClose={onDeleteClose}
                                        onConfirm={async (): Promise<void> => {
                                            setYearlyBudget(prev => {
                                                let toDelete = changeBudget
                                                let newBudget = prev.filter(p => p.year !== toDelete.year).sort((a, b) => a.year - b.year);
                                                return newBudget;
                                            });
                                            return Promise.resolve();
                                        }}
                                    />


                                    <SimpleGrid columns={{ base: 1, md: 3 }} gap={1} rounded={'lg'} shadow={'md'} mt={'1rem'} py='1rem'>
                                        <StatRoot>
                                            <Box textAlign="center">
                                                <Stack align='center'>
                                                    <HStack>
                                                        <BiWallet color='blue' size={'1.5em'} />
                                                        <Text fontSize={'lg'} fontWeight={'bold'}>Gesamtbudget</Text>
                                                    </HStack>
                                                </Stack>
                                                <StatValueText>{AmountUtil.format(yearlyBudget.reduce((a, yb) => a + (yb.budget ?? 0), 0))}</StatValueText>
                                                <StatLabel>{watch('project.currency') ?? 'CHF'}</StatLabel>
                                            </Box>
                                        </StatRoot>
                                        <StatRoot>
                                            <Box textAlign="center">
                                                <Stack align='center'>
                                                    <HStack>
                                                        <BiPieChartAlt color='blue' size={'1.5em'} />
                                                        <Text fontSize={'lg'} fontWeight={'bold'}>Projektbudget</Text>
                                                    </HStack>
                                                </Stack>
                                                <StatValueText>{AmountUtil.format(yearlyBudget.reduce((a, yb) => a + (yb.projectBudget ?? 0), 0))}</StatValueText>
                                                <StatLabel>CHF</StatLabel>
                                            </Box>
                                        </StatRoot>
                                        <StatRoot>
                                            <Box textAlign="center">
                                                <Stack align='center'>
                                                    <HStack>
                                                        <BiBarChartAlt color='blue' size={'1.5em'} />
                                                        <Text fontSize={'lg'} fontWeight={'bold'}>Fundraisingbudget</Text>
                                                    </HStack>
                                                </Stack>
                                                <StatValueText>{AmountUtil.format(yearlyBudget.reduce((a, yb) => a + (yb.fundraisingBudget ?? 0), 0))}</StatValueText>
                                                <StatLabel>CHF</StatLabel>
                                            </Box>
                                        </StatRoot>
                                    </SimpleGrid>
                                </Stack>
                            </Tabs.Content>
                        </Tabs.Root>

                        {/* submittion */}
                        <Flex justifyContent='flex-end'>
                            <Group gap={2}>
                                <CancelButton onClick={() => navigate('/projects/')} />
                                {!readOnly && (<SubmitButton isSubmitting={isSubmitting} />)}
                            </Group>
                        </Flex>
                    </Stack>
                </Fieldset.Root>
            </form>
        </Flex>
    );
}

export default ProjectsForm