import { Button } from "@/components/ui/button"
import { ReactElement } from "react";

export interface CancelButtonProps {
    caption?: string;
    onClick: () => void;
    [key: string]: any;
}

const CancelButton = (props: CancelButtonProps): ReactElement => {
    const { caption, onClick, ...rest } = props;
    return (<Button
        bg={'gray.100'}
        color={'black'}
        _hover={{ bg: 'gray.400' }}
        onClick={() => onClick()}
        {...rest}
    >{caption ?? 'Abbrechen'}</Button>)
}

export default CancelButton