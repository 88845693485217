
import { Text } from "@chakra-ui/react"
import { Link } from "react-router-dom"
import { useColorModeValue } from "@/components/ui/color-mode"
import { Tooltip } from "@/components/ui/tooltip"
import { Tag } from "@/components/ui/tag"

interface ReachLinkProps {
    name?: string
    to: string
    tooltip?: string
}

const ReachLink = (props: ReachLinkProps) => {
    const colorLinkHover = useColorModeValue('gray.900', 'gray.200');
    return (
        <Tag>
            <Tooltip showArrow openDelay={500} content={props.tooltip} positioning={{ placement: 'top-end' }} contentProps={{ css: { "--tooltip-bg": "black" } }}>
                <Text color='blue.600' _hover={{ color: colorLinkHover }}>
                    <Link to={props.to}>{props.name ?? props.to}</Link>
                </Text>
            </Tooltip>
        </Tag>
    )
}

export default ReachLink